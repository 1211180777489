
  
  const TailwindJson = [
  
    {
      "id": 1,
  
  
      "html_title": "intro",
  
  
      "bootstrap_head_desc": [
     
  
        {
          "bootstrap_title_heading": "Installation",
       
          "bootstrap_title_desc": `Step 1: Install Tailwind CSS
Install Tailwind CSS in your React project:

npm install tailwindcss postcss autoprefixer


Step 2: Create a Configuration File
Generate a default tailwind.config.js file:

npx tailwindcss init
npx tailwindcss init -p


Step 3: Configure PostCSS

npm install postcss autoprefixer// postcss.config.js
module.exports = {
  plugins: {
    tailwindcss: {},
    autoprefixer: {},
  },
}

Step 4:edit Configure tailwindCSS
// tailwind.config.js
/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {},
  },
  plugins: [],
}

step 5: create talwind.css file and add
/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles go here */


step 6: update in index.js

// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/tailwind.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


step 7: run below code
`,
  
        },

        {
          "bootstrap_title_heading": "App.js",
          "bootstrap_title_desc": `// App.js
          import React from 'react'
          
          const App = () => {
            return (
          <div className="flex justify-center items-center h-screen">
          <div className="bg-blue-500 text-white p-4 hover:bg-blue-700 transition-all duration-500 ease-in-out">
          This is a blue box with white text and padding.
          </div>
          </div>
            )
          }`
        }


      ],
  
  

      "coursename": "Intro",
  
  
    },
  
  
  
    {
      "id": 2,
  
  
      "html_title": "roadmap",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Folder Structure",
          
         
  
          
          
          "bootstrap_title_desc": `src/
|-- components/
|   |-- common/
|       |-- Header.jsx
|       |-- Footer.jsx
|       |-- HeroSection.jsx
|   |-- product/
|       |-- ProductList.jsx
|       |-- ProductDetail.jsx
|   |-- cart/
|       |-- Cart.jsx
|   |-- user/
|       |-- UserProfile.jsx
|-- pages/
|   |-- Home.jsx
|   |-- Products.jsx
|   |-- ProductDetailPage.jsx
|   |-- CartPage.jsx
|   |-- UserProfilePage.jsx
|-- styles/
|   |-- tailwind.css
|   |-- custom.css
|-- App.jsx
|-- index.js
|-- routes.js
|-- assets/
|   |-- images/
|       |-- product1.jpg
|       |-- product2.jpg
|   |-- icons/
|       |-- cart-icon.svg
|-- data/
|   |-- products.js
`,
 
        },
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "roadmap",
  
  
    },

    
    {
      "id": 3,
  
  
      "html_title": "tw-header",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Header.jsx",
          
         
  
          
          
          "bootstrap_title_desc": `// Header.jsx

import React, { useState } from 'react';


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-semibold">
<span className="text-blue-500">Your</span>Brand
        </div>

        {/* Navigation Menu (Hidden on smaller screens) */}
         <nav className={'hidden md:flex space-x-4 '$'{isMenuOpen ? 'md:hidden' : ''}'}> 
<a href="" className="hover:text-blue-500">Home</a>
<a href="" className="hover:text-blue-500">Products</a>
<a href="" className="hover:text-blue-500">About Us</a>
<a href="" className="hover:text-blue-500">Contact</a>
        </nav>

        {/* Mobile Menu Button (Hidden on larger screens) */}
        <button className="md:hidden" onClick={toggleMenu}>
{isMenuOpen ? (
"close"
) : (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
  </svg>
)}
        </button>

        {/* Mobile Menu (Hidden on larger screens) */}
        {isMenuOpen && (
<div className="md:hidden absolute top-0 left-0 right-0 bg-gray-800 text-white p-4">
  {/* Close Button */}
  <button className="w-6 h-6 absolute top-4 right-6" onClick={closeMenu}>
    clode
  </button>

  {/* Logo in Mobile Menu */}
 
  
  <a href="" className="block py-2">Home</a>
  <a href="" className="block py-2">Products</a>
  <a href="" className="block py-2">About Us</a>
  <a href="" className="block py-2">Contact</a>
</div>
        )}
      </div>
    </header>
  );
}

export default Header;
          `,
 
        },
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "TW-Header",
  
  
    },


    {
      "id": 4,
  
  
      "html_title": "tw-hero-section",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "HeroSection.jsx",
          
         
  
          
          
          "bootstrap_title_desc": `// HeroSection.jsx

import React from 'react';

const HeroSection = () => {
  return (
<div className="relative h-screen overflow-hidden">
  {/* Background Image */}
<img
  src="https://v-extechsolution.in/static/media/placement.9c07f276d25efd8b2129.jpg"
  alt="Hero Background"
  className="object-cover w-full h-full absolute inset-0 transition-opacity duration-1000 opacity-50"
/>
  
{/* Content Container */}
<div className="text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white">
  <h1 className="text-4xl text-blue-500 font-bold mb-4">Welcome to Your Website</h1>
  
  {/* Your amazing design elements */}
  <div className="bg-blue-500 text-white p-8 rounded-md shadow-lg">
<p>Your content and amazing design elements go here.</p>
  </div>
  
  <button className="mt-8 bg-white text-blue-500 px-6 py-3 rounded-full hover:bg-blue-100">
Get Started
  </button>
</div>
  </div>
);
  };
  
  export default HeroSection;
  `,
 
    },
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "TW-Hero Section",
  
  
    },

    {
      "id": 5,
  
  
      "html_title": "tw-category",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Category.jsx",
          
         
  
          
          
          "bootstrap_title_desc": `import React from 'react';

          const Category = () => {
            // Sample images
            const images = [
              'https://vhxview.com/static/media/prod4.21d00ef9c1aa695ac8e8.png',
              'https://vhxview.com/static/media/prod2.f6a4a91e2b254267eded.png',
              'https://vhxview.com/static/media/prod3.b3d755eb35b455b7bb9d.png','https://vhxview.com/static/media/prod4.21d00ef9c1aa695ac8e8.png',
              'https://vhxview.com/static/media/prod2.f6a4a91e2b254267eded.png',
              'https://vhxview.com/static/media/prod3.b3d755eb35b455b7bb9d.png',
              'https://vhxview.com/static/media/prod4.21d00ef9c1aa695ac8e8.png',
              'https://vhxview.com/static/media/prod2.f6a4a91e2b254267eded.png',
              'https://vhxview.com/static/media/prod3.b3d755eb35b455b7bb9d.png',
              // Add more image URLs here
            ];
          
            return (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-3">
                {images.map((imageUrl, index) => (
                  <div key={index} className="relative">
                    <img
                      src={imageUrl}
                      alt='img'
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black opacity-0 hover:opacity-5 transition-opacity duration-400">
                      <p className="text-white"> {index + 1}</p>
                    </div>
                  </div>
                ))}
              </div>
            );
          };
          
          export default Category;
          `,
 
    },
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "TW-Category",
  
  
    },

    {
      "id": 6,
  
  
      "html_title": "tw-Product",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "ProductPage.jsx",
          
         
  
          
          
          "bootstrap_title_desc": `import React from 'react';
import { FaHeart } from 'react-icons/fa';

const ProductPage = () => {
  const productsData= [
{
"id": 1,
"name": "Basic Tee",
"color": "Black",
"price": 35,
"image": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"
},
{
"id": 2,
"name": "Casual Shirt",
"color": "Blue",
"price": 45,
"image": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg"
},
{
"id": 3,
"name": "Basic Tee",
"color": "Black",
"price": 35,
"image": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"
},
{
"id": 4,
"name": "Basic Tee",
"color": "Black",
"price": 35,
"image": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"
}
];

return (
<div className="bg-gray-100 py-8">
<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
<h2 className="text-2xl font-bold mb-4">Our Products</h2>
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
{productsData.map(product => (
<div key={product.id} className="bg-white rounded-lg shadow-md overflow-hidden">
<div className="relative">
<img src={product.image} alt={product.name} className="object-cover w-full h-40 sm:h-48 md:h-56 p-2" />
<button className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md">
<FaHeart className="h-6 w-6 text-gray-500" />
</button>
</div>
<div className="p-4">
<h3 className="text-lg font-bold text-gray-800">{product.name}</h3>
<p className="text-sm text-gray-500">{product.color}</p>
<p className="text-lg font-semibold text-gray-900">{product.price}</p>
</div>
</div>
))}
</div>
</div>
</div>
);
}

export default ProductPage;
`,
 
    },
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "TW-Product",
  
  
    },

    {
      "id": 7,
  
  
      "html_title": "tw-product-overview",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Product Overview.jsx",
          
         
  
          
          
          "bootstrap_title_desc": `import React from 'react';

const ProductOverview = () => {
// Define your product details here
const product = {
name: 'Basic Tee 6-Pack',
description: 'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Made from 100% cotton for maximum comfort.',
images: [
"https://v-extechsolution.in/static/media/python.0cc149e38bc8d29ebe80.png",
  "https://v-extechsolution.in/static/media/python.0cc149e38bc8d29ebe80.png",
  "https://v-extechsolution.in/static/media/python.0cc149e38bc8d29ebe80.png",
  // Add more image URLs as needed
  ],
  manufacturer: {
  name: 'ABC Clothing Inc.',
  address: '123 Main St, City, Country',
  phone: '123-456-7890',
  email: 'info@abcclothing.com'
  },
  returnPolicy: '30 days return policy',
  price: '$192',
  // Add more product details as needed
  };
  
  return (
  <div className="container mx-auto px-4 py-8">
  <div className="flex flex-col md:flex-row">
  <div className="md:w-1/2">
  <img src={product.images[0]} alt={product.name} className="w-full h-auto mb-4 rounded-lg shadow-lg" width='50%' />
  <div className="flex justify-center space-x-4">
  {product.images.map((image, index) => (
  <img key={index} src={image} alt='' className="w-1/4 h-auto rounded-lg shadow-md cursor-pointer" />
  ))}
  </div>
  </div>
  <div className="md:w-1/2 md:pl-8">
  <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
  <p className="text-gray-700 mb-6">{product.description}</p>
  <div className="mb-6">
  <h2 className="text-lg font-semibold mb-2">Manufacturer Details</h2>
  <p className="text-sm text-gray-600"><span className="font-semibold">Name:</span> {product.manufacturer.name}</p>
  <p className="text-sm text-gray-600"><span className="font-semibold">Address:</span> {product.manufacturer.address}</p>
  <p className="text-sm text-gray-600"><span className="font-semibold">Phone:</span> {product.manufacturer.phone}</p>
  <p className="text-sm text-gray-600"><span className="font-semibold">Email:</span> {product.manufacturer.email}</p>
  </div>
  <div className="mb-6">
  <h2 className="text-lg font-semibold mb-2">Product Details</h2>
  <p className="text-sm text-gray-600"><span className="font-semibold">Price:</span> {product.price}</p>
  <p className="text-sm text-gray-600"><span className="font-semibold">Return Policy:</span> {product.returnPolicy}</p>
  </div>
  <div className="flex mb-4">
  <button className="bg-indigo-500 text-white py-2 px-6 rounded-md mr-4 hover:bg-indigo-600 transition duration-300">
  Add to Cart
  </button>
  <button className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition duration-300">
  Buy Now
  </button>
  </div>
  </div>
  </div>
  </div>
  );
  };
  
  export default ProductOverview;
  `,
 
},
  

    
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "TW-Product Overview",
  
  
    },
  
  
  
  
   
  ]
  export default TailwindJson