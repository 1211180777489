import React, { useState, useEffect } from 'react';
import './CertiVerification.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import * as yup from 'yup';

const CertiVerification = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await fetch('https://bottom-seven.vercel.app/certi', {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
          dob: values.dob,
          startDate: values.startDate,
          endDate: values.endDate,
          work: values.work,
          certiId: values.certiId, // Add Certi ID to the request body
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = await response.json();

      if (!data.success) {
        alert(data.error);
      } else {
        alert(data.message);
        resetForm();
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      dob: '',
      startDate: '',
      endDate: '',
      work: '',
      certiId: '', // Initialize Certi ID
    },

    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      dob: yup.date().required('Date of Birth is required'),
      startDate: yup.date().required('Start date is required'),
      endDate: yup.date().required('End date is required'),
      work: yup.string().required('Work is required').min(5),
      certiId: yup.string().required('Certi ID is required'), // Add Certi ID validation
    }),

    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, { resetForm });
    },
  });

  return (
    <div className='attenp'>
      <div className='atten-form'>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='name'
            name='name'
            label='Name'
            type='text'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='standard'
          />

          <TextField
            id='dob'
            name='dob'
            type='date'
            value={formik.values.dob}
            onChange={formik.handleChange}
            InputProps={{ placeholder: 'Date of Birth' }}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            helperText={formik.touched.dob && formik.errors.dob}
            variant='standard'
          />

          <TextField
            id='startDate'
            name='startDate'
            type='date'
            value={formik.values.startDate}
            onChange={formik.handleChange}
            InputProps={{ placeholder: 'Start Date' }}
            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
            helperText={formik.touched.startDate && formik.errors.startDate}
            variant='standard'
          />

          <TextField
            id='endDate'
            name='endDate'
            type='date'
            value={formik.values.endDate}
            onChange={formik.handleChange}
            InputProps={{ placeholder: 'End Date' }}
            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
            helperText={formik.touched.endDate && formik.errors.endDate}
            variant='standard'
          />

          <TextField
            id='work'
            name='work'
            label='Work /Reason*'
            multiline
            rows={4}
            value={formik.values.work}
            onChange={formik.handleChange}
            error={formik.touched.work && Boolean(formik.errors.work)}
            helperText={formik.touched.work && formik.errors.work}
            variant='standard'
          />

          <TextField
            id='certiId'
            name='certiId'
            label='Certi ID'
            type='text'
            value={formik.values.certiId}
            onChange={formik.handleChange}
            error={formik.touched.certiId && Boolean(formik.errors.certiId)}
            helperText={formik.touched.certiId && formik.errors.certiId}
            variant='standard'
          />

          <Button
            variant='contained'
            className='SM'
            type='submit'
            disabled={loading}
          >
            {loading ? 'sending...' : 'Submit'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CertiVerification;
