import React from 'react'

import Course from './Course/Course'
import Paragraph from './Paragraph/Paragraph'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import "./Service.scss"
import Loader from '../../Loader/Load';
import { Helmet } from 'react-helmet';



const Service = ({Product}) => {


  
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    await loadFull(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);
  return (
    <>
<Helmet>
<title>Best IT Course Training In Vadodara</title>
        <meta name="title" content="Best codding classes In Vadodara." />
        <meta name="description" content="Choose In Vadodara Any IT course like data analysis,data science,mern stack development,fullstack devlopement,python,software testing,manual testing.
        providing,nizampura" />

</Helmet>
    
<Loader/>

<Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "transperent",
                    },
                },
                
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#007FFF",
                    },
                    links: {
                        color: "#007FFF",
                        distance: 120,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default:"bounce",
                        },
                        random: false,
                        speed: 4,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 40,
                    },
                    opacity: {
                        value: 0.1,
                    },
                    shape: {
                        type: ["triangle", "circle"],
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        />
    <div>

    


    
    <Course Product={Product}/>
    <Paragraph/>

   
     
    
      
    

      
      
    
  
    
    
    </div>
    </>

  )
}

export default Service