
  
  const CyberJson = [
  
    {
      "id": 1,
  
  
      "html_title": "intro",
  
  
      "bootstrap_head_desc": [
     
  
        {
"bootstrap_title_heading": "What is Cyber Security?",
          
        
          "bootstrap_title_desc": `Cybersecurity, or information security,
is the practice of protecting computer systems,
networks, and digital information from theft,
damage, unauthorized access, and other cyber threats.`,

        },

        {

          "bootstrap_title_heading": "Cybe Besics",
  
          "bootstrap_title_desc": `What is Cyber Crime?
          Just like regular crime, it also exists
          on the Internet.
          
          1)password attack
          2)gift card code attack
          3)document stolen,asking for money
          4)system attack
          5)sql injection attack
          6)security attack
          `,
        }


      ],
  
 
  
      "coursename": "Cyber Intro",
  
  
    },
  
  
  
    {
      "id": 2,
  
  
      "html_title": "cyber-dark-web",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "The Dark Web",
          
         
  
          
          
          "bootstrap_title_desc": `
The dark web is a part of the internet that is
intentionally hidden and inaccessible through
standard web browsers. It's often associated with illegal
activities due to the anonymity it provides to users. Here's
an overview of the dark web with an example:`,
 
        },

        {
          "bootstrap_title_heading": "Dark Website",
          
         
  
          
          
          "bootstrap_title_desc": `Accessibility:
Accessible using specific software such as Tor (The Onion Router),
I2P (Invisible Internet Project), or Freenet.
Requires specific configurations or tools to access.`,
 
        },

        {
          "bootstrap_title_heading": "Example",
          
         
  
          
          
          "bootstrap_title_desc": `One of the most infamous examples of the dark web.
online marketplace
for buying and selling illegal drugs, hacking tools, and other illegal goods and services.
Operated on the Tor network, allowing users to access it anonymously.
Accepted only Bitcoin as a payment method for increased privacy.`,
 
        },

        
        {
          "bootstrap_title_heading": "monitor-dark-web",
          
         
  
          
          
          "bootstrap_title_desc": `Remember that monitoring the dark
web requires a cautious and informed approach.
It is important to prioritize legal and ethical
considerations, and collaboration with law enforcement
is crucial for addressing potential threats effectively.`,
 
        },
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Cyber-Dark-Web",
  
  
    },
  
  
  
    {
      "id": 3,
  
  
      "html_title": "cyber-network",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "The OSI Model",
          
    
  
          
          
          "bootstrap_title_desc": `It is essential for Cyber Security Professionals
to have a solid understanding of how computers communicate.

The OSI ("Open Systems Interconnection") model makes it clear
what is required to communicate on a network by splitting the
requirements into multiple layers.`,
 
        },

  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Cyber-Network",
  
  
    },

    {
      "id": 4,
  
  
      "html_title": "text-encryption",
  
  
      "bootstrap_head_desc": [
     


        {
          "bootstrap_title_heading": "Text Encryption Using Algorithms",
          
         
  
          
          
          "bootstrap_title_desc": `Text encryption and decryption is a common practice to secure sensitive information.
and how you can use them in a programming language like Python and express js and php.
You can build a simple web application to encrypt and decrypt textual
information. 
Remember that strong encryption should produce different outputs even given the same input.`,
        },


        {
          "bootstrap_title_heading": "Caesar Cipher using python (Encrypted)",
          
         
  
          
          
          "bootstrap_title_desc": `def encrypt_code(text, shift):
result = ""
for char in text:
if char.isalpha():
start = ord('a') if char.islower() else ord('A')
result += chr((ord(char) - start + shift) % 26 + start)
else:
result += char
return result


plaintext = "Hello, World!"
shift = 3
encrypted_text = encrypt_code(plaintext, shift)
print("Encrypted Text:", encrypted_text)`,
        },

        {
          "bootstrap_title_heading": "How It works",
          
         
  
          
          
          "bootstrap_title_desc": `The function is named encrypt_code.
It takes two parameters: text
(the input text to be encrypted) and shift (the number of positions
each letter should be shifted in the alphabet).

Initialize an empty string called result to store the encrypted text.

Loop through each character in the input text

Check if the character is an alphabet letter:

Determine the starting point of the alphabet for
the case (uppercase or lowercase):

If the current character is lowercase, set the starting point (start)
to the ASCII value of 'a'. If it's uppercase, set it to the ASCII value
of 'A'.

Each character, such as letters, digits, punctuation marks, and control characters, is represented by a unique 7-bit binary number (from 0 to 127).

The ASCII value for the letter 'A' is 65.
The ASCII value for the letter 'a' is 97.
The ASCII value for the digit '0' is 48.
The ASCII value for the exclamation mark '!' is 33.

print(ord('A'))
print(chr(65))

If char is a lowercase alphabet letter (char.islower() is True), then ord('a') is used to get the Unicode code point of the lowercase letter 'a'.
If char is an uppercase alphabet letter (char.islower() is False), then ord('A') is used to get the Unicode code point of the uppercase letter 'A'.

Encrypt the character using the Caesar Cipher:

result += chr((ord(char) - start + shift) % 26 + start)

Calculate the new ASCII value of the character using the Caesar Cipher formula:
(ord(char) - start + shift) % 26: Shift the character by the specified amount
(shift) within the range of 26 letters.
+ start: Adjust the result to the ASCII value of the starting point ('a' or 'A').
Convert the new ASCII value back to a character using chr() and append
it to the result string.


If the character is not an alphabet letter, keep it unchanged:
    
Return the encrypted result:


    
Set a sample plaintext ("Hello, World!") and a shift value (3).
Call the encrypt_caesar function with the plaintext and shift.
Print the encrypted result.    `,
        },

        
        {
          "bootstrap_title_heading": "Caesar Cipher using python (decrypted)",
          
         
  
          
          
          "bootstrap_title_desc": `def decrypt_code(text, shift):
result = ""
for char in text:
if char.isalpha():
start = ord('a') if char.islower() else ord('A')
result += chr((ord(char) - start - shift) % 26 + start)
else:
result += char
return result


plaintext = "Hello, World!"
shift = 3
encrypted_text = encrypt_code(plaintext, shift)
print("Encrypted Text:", decrypted_text)`,
        },

        {
          "bootstrap_title_heading": "using React And Express",
          
         
  
          
          
          "bootstrap_title_desc": `//react js(App.jsx)

import React, { useState } from 'react';
import axios from 'axios';

function App() {
const [text, setText] = useState('');
const [shift, setShift] = useState(3);
const [encryptedText, setEncryptedText] = useState('');
const [decryptedText, setDecryptedText] = useState('');

const handleEncrypt = async () => {
try {
const response = await axios.post('http://localhost:3001/encrypt', { text, shift });
setEncryptedText(response.data.encryptedText);
} catch (error) {
console.error('Encryption error:', error);
}
};

const handleDecrypt = async () => {
try {
const response = await axios.post('http://localhost:3001/decrypt', { encryptedText, shift });
setDecryptedText(response.data.decryptedText);
} catch (error) {
console.error('Decryption error:', error);
}
};

return (
<div>
<label>
Text:
<input type="text" value={text} onChange={(e) => setText(e.target.value)} />
</label>
<br />
<label>
Shift:
<input type="number" value={shift} onChange={(e) => setShift(parseInt(e.target.value, 10))} />
</label>
<br />
<button onClick={handleEncrypt}>Encrypt</button>
<button onClick={handleDecrypt}>Decrypt</button>
<br />
<p>Encrypted Text: {encryptedText}</p>
<p>Decrypted Text: {decryptedText}</p>
</div>
);
}

export default App;
          `,
        },

        {
          "bootstrap_title_heading": "Express js",
          
    
  
          
          
          "bootstrap_title_desc": `const express = require('express');
const bodyParser = require('body-parser');
const cors = require('cors');

const app = express();
const port = 3001;

app.use(cors());
app.use(bodyParser.json());

app.post('/encrypt', (req, res) => {
const { text, shift } = req.body;
const encryptedText = encryptCaesar(text, shift);
res.json({ encryptedText });
});

app.post('/decrypt', (req, res) => {
const { encryptedText, shift } = req.body;
const decryptedText = decryptCaesar(encryptedText, shift);
res.json({ decryptedText });
});

// Caesar Cipher encryption function
function encryptCaesar(text, shift) {
let result = '';
for (const char of text) {
if (char.match(/[a-zA-Z]/)) {
const start = char.match(/[a-z]/) ? 'a' : 'A';
const code = char.charCodeAt(0);
const encryptedCode = (code - start.charCodeAt(0) + shift) % 26 + start.charCodeAt(0);
result += String.fromCharCode(encryptedCode);
} else {
result += char;
}
}
return result;
}

// Caesar Cipher decryption function
function decryptCaesar(encryptedText, shift) {
return encryptCaesar(encryptedText, 26 - shift); // Decrypt by shifting in the opposite direction
}

app.listen(3000, () => {
console.log("Server is running on port 3000");
});
`,
"bootstrap_title2_heading": "Output",
},


{
  "bootstrap_title_heading": "Advanced Encryption Standard (AES):",
  
 

  
  
  "bootstrap_title_desc": `const express = from Crypto.Cipher import AES
from Crypto.Util.Padding import pad, unpad
from Crypto.Random import get_random_bytes
  
def encrypt_aes(text, key):
cipher = AES.new(key, AES.MODE_CBC, iv=get_random_bytes(16))
plaintext = pad(text.encode('utf-8'), 16)
ciphertext = cipher.encrypt(plaintext)
return ciphertext
  
def decrypt_aes(ciphertext, key):
cipher = AES.new(key, AES.MODE_CBC, iv=ciphertext[:16])
decrypted_text = unpad(cipher.decrypt(ciphertext[16:]), 16).decode('utf-8')
return decrypted_text
  
# Example usage:
key = get_random_bytes(32)
plaintext = "Hello, World!"
encrypted_text = encrypt_aes(plaintext, key)
print("Encrypted Text:", encrypted_text)

decrypted_text = decrypt_aes(encrypted_text, key)
print("Decrypted Text:", decrypted_text)
`,
"bootstrap_title2_heading": "Output",
},


  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Cyber Text Encryption",
  
  
    },

    {
      "id": 5,
  
  
      "html_title": "password-strength",
  
  
      "bootstrap_head_desc": [
     


        {
          "bootstrap_title_heading": "Text strength Tester",
          
    
  
          
          
          "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Password Strength Test</title>
<style>
input {
margin-bottom: 10px;
}

#strength-meter {
margin-top: 10px;
}

.weak {
color: red;
}

.medium {
color: orange;
}

.strong {
color: green;
}
</style>
</head>
<body>
<label for="password">Password:</label>
<input type="password" id="password" oninput="checkPasswordStrength()">

<div id="strength-meter">
Strength: <span id="strength-text" class="weak">Weak</span>
</div>

<script>
function checkPasswordStrength() {
const password = document.getElementById('password').value;
const strengthText = document.getElementById('strength-text');

// Reset styles
strengthText.classList.remove('weak', 'medium', 'strong');
strengthText.innerHTML = 'Weak';

// Check password strength
if (password.length >= 8) {
strengthText.classList.add('medium');
strengthText.innerHTML = 'Medium';

if (password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/\d/) && password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
strengthText.classList.add('strong');
strengthText.innerHTML = 'Strong';
}
}
}
</script>
</body>
</html>
`,
        },




  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Cyber Password-Strength",
  
  
    },

    
    {
      "id": 5,
  
  
      "html_title": "image-encrypt",
  
  
      "bootstrap_head_desc": [
     


        {
          "bootstrap_title_heading": "Image Encrypt",
          
         
  
          
          
          "bootstrap_title_desc": `Encrypting and decrypting images involves
converting the image file into a format that can be easily
manipulated for encryption, and then converting it back to the
original format after decryption. One common approach is to convert
the image to a byte array, perform encryption or decryption on the
byte array, and then convert it back to an image.

Below, I'll provide a simple example in Python using the
cryptography library for encryption and decryption of an
image. Make sure to install the library first:

-- pip install cryptography
--

          
          `,
        },

        {
          "bootstrap_title_heading": "Encrypt",
          
         
  
          
          
          "bootstrap_title_desc": `from cryptography.fernet import Fernet
from PIL import Image
import io

def generate_key():
return Fernet.generate_key()
  
def encrypt_image(image_path, key):
with open(image_path, 'rb') as file:
image_data = file.read()

cipher = Fernet(key)
encrypted_data = cipher.encrypt(image_data)

encrypted_image_path = 'encrypted_image.png'
with open(encrypted_image_path, 'wb') as file:
file.write(encrypted_data)

return encrypted_image_path

def decrypt_image(encrypted_image_path, key):
with open(encrypted_image_path, 'rb') as file:
encrypted_data = file.read()

cipher = Fernet(key)
decrypted_data = cipher.decrypt(encrypted_data)

decrypted_image = Image.open(io.BytesIO(decrypted_data))
return decrypted_image

# Example usage
key = generate_key()
image_path = 'your_image.jpg'

# Encrypt the image
encrypted_image_path = encrypt_image(image_path, key)

# Decrypt the image
decrypted_image = decrypt_image(encrypted_image_path, key)
decrypted_image.show()
`,
        },




  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Cyber Image Ecrypt",
  
  
    },

    {
      "id": 6,
  
  
      "html_title": "cyber tools",
  
  
      "bootstrap_head_desc": [
     


        {
          "bootstrap_title_heading": "sectools",
          
         
  
          
          
          "bootstrap_title_desc": `burp suit
          
. Here are some key reasons why Burp Suite is commonly used:


Burp Suite is a widely used application security testing tool that serves various purposes in the field of cybersecurity. Here are some key reasons why Burp Suite is commonly used:

Web Application Security Testing:

Burp Suite is designed for finding security vulnerabilities in web applications.

Manual Testing and Analysis:

Security professionals use Burp Suite to manually analyze and manipulate HTTP requests and responses. 

Automated Scanning:

Burp Suite includes automated scanning features that can crawl a web application and identify common security issues, such as SQL injection, 

Session Management:

The tool assists in managing and manipulating user sessions. Security professionals can use Burp Suite to capture, analyze, and modify session tokens, cookies, and other session-related data.

Security Research and Learning:

Burp Suite is a valuable tool for security researchers and students learning about web application security.

Proxy Capabilities:

Burp Suite acts as a proxy between the user's browser and the target web application. This allows testers to intercept and modify requests and responses in real-time,

`,
        },

        {
          "bootstrap_title_heading": "installation",
          
         
  
          
          
          "bootstrap_title_desc": `Step 1: Install Burp Suite
Download Burp Suite: Visit the official Burp Suite website and
download the appropriate version (Community or Professional) for your operating system.

Step 2: Explore the User Interface
Open Burp Suite: Launch Burp Suite after installation.

Explore the Tabs:

Target: Set the target scope for your testing.
Proxy: Intercept and modify HTTP requests.
Spider: Crawl and map the target application.
Scanner: Automatically identify security vulnerabilities.
Intruder: Perform automated attacks.
Repeater: Manually repeat and modify requests.
Decoder: Decode/encode data.
Comparer: Compare responses.
Extender: Use extensions to customize Burp Suite.

Step 3: Configure Browser and Burp Proxy
Configure Browser Proxy:

Set your browser to use Burp Suite as a proxy. Change the proxy settings in your browser to 127.0.0.1 and port 8080 (default).
Verify Proxy Connection:

Check that Burp Suite is intercepting requests in the "Proxy" tab.


Step 4: Practice Interception
Intercept Requests:
Use the "Intercept" toggle in the "Proxy" tab to intercept requests.
Observe and modify HTTP requests before forwarding them.

Step 5: Explore Target Tab
Add Target:
Navigate to the "Target" tab and add the target URL of the web application you want to test.

Step 6: Use Spider
Start Spider:
In the "Spider" tab, start the spider to crawl the target application.
Review the results in the "Site map."

Step 7: Automated Scanning with Scanner
Run Scanner:
In the "Scanner" tab, initiate an automated scan to identify common vulnerabilities.

Step 8: Intruder for Manual Testing
Use Intruder:
In the "Intruder" tab, perform manual attacks by customizing and automating requests.
Explore payload options for fuzzing.

Step 9: Repeater for Manual Testing
Use Repeater:
In the "Repeater" tab, manually send and modify requests to observe their impact.

tep 10: Learn Decoder, Comparer, and Extender
Explore Other Tabs:

Familiarize yourself with the "Decoder," "Comparer," and "Extender" tabs for various tasks.
Learn Extensions:

Explore Burp Suite extensions to enhance functionality.

Step 11: Review and Analyze
Analyze Results:
Review the findings, logs, and reports generated by Burp Suite.
Understand security vulnerabilities and their impact.

Step 12: Explore Burp Suite Documentation
Read Documentation:
Refer to the 'official Burp Suite documentation' for in-depth explanations and tutorials.

`,
        },

        {
          "bootstrap_title_heading": "take steps",
          
         
  
          
          
          "bootstrap_title_desc": `Step 1: Start Burp Suite
Open Burp Suite and make sure it's running.

Step 2: Configure Browser Proxy
Open your browser settings.
Configure the proxy settings to use Burp Suite:
Host: 127.0.0.1
Port: 8080 (default)
Step 3: Intercept a Request
In Burp Suite, go to the "Proxy" tab.
Ensure the "Intercept" toggle is on (should be green).
Go to your browser and visit any website (e.g., http://example.com).
You'll notice that Burp Suite intercepts the request in the "Proxy" tab.

Step 4: Forward the Request
In Burp Suite, go to the "Proxy" tab.
You'll see the intercepted request. Right-click on it and select "Forward" to send it to the server.
Step 5: Modify the Request
In Burp Suite, go to the "Proxy" tab.
Intercept a request.
Right-click on the request and select "Send to Repeater."
In the "Repeater" tab, you can modify the request's parameters or body.
Click "Go" to send the modified request.
Step 6: Analyze Responses
In Burp Suite, go to the "Proxy" tab.
Intercept a response.
Analyze the response in the "Response" tab. You can see the server's response.
Step 7: Spider a Website
Go to the "Target" tab.
Enter a target URL (e.g., http://example.com).
Right-click on the target and select "Spider this host."
Review the results in the "Site map."
Step 8: Automated Scanning
Go to the "Target" tab.
Right-click on the target and select "Scan."`,
        },
      




  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "cyber tools",
  
  
    },
  
  
   
  

   
   
  
  
  
  
  
  
  
  
  
  
  ]
  export default CyberJson