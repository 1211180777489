import React, { useContext } from 'react';
import './Mail.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from '../../../../Uttis/MyContext';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  message: yup.string().required('Message is required').min(5),
  phone: yup.number().min(10).required('Phone no. is required'),
});

const Mail = () => {

    const{setSnack,setLoading,setMessage} = useContext(MyContext)
     


  const formik = useFormik({
  
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async(values,{ resetForm }) => {
      setLoading(true)
      document.querySelector('body').style.overflow='hidden'
   const response =    await fetch('https://bottom-seven.vercel.app/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = await response.json()

      if(data.success){
        setMessage(data.message)
        setSnack(true)
        resetForm()
      }else{
        setMessage(data.error)
        setSnack(true)
      }
      setLoading(false)
       document.querySelector('body').style.overflow='auto'

    },
  });
    

  return (
    <>
 
        <div className='page1' >
            <form onSubmit={formik.handleSubmit}  >
                <TextField
                    id="name"
                    name="name"
                    value={formik.values.name || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    label="Name"
                    variant="standard" /><br />


                <TextField
                    id="email"
                    name="email"

                    value={formik.values.email || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email"
                    variant="standard" /><br />

                <TextField
                    id="phone"
                    name="phone"

                    value={formik.values.phone || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    label="Phone"
                    variant="standard" /><br />


                <TextField
                    id="message"
                    name="message"
                    rows={4}
                    multiline
                    value={formik.values.message || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    label="message"
                    variant="standard" /><br />

                <Button variant="contained"   className='SM' type='submit' >Send Message</Button>

            </form>
        </div>
    
    </>
  )
}

export default Mail