
  
  const FigmaJson = [
  
   
  
  
  
    {
      "id": 1,
  
  
      "html_title": "intro",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "What is UI Design?",
         
  
          
          
          "bootstrap_title_desc": ` Consider how icons are displayed on
 a page and how they connect with one another in the electronic
 medium. Interface design components include font selection,
color palette, images, button placement, and menu structure.`,
          
        },
  
        {
          "bootstrap_title_heading": "What is UX Design?",
         
  
          
          
          "bootstrap_title_desc": `Consider taking a trip or shopping on the internet.
The best solution would be an easy-to-use website or app that allows you
to quickly find what you’re looking for. You immediately complete your
transaction and leave the store. That is exactly what we mean by a good user experience!`,
          
        },
       

        {
          "bootstrap_title_heading": "Why UI UX Design?",
         
  
          
          
          "bootstrap_title_desc": `Enhanced User Satisfaction: A well-designed UI and UX can greatly enhance user satisfaction by providing a smooth, intuitive, and enjoyable experience. Users are more likely to engage with and return to products that offer a positive experience.

Usability: UI and UX design focuses on making products easy to use and navigate. Clear layouts, intuitive navigation, and logical flows contribute to usability, ensuring that users can accomplish their tasks efficiently and effectively.

Increased Engagement: Engaging UI design elements such as attractive visuals, animations, and interactive features can captivate users and encourage them to interact more with the product. This can lead to increased engagement and retention.

Brand Perception: UI design plays a significant role in shaping the perception of a brand. A visually appealing and consistent UI can convey professionalism, trustworthiness, and brand identity, influencing how users perceive the product and the company behind it.

Accessibility: A good UX design considers the diverse needs of users, including those with disabilities or limitations. By following accessibility guidelines and principles, UI/UX designers can ensure that products are usable by a wider range of people, promoting inclusivity.

Competitive Advantage: In today's competitive digital landscape, a well-designed UI/UX can be a significant differentiator. Products that offer superior user experiences stand out from the competition and are more likely to attract and retain customers.

Reduced Development Costs: Investing in UI/UX design early in the development process can help identify and address usability issues and design flaws before they become expensive to fix. This can ultimately save time and money in the long run by reducing the need for extensive redesigns or post-launch fixes.

Analytics and Iteration: UI/UX design is often an iterative process that involves gathering feedback, analyzing user behavior, and making continuous improvements based on data-driven insights. By monitoring user interactions and iterating on design elements, products can evolve to better meet the needs and preferences of users over time.`,
          
        },
    
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "intro",
  
  
    },

    {
      "id": 2,
  
  
      "html_title": "ui-vs-ux",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "UI (User Interface):",
         
  
          
          
          "bootstrap_title_desc": `Focus: UI design primarily focuses on the look and feel of the product, including its visual elements, such as colors, typography, icons, buttons, and other graphical assets.

Presentation: UI designers are responsible for creating visually appealing and aesthetically pleasing interfaces that attract users and convey the brand's identity. This involves designing layouts, arranging elements on the screen, and ensuring consistency across all parts of the interface.

Interactivity: UI design also involves designing interactive elements that users can interact with, such as buttons, menus, forms, and other controls. UI designers determine the visual style and behavior of these elements to make them intuitive and easy to use.

Tools: UI designers often use graphic design software such as Adobe Photoshop, Sketch, or Figma to create mockups and visual designs of the interface.`,

        },
  
        {
          "bootstrap_title_heading": "UX (User Experience):",
         
  
          
          
          "bootstrap_title_desc": `Focus: UX design focuses on the overall experience of the user when interacting with the product, encompassing all aspects of the user's journey from initial discovery to task completion.

User-Centric Approach: UX designers prioritize understanding the needs, goals, and behaviors of users through research, user testing, and analysis. They aim to create products that are easy to use, efficient, and enjoyable for the target audience.

Flow and Navigation: UX designers are responsible for designing the overall flow and navigation structure of the product to ensure that users can easily find what they need and complete their tasks without friction or confusion.

Problem-Solving: UX designers identify usability issues, pain points, and opportunities for improvement through methods such as user interviews, surveys, usability testing, and analytics. They then work to address these issues and optimize the user experience.

Prototyping: UX designers often create prototypes and wireframes to visualize the user journey and test different design solutions before implementation. Prototyping tools like Adobe XD, Axure RP, or InVision are commonly used in UX design.`,

        },
       

       
    
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "UI Vs UX",
  
  
    },

    {
      "id": 3,
  
  
      "html_title": "ui-ux-project",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Project",
         
  
          
          
          "bootstrap_title_desc": `Chatbots Because chatbots are becoming increasingly
 popular, you should have at least one in your portfolio. This project gives
you the opportunity to demonstrate your abilities in conversational design,
information architecture, and visual design. You might also show off your skill
to create an engaging user experience that users will want to return to.

Desktop wallpaper can be an excellent addition to your portfolio, particularly
if your work is complex. This should be customized for a specific user. Consider
presenting this project as a case study, in which you describe your thought process,
justification, and how your design meets the needs of the user.

Consider including a restaurant menu in your portfolio. This can be accomplished by
converting a favorite restaurant’s menu into a website and app. Concentrate on the 
essentials for this project. For rapid visual perception, combine highly accessible
words with few visuals.`,

        },

      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "UI UX project",
  
  
    },

    {
      "id": 4,
  
  
      "html_title": "ui-ux-wireframe",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "User Flow",
         
  
          
          
          "bootstrap_title_desc": `When dealing with a product, a customer might take several different paths.
 A user flow is a visual representation of the various paths that can be taken when using an app or 
website, which can be written out or created digitally. The flowchart begins with the consumer’s
 first engagement with the product, such as an onboarding screen or homepage, and ends with the final
  action or conclusion, such as purchasing a product or creating an account.`,

        },

        {
          "bootstrap_title_heading": "Wireframes",
         
  
          
          
          "bootstrap_title_desc": `
          Wireframes are skeletal representations of a
digital interface, typically created during the early stages 
of the design process. They outline the structure, layout, and
functionality of a web page, mobile app screen, or software interface
without focusing on detailed visual design elements like colors, typography,
or imagery. Here's more detail about wireframes:`,

        },

        {
          "bootstrap_title_heading": "Wireframes advantages",
         
  
          
          
          "bootstrap_title_desc": `Wireframes serve as a blueprint for the design, providing a
 clear outline of the content hierarchy, navigation structure, and user interactions.
They help stakeholders visualize the layout and organization of interface elements,
 facilitating discussions and decision-making.
Wireframes enable designers to quickly iterate and test different design concepts,
 refining the user experience before investing time in detailed visual design.`,

        },

        {
          "bootstrap_title_heading": "Tools:",
         
  
          
          
          "bootstrap_title_desc": `Wireframes can be created using various tools,
ranging from pen and paper sketches to digital software specifically
designed for wireframing, such as Adobe XD, Sketch, Balsamiq, Axure RP, or Figma.
The choice of tool depends on factors such as the designer's preference,
team collaboration needs, and the complexity of the project.`,

        },

        {
          "bootstrap_title_heading": "Wire Flow",
         
  
          
          
          "bootstrap_title_desc": `A wireflow combines the elements of wireframes
and user flows to provide a comprehensive representation of both the visual
design and the user journey within a digital product. `,

        },
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "UI UX Wireframe",
  
  
    },


  ]
  export default FigmaJson