
const SQL = [
  {
    "id": 1,
    "html_title": "intro",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Introduction to SQL",

        "bootstrap_title_desc": `Learn the basics of SQL, its history,
and why it's used. SQL stands for Structured Query Language and 
is essential for managing and manipulating relational databases.
For example, SQL is used to perform tasks such as querying data,
updating records, and creating tables. An example query is 
'SELECT * FROM employees;', 
which retrieves all records from the 'employees' table.`,
        "copy": true
      },
      {
        "bootstrap_title_heading": "What is SQL?",
        "bootstrap_title_desc": "SQL stands for Structured Query Language. It is used to communicate with databases and perform various tasks such as querying, updating, and managing data. SQL commands include 'SELECT', 'INSERT', 'UPDATE', and 'DELETE'. For instance, 'INSERT INTO employees (name, position) VALUES ('Alice', 'Developer');' adds a new record to the 'employees' table.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Basic SQL Syntax",
        "bootstrap_title_desc": "Understand the basic syntax and structure of SQL queries. SQL queries are composed of clauses such as SELECT, FROM, WHERE, and ORDER BY. A basic SQL query might look like: 'SELECT name, position FROM employees WHERE department = 'Sales';'. This retrieves the names and positions of employees in the 'Sales' department.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "First SQL Query",
        "bootstrap_title_desc": "Write and execute your first SQL query to select data from a table. To retrieve all records from the 'employees' table, use: 'SELECT * FROM employees;'. This command fetches every column and row from the table.",
        "copy": true
      }
    ],
    "coursename": "SQL Intro"
  },
  {
    "id": 2,
    "html_title": "sql-basics",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Database Structure",
        "bootstrap_title_desc": "Learn about databases, tables, and relationships. Databases store data in tables, which are organized into rows and columns. For example, a database might have tables like 'employees' and 'departments', with relationships defined between them, such as a foreign key linking an 'employee' to a 'department'.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Creating Tables",
        "bootstrap_title_desc": "Understand how to create tables and define data types. Tables are created using the 'CREATE TABLE' statement. For instance, to create a table for storing employee data: 'CREATE TABLE employees (id INT PRIMARY KEY, name VARCHAR(100), position VARCHAR(50));'. This defines a table with three columns: 'id', 'name', and 'position'.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Inserting Data",
        "bootstrap_title_desc": "Learn how to insert data into tables using SQL. The 'INSERT INTO' statement adds new records to a table. For example, to insert a new record into the 'employees' table: 'INSERT INTO employees (name, position) VALUES ('Bob', 'Manager');'. This adds a new employee named Bob with the position of Manager.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Basic Queries",
        "bootstrap_title_desc": "Write basic SQL queries to retrieve and manipulate data. Basic queries include selecting, updating, and deleting data. For instance, to update an employee's position: 'UPDATE employees SET position = 'Senior Developer' WHERE name = 'Alice';'. This changes Alice's position to Senior Developer.",
        "copy": true
      }
    ],
    "coursename": "SQL Basics"
  },
  {
    "id": 3,
    "html_title": "advanced-sql",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Joins",
        "bootstrap_title_desc": "Learn about different types of joins and how to combine data from multiple tables. Joins allow you to query data from multiple tables based on relationships between them. For example, to join 'employees' with 'departments': 'SELECT employees.name, departments.department_name FROM employees INNER JOIN departments ON employees.department_id = departments.id;'. This retrieves employee names and their respective department names.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Subqueries",
        "bootstrap_title_desc": "Understand how to use subqueries to perform more complex operations. A subquery is a query nested inside another query. For example, to find employees who work in a department with more than 10 employees: 'SELECT name FROM employees WHERE department_id IN (SELECT id FROM departments WHERE employee_count > 10);'. This retrieves employees from departments with more than 10 employees.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Indexes",
        "bootstrap_title_desc": "Learn about indexes and how they improve query performance. Indexes are used to speed up the retrieval of data from a table. For example, to create an index on the 'name' column: 'CREATE INDEX idx_name ON employees (name);'. This improves the performance of queries that search by employee name.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Transactions",
        "bootstrap_title_desc": "Understand transactions and how to ensure data integrity. Transactions allow you to execute a series of SQL statements as a single unit of work. For example: 'BEGIN TRANSACTION; UPDATE employees SET position = 'Director' WHERE id = 1; COMMIT;'. This starts a transaction, updates an employee's position, and commits the changes.",
        "copy": true
      }
    ],
    "coursename": "Advanced SQL"
  },
  {
    "id": 4,
    "html_title": "sql-practice",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "SQL in Real-World Applications",
        "bootstrap_title_desc": "See how SQL is used in real-world applications and scenarios. SQL is fundamental for database management systems used in various industries. For example, in an e-commerce application, SQL is used to manage inventory, process orders, and handle customer data. A query might be 'SELECT product_name, price FROM products WHERE stock > 0;' to retrieve available products.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Performance Tuning",
        "bootstrap_title_desc": "Learn techniques for optimizing SQL queries and database performance. Performance tuning involves optimizing queries and database schema to improve efficiency. For example, to optimize a slow query, you might add an index on frequently queried columns or rewrite the query for better performance. Using 'EXPLAIN' can help analyze and improve query performance.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Best Practices",
        "bootstrap_title_desc": "Understand best practices for writing and managing SQL queries. Best practices include writing efficient queries, ensuring data security, and maintaining a clean schema. For example, using parameterized queries helps prevent SQL injection, and normalizing your database reduces redundancy.",
        "copy": true
      },
      {
        "bootstrap_title_heading": "Final Project",
        "bootstrap_title_desc": "Apply what you've learned in a final project involving a database application. The project will integrate various SQL concepts and techniques. For instance, you might build a database for a library system, including tables for books, members, and loans, and implement complex queries to manage library operations.",
        "copy": true
      }
    ],
    "coursename": "SQL Practice"
  }
];
export default SQL