import React from 'react'
import HeroSection from '../HeroSection'
import Product from '../../Jason'
import { useParams } from 'react-router-dom'
import AboutSection from '../AboutSection'
import TestimonialsPage from '../ReviewSection'
import { Helmet } from 'react-helmet';



const Fullstack = () => {
  const {coursename} = useParams()
  return (
<>
    <Helmet>
    <title>Best {coursename} Course Training In Vadodara</title>
            <meta name="title" content="Best IT Training In Vadodara." />
            <meta name="description" content="Choose In Vadodara Any IT course like data analysis,data science,mern stack development,fullstack devlopement,python,software testing,manual testing.
            providing,nizampura" />
    
    </Helmet>
   <div>

   {
    Product
    .filter((Item) =>Item.Coursename==coursename)
    .map((Item) =>{
      return(
      <>
      <HeroSection img={Item.page_img} title={Item.main} />
      <AboutSection/>
     <TestimonialsPage/>
      </>
      )
    })
   }


  
  
   </div>
   </>
  )
}

export default Fullstack