import React from 'react'
import "./Office.scss"


const Office = () => {

    return (
    <div className='office'>
        
        <h1>Our Office Location</h1>
       
        
     <div className="office-card">
        <div className="office-card-first">
            <h3>First Office</h3>
            <h4>timing: 8am to 6pm</h4>
            <p>Address: <br/>301,Dhun Complex,Nizampura,Near Mahesana Circle,Vadodara Gujarat-390002</p>
        </div>

        <div className="office-card-first">
            <h3>Second Office</h3>
            <h4>timing: 10am to 9pm</h4>
            <p>Address: <br/>202,Kunal Complex,Nizampura,Opp Passport Office,Near Bank Of Baroda,Vadodara Gujarat-390002</p>
        </div>
     </div>
    </div>

    )
}

export default Office