import React from 'react'
import './PortFolio.scss'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Proj from '../Projects/Json';




const PortFolio = () => {



  

  return (

 
    <div className='project-tech' >
    
    <Carousel showIndicators={false}
    showArrows={true}
    autoPlay={true}
    interval={4000}
    infiniteLoop={true}
    transitionTime={1000}
    // stopOnHover={false}
   axis='vertical'
    showStatus={false}>


    {

      Proj.map((item) =>{
        return(
          <div className='project-part'>
          <div className='project-left'>
          <h1>{item.project_title}</h1>
          <p>{item.project_desc}</p>
          </div>
      
          <div className='project-right' onClick={() => window.open(item.project_link, '_blank')}>
          <img src={item.card_img} alt=''/>
          <div className="overlay">
                <div className="text">{item.overlay_text}</div>
                <div className="demo">View</div>
              </div>
         
          </div>
          </div>
        )
      })
      
      
     }

   

    
     </Carousel>
    
     </div>

   
  )
}

export default PortFolio