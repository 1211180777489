// Bottom.js
import React, { useContext, useEffect, useState } from 'react';
import { NavLink} from 'react-router-dom';
import './Bottom.scss';
import MyContext from '../../../Uttis/MyContext';

const Bottom = () => {

  const {location} = useContext(MyContext)

  const handleCallClick = () => {
    const phoneNumber = '9664768292';
    window.location.href = `tel:${phoneNumber}`;
  };

 
  const [hideFooter, setHideFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setHideFooter(false);
      } else {
        setHideFooter(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

  }, []);



  if (!hideFooter || location.pathname.match(/^\/(html|learning|sql|css|certi-details|student-registration|css-advance|javascript|project|reactjs|vxeditor|express-js|excel|cyber-security|php)\//) || location.pathname === '/vxeditor') {
    return null;
  }

 

  return (
    <div className="bottom-navigation">
      <div className="nav-item" onClick={handleCallClick}>
        Call
      </div>
    <div className="nav-item" activeClassName="active" onClick={() =>window.open('https://v-extechsolution.in/batch/web-development-batch1','_blank')}> 
   

        New
        </div>
      <NavLink to="/" className="nav-item" activeClassName="active" exact>
        Home
      </NavLink>
    </div>
  );
};

export default Bottom;
