import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import "./Login.scss"
import MyContext from '../../Uttis/MyContext';

const Login = ({ onLogin, username, password }) => {
 const{setSnack,setMessage}=useContext(MyContext)

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      if (values.username === username && values.password === password) {
        onLogin();
      } else {
        setSnack(true);
        setMessage("Invalid credentials!")
       
      }
    },
  });

  return (
    <div className="containerlog">
      <div className="form-container">
        <h1>V-Ex Tech Solution</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </div>
       
          <button type="submit" className="submit-button">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
