import React, { useEffect, useState } from 'react';
import './Load.scss';
import logo from '../../assets/logo/logo.png';

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loader-container ${loading ? 'loading' : ''}`}>
      
      <img src={logo} alt='' className='logo-loader' />
    </div>
  );
};

export default Loader;
