

const styles = { // optional
  "input": {
    
    "flex-direction": "row",
    
   
     
   
  },
  "select": {
    "flex-direction": "row-reverse",
   

  }
   
  };

const Product = [
    {
      "userid":1,
       "img_page":require('../../../../assets/Paragraph-img/course.jpg'),
      "title": "Talk About Python",
      "stylei":styles.input,
      "desc": `Python is known for its readability and clean syntax.
      Python is in high demand across industries. Many companies, including tech giants like Google, Facebook, and Microsoft, use Python extensively. Learning Python enhances your employability and opens up opportunities in various sectors, including software development, data analysis, machine learning, and more.`
    },
    {
        "userid":2,
        "img_page":require('../../../../assets/Paragraph-img/emi.png'),
        "title": "Why Should I Learn Sql?",
        "stylei":styles.select,
        "desc": `Many job roles across various industries require SQL skills. Whether you're a software developer, data analyst, business analyst, or database administrator, proficiency in SQL is often listed as a desirable or essential skill in job descriptions.
        SQL is a fundamental skill for anyone working with databases or involved in data-related tasks.`
        
      },
      {
        "userid":3,
        "img_page":require('../../../../assets/Paragraph-img/flexibletiming.png'),
        "title": "Data Analytics Better Or Not ?",
        "stylei":styles.input,
        "desc": " learning data analysis provides you with a skill set that is in demand, applicable across various industries, and empowers you to make informed decisions based on evidence. Whether you're interested in a career in data science, business analytics, or you simply want to enhance your problem-solving skills, data analysis is a valuable and rewarding skill to acquire."
        
      },

      {
        "userid":4,
        "img_page":require('../../../../assets/Paragraph-img/interview.jpg'),
        "title": "Let's Build E-Commerce Software",
        "stylei":styles.select,
        "desc": `Building e-commerce software provides developers with valuable learning opportunities in areas such as web development, database management, security, and integration with third-party services.
        building e-commerce software offers a range of benefits for businesses and developers alike.`
        
      },

      {
        "userid":5,
        "img_page":require('../../../../assets/Paragraph-img/placement.jpg'),
        "title": "React js Or Angular",
        "stylei":styles.input,
        "desc": `React has a large and active community, and it is often used with libraries like Redux for state management and React Router for routing. The ecosystem provides a wide range of tools and packages.
        React is often considered easier to learn, especially for developers familiar with JavaScript. Angular has a steeper learning curve, particularly due to TypeScript and its opinionated nature.`
        
      },

      {
        "userid":6,
        "img_page":require('../../../../assets/Paragraph-img/placementsupport.png'),
        "title": "Android Development Right?",
        "stylei":styles.select,
        "desc":" learning Android development is a strategic investment, offering both personal and professional benefits. It empowers you to create applications for a vast and diverse user base, tap into the job market's demand for skilled developers, and contribute to the dynamic and innovative field of mobile app development."
        
      },

      {
        "userid":7,
        "img_page":require('../../../../assets/Paragraph-img/postcourse.png'),
        "title": "Backend Is Required?",
        "stylei":styles.input,
        "desc": "Learning backend development comes with several advantages, especially if you're interested in building full-stack applications or working on the server-side of web and mobile development.learning backend development is a valuable investment for anyone aspiring to become a full-stack developer or specialize in server-side programming. "
        
      },

      {
        "userid":8,
        "img_page":require('../../../../assets/Paragraph-img/practical.jpg'),
        "title": "First Programing Language ?",
        "stylei":styles.select,
        "desc": "HTML, which stands for HyperText Markup Language, is the standard markup language for creating web pages and web applications. It forms the backbone of the content structure on the World Wide Web."
        
      },

      {
        "userid":9,
        "img_page":require('../../../../assets/Paragraph-img/project.png'),
        "title": "perfect design is very important",
        "stylei":styles.input,
        "desc": "learning CSS is a fundamental step in becoming a proficient web developer. It empowers you to create visually appealing, responsive, and accessible websites, and it is a foundational skill for anyone working in the field of web development."
        
      },
      {
        "userid":10,
        "img_page":require('../../../../assets/Paragraph-img/trainers.jpg'),
        "title": "testing website",
        "stylei":styles.select,
        "desc": "learning software testing is essential for anyone involved in software development, whether as a developer, quality assurance engineer, or project manager. It ensures that software products meet high-quality standards, and it contributes to the overall success of a software project. "
        
      },

     
    
  ]


 
  
  export default Product;