import React from 'react'
import "./Pagination.scss"

const Pagination = ({totalPosts,postsPerPage,setCurrentPage,currentPage}) => {

    let pages = [];

    for(let i = 1;i<= Math.ceil(totalPosts/postsPerPage); i++){ 
        pages.push(i)
    }
  return (
    <div className='pagination'>
       

        <button className='btn-1' onClick={() => setCurrentPage(currentPage-1)} disabled={currentPage===1?true:false} >&lt;	&lt;</button>

        {
            pages.map((page,index)=>{
                return <button 
                className={page == currentPage ? 'active' : ''}
                 key={index} 
                onClick={()=>setCurrentPage(page)}>{page}</button>
            })
        }

     
        <button className='btn-2' onClick={() => setCurrentPage(currentPage+1)} disabled={currentPage===pages.length?true:false}>&gt;&gt;</button>


    </div>
  )
}

export default Pagination