const Rev = [
 
    {
      "userid":7,
      "student":require("../../../assets/students/sumit.png"),
      "studentname": "Sumit Vihol",
      "review_decs":`I thoroughly enjoyed my time at V-Ex tech, where I managed to master front-end development within just 3 months.`,
     
    },
   
    {
      "userid":10,
      "student":require("../../../assets/students/sanjay.png"),
      "studentname": "Sanjay Bariya",
      "review_decs":`I have started python with django internship here and doing well ,i suggest to all my friend to come this place for great learning,very nicely teaching concepts, highly recommended`,
   
    },
 
    {
      "userid":20,
      "student":require("../../../assets/students/meet.png"),
      "studentname": "Meet Bhatiya",
      "review_decs":`It was Great Experience and Good Atmosphere for the Intern in this Institute. I learnt a lot from this Intern about My Course. I recommend to join For Data Analytics And Other Courses. Good and Helpful Support from the Developers. I Gain Knowledge very well from this Intern`,
    
    },
    {
      "userid":21,
      "student":require("../../../assets/students/ashwin.jpg"),
      "studentname": "Ashwin Singh",
      "review_decs":`It is a good place for the person who is looking for an internship and I get to know a lot of new things as a full stack developer intern.`,
    },
    {
      "userid":22,
      "student":require("../../../assets/students/dhruv_patel.png"),
      "studentname": "Dhruv Patel",
      "review_decs":`Classes is very good and veer sir is awesome,he knows every technology,i like his hard work and highly recommend wholeheartedly, not for review purpose,this one very great platform for learning on live project in IT students`,
   
    },
 
    {
      "userid":29,
      "student":require("../../../assets/students/het.png"),
      "studentname": "Het Chunara",
      "review_decs":`Studying Full Stack web development With Best Teaching And High Plateform,
      It was nice experience in v-ex tech, there are so many technology which are python, html, css, javascript, php, django, laraval, and all others, I highly recommend for this place to learn in It`,
     
    },
  
    {
      "userid":34,
      "student":require("../../../assets/students/rachana.png"),
      "studentname": "Rachana",
      "review_decs":`To work as a computer engineering intern great experience and good learning experience. I learnt a lot from this internship. I learnt HTML & CSS language. The best IT company in Vadodara for the developers. good support to employees for there work and gain knowledge.`,
    },
  
    {
      "userid":36,
      "student":require("../../../assets/students/krupa.png"),
      "studentname": "Krupa",
      "review_decs":`doing Application developement course using react native in this institute, it is tottaly best, for installation and set up, they took care of everything, i started from scratch, highly recomended`,
    },
 
    {
      "userid":45,
      "student":require("../../../assets/students/mohit (1).png"),
      "studentname": "Mohit Hundlani",
      "review_decs":`As a 12th Science student, joining V Ex Tech (for me) (*you should*) is a great way to explore the IT industry. The institute offers a range of courses to different aspects of IT, including programming languages, web development, data science, and more. `,
     
    },
    {
      "userid":46,
      "student":require("../../../assets/students/shreyank.png"),
      "studentname": "Shreyank",
      "review_decs":`C and c++ teaches well, as a 1st year student,I really learnt practical skills`,
     
    },

    {
      "userid":48,
      "student":require("../../../assets/students/bhavisha.png"),
      "studentname": "Bhavisha",
      "review_decs":`Currently on journey of Full Stack Developer ... It's really proud to be a part of such great team...!! Both offline and video lecs are superb . You can even share any kind of problem with  Veer sir . He is very INSPIRING and full of ENTHUSIASM..and focused on CONCEPTUAL development..!! If want to excel in variety of IT courses, then highly recommended`,
     
    },
    {
      "userid":49,
      "student":require("../../../assets/students/asim.png"),
      "studentname": "Aseem Joshi",
      "review_decs":`Currently on Boat for Full Stack ... My experience with V-Ex Tech sols is quite well and I have learned a lot .. Equal attention is given to every individual which is the best part of Mentor - Veer Sir  . So I would suggest if you are really interested in variety of IT courses and want to excel .. Join V-Ex Tech .. It helps you overcome your fears and helps you in achieving your goals..`,
     
    },

    {
      "userid":51,
      "student":require("../../../assets/students/DEVPANCHAL.png"),
      "studentname": "Dev Panchal",
      "review_decs":`Excellent , I have started my college internship at V-Ex Tech Solutions on early August. There surrounding are good and well enough to do practices also. The HR, Veer Sir are too friendly with the students and always available for my doubts and motivates me towards my goal..!`,
     
    },
    
    {
      "userid":54,
      "student":require("../../../assets/students/dhruv-maheta.png"),
      "studentname": "Dhruv Maheta",
      "review_decs":`V-Ex tech solution is one of the best companies for training and learning new languages for students. This company environment is so friendly and flexible. Here everyone helps each other to learn.`,
     
    },
    {
      "userid":55,
      "student":require("../../../assets/students/JAY-HUDLANI.jpg"),
      "studentname": "Jay Hundlani",  
      "review_decs":`Recently started E-commerce course  with this firm and it  is one of the best coaching institute in Vadodara.`,
     
    },

  
   

  
  
  
  
  
  
  
  
  
    
  ]
  
  export default Rev;