import { Button } from '@mui/material'
import React, { useContext } from 'react'
import {MdOutlineArrowForwardIos} from "react-icons/md"
import "./WebBatch.scss"
import Data from './Json'
import { useParams } from 'react-router-dom'
import {TiArrowRightThick} from "react-icons/ti"
import 'react-accessible-accordion/dist/fancy-example.css';
import {AiFillSafetyCertificate} from "react-icons/ai"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Certificate from '../Header/Students/Certification/Certificate'
import { Helmet } from 'react-helmet'
import MyContext from '../../Uttis/MyContext'


const WebBatch = () => {


const{setJoin} = useContext(MyContext)
  const {batch} = useParams()
  return (
<>
    <Helmet>
    <title> Complete {batch} at &#8377;7599/-</title>
            <meta name="title" content={`Complete ${batch} In Vadodara`}/>
            <meta name="description" content={`V Ex Tech Provides Best ${batch} Training in Vadodara with Job Placement The course covers modules in variation of langauges... .`} />
    
    </Helmet>
    <div className='web-batch'>
{
  Data
  .filter(Item => Item.param === batch)
  .map((Item) => {
    return(
      <div className='join-now-main'>

      <h1>New <span>{Item.batch} </span>PROGRAM</h1>
      <h3>{Item.start_date}</h3>
      <Button className='btn' variant='contained' onClick={()=>setJoin(true)}>Join Now &nbsp; <MdOutlineArrowForwardIos/></Button>
      </div>
    )
   
  })
}

<div className='link-program'>



<a href='https://v-extechsolution.in/html/intro' target='_blank'>Html</a>
<a href='https://v-extechsolution.in/css/intro' target='_blank'>Css</a>
<a href='https://v-extechsolution.in/css-advance/intro' target='_blank'>Css Advance</a>
<a href='https://v-extechsolution.in/bootstrap/intro' target='_blank'>Bootstrap</a>
<a href='https://v-extechsolution.in/javascript/intro' target='_blank'>Javascript</a>
<a href='https://v-extechsolution.in/reactjs/intro' target='_blank'>React Js</a>
<a href='https://v-extechsolution.in/mongodb/intro' target='_blank'>Mongo Db</a>

</div>
   

    <div className='why'>
    <h1>ALL MODULES</h1>


    {
      Data.filter(Item => Item.param === batch).map((Item) => {
        return(


          <>

          {Item.container.map((Item) =>{
            return(
              <div className='why-card'  key={Item.id}>
              <div className='card' style={Item.color}>
              <h3>{Item.name}</h3>
              <p>{Item.dec}</p>
              </div>
             
              </div>
            )
          })}
          
          </>
          
        )
      })
    }
   
    </div>

    <div className='batch-topics'>
    <h1>ALL TOPICS COVERED</h1>
    <div className='list-topics'>
    {
      Data.filter(Item => Item.param === batch).map((Item) => {
        return(
          <>
          
          {
            Item.container.map((Item) =>{
              return(
                <>
                
                {Item.topics.map((Item) =>{
                  return(
                   
                    <div className='list'><TiArrowRightThick size={23}  /> &nbsp;{Item.list}</div>
                    
                  )
                })}
                </>
              )
            })
          }
          </>
        )
      })
    }

    </div>

   


    
    </div>

    <div className='certi-batch'>
    <h1>COURSE CERTIFICATE</h1>
    <h3><AiFillSafetyCertificate/>Start today <span onClick={()=>setJoin(true)}>Join</span></h3>
    <Certificate/>
     </div>

    <div className='who'>
    <h1>Who Can Join This Batch <br/><span>People frequently Asked questions here...</span></h1>
   

    <div className='free'>
    <Accordion allowZeroExpanded style={{border:"none"}}>
    {Data.map((Item) => {
      return(
<>
{Item.container.map((Item) =>{
  return(
    <>
    {Item.accordian.map((Item) => {
      return(
        <AccordionItem key={Item.id} style={{border:"none"}}>
        <AccordionItemHeading >
            <AccordionItemButton style={{borderBottom:"1px solid rgba(0,0,0,0.2)",fontWeight:900,fontFamily:"sans-serif"}}>
                {Item.ques}
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel style={{backgroundColor:"white",border:"none",fontFamily:"monospace"}}>
          {Item.ans}
        </AccordionItemPanel>
    </AccordionItem>
      )
    })}
    </>
  )
})}


</>
      )
    }
        
    )}
</Accordion>
    </div>
    </div>

   

    

    

   
    </div>

    </>
  )
}

export default WebBatch