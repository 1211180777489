import React from 'react';
import './Terms.scss';

const Terms = () => {
  return (
    <section id="terms-of-service">
      <div className="card">
        <h1 className="primary-heading">Terms and Conditions</h1>

        <h2 className="section-heading">1. Acceptance of Terms</h2>
        <p className="paragraph">
          By accessing or using V-Ex Tech Solution's IT services, website, or any associated software, you agree to be bound by these Terms and Conditions.
        </p>

        <h2 className="section-heading">2. User Conduct</h2>
        <p className="paragraph">
          You agree not to engage in any activity that disrupts, interferes with, or compromises the security or functionality of the services provided by V-Ex Tech Solution.
        </p>

        <h2 className="section-heading">3. Intellectual Property</h2>
        <p className="paragraph">
          All software, content, and materials provided by V-Ex Tech Solution are protected by intellectual property laws and may not be used, reproduced, or distributed without permission.
        </p>

        <h2 className="section-heading">4. Limitation of Liability</h2>
        <p className="paragraph">
          V-Ex Tech Solution shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your access to or use of our IT services, software, or website.
        </p>

        <h2 className="section-heading">5. Service-Level Agreement (SLA)</h2>
        <p className="paragraph">
          V-Ex Tech Solution aims to provide consistent and reliable IT services. Any downtime or service interruptions will be communicated in advance when possible. We strive to resolve any issues within the shortest possible timeframes.
        </p>

        <h2 className="section-heading">6. Indemnification</h2>
        <p className="paragraph">
          You agree to indemnify and hold V-Ex Tech Solution harmless from any claims, losses, liabilities, damages, costs, and expenses arising out of or relating to your use of our IT services or any violation of these Terms.
        </p>

        <h2 className="section-heading">7. Confidentiality</h2>
        <p className="paragraph">
          Both parties agree to keep confidential any proprietary or sensitive information exchanged during the provision of services. This obligation will survive the termination of the agreement.
        </p>

        <h2 className="section-heading">8. Termination of Services</h2>
        <p className="paragraph">
          V-Ex Tech Solution reserves the right to terminate or suspend access to our services at any time, with or without notice, for any reason, including but not limited to breach of these Terms.
        </p>

        <h2 className="section-heading">9. Governing Law</h2>
        <p className="paragraph">
          These Terms and Conditions shall be governed by and construed in accordance with the laws applicable to V-Ex Tech Solution's operations.
        </p>

        <h1 className="primary-heading">Refund Policy</h1>

        <h2 className="section-heading">1. Eligibility for Refunds</h2>
        <p className="paragraph">
          Refunds are only available for services canceled within 2 days of the initial purchase. No refunds will be issued after the service has commenced or after this period.
        </p>

        <h2 className="section-heading">2. Refund Process</h2>
        <p className="paragraph">
          To request a refund, please contact our support team with your service details and reason for cancellation.
        </p>

        <h2 className="section-heading">3. Refund Timeframe</h2>
        <p className="paragraph">
          Once your request is received and processed, we will notify you via email of the approval or rejection of your refund. Approved refunds will be credited within 7 days to the original payment method.
        </p>

        <h1 className="primary-heading">Privacy Policy</h1>

        <h2 className="section-heading">1. Information We Collect</h2>
        <p className="paragraph">
          We collect personal information such as your name, email address, and payment details when you engage our services or sign up for our newsletter.
        </p>

        <h2 className="section-heading">2. How We Use Your Information</h2>
        <p className="paragraph">
          We use your information to deliver and improve our IT services, communicate with you, and manage our operations.
        </p>

        <h2 className="section-heading">3. Cookies</h2>
        <p className="paragraph">
          We use cookies to personalize content, analyze traffic, and enhance your experience on our website.
        </p>

        <h2 className="section-heading">4. Data Security</h2>
        <p className="paragraph">
          We implement robust security measures to protect your data from unauthorized access or disclosure.
        </p>

        <h2 className="section-heading">5. Changes to This Privacy Policy</h2>
        <p className="paragraph">
          We reserve the right to update or modify our Privacy Policy at any time, with or without notice.
        </p>
      </div>
    </section>
  );
};

export default Terms;
