import React, { useEffect, useState } from 'react';
import './Grid.scss';

import img1 from '../../../../assets/Grid-img/data.png';
import img2 from '../../../../assets/Grid-img/api.png';
import img3 from '../../../../assets/Grid-img/app.png';
import img4 from '../../../../assets/Grid-img/c.png';
import img5 from '../../../../assets/Grid-img/cloud.png';
import img6 from '../../../../assets/Grid-img/BD.png';
import img7 from '../../../../assets/Grid-img/dataanalysis.png';
import img8 from '../../../../assets/Grid-img/html.png';
import img9 from '../../../../assets/Grid-img/python.png';
import img10 from '../../../../assets/Grid-img/php.png';
import img11 from '../../../../assets/Grid-img/comp.png';
import img12 from '../../../../assets/Grid-img/ui.png';

const Grid = () => {

  const[a,setA] = useState('scale(1)')
  const[b,setB] = useState('rgba(0, 0, 0, 0.409)')

  const handles = () =>{
    if(window.scrollY>300){
      setA('scale(1.2)')
      setB('transparent')
    }else{
      setA('scale(1)')
      setB('rgba(0, 0, 0, 0.409)')
    }
  }

  useEffect(()=>{
window.addEventListener('scroll',handles)
  },[])
  const data = [
    { id: 1, ImgSrc: img1 },
    { id: 2, ImgSrc: img2 },
    { id: 3, ImgSrc: img3 },
    { id: 4, ImgSrc: img4 },
    { id: 5, ImgSrc: img5 },
    { id: 6, ImgSrc: img6 },
    { id: 7, ImgSrc: img7 },
    { id: 8, ImgSrc: img8 },
    { id: 9, ImgSrc: img9 },
    { id: 10, ImgSrc: img10 },
    { id: 11, ImgSrc: img11 },
    { id: 12, ImgSrc: img12 },
  ];

  return (
    <div className='grid-container'>
  
        {data.map((item, index) => (
          <div className='grid-item' key={index}>
            <img src={item.ImgSrc} alt={`img-${item.id}`} style={{transform:a}}/>
            <div className='overlay' style={{backgroundColor:b}}></div>
          </div>
        ))}
    
    </div>
  );
};

export default Grid;
