import React from 'react'
import "./Tools.scss"
import Tjson from './Json'

const Tools = () => {
  return (

    <>
  {
    Tjson.map((Item) => {
        return(
            <div className="animated-paragraph-container" key={Item.id}>

            <h1>{Item.title}</h1>
          <p className="animated-paragraph">
           {Item.desc}
          </p>
          <a href={Item.link} target='_blank' className="animated-link">
            Go To {Item.goto}
          </a>
        </div>
        )
    })
  }
   

    </>
  )
}

export default Tools