import {BiAtom} from "react-icons/bi"
import {BsGithub} from "react-icons/bs"
import {VscCommentUnresolved} from "react-icons/vsc"
import {SiTestcafe} from "react-icons/si"
import {TfiMicrosoftAlt} from "react-icons/tfi"
import {MdOutlineAccessTimeFilled} from "react-icons/md"
import {GiTeacher} from "react-icons/gi"
import {FaFilePdf,FaLaptop} from "react-icons/fa"



const Product = [


    {
        "id":1,
        "icon":<BiAtom/>,
        "head":"Custom Software Development",
        "desc":"Provide end-to-end software development services, from concept to deployment. "
        

    },

   

    {
        "id":2,
        "icon":<VscCommentUnresolved/>,
        "head":"Mobile App Development",
        "desc":"Create mobile applications for iOS and Android platforms."

    },
        


        {
            "id":3,
            "icon":<SiTestcafe/>,
            "head":"E-commerce Solutions:",
            "desc":"Build custom e-commerce platforms for online businesses. "
            
    
        },

        {
            "id":4,
            "icon":<TfiMicrosoftAlt/>,
            "head":"Data Analytics and Business Intelligence",
            "desc":"Develop dashboards and reporting tools for data-driven decision-making."
            
    
        },

        {
            "id":5,
            "icon":<GiTeacher/>,
            "head":"IT Consulting",
            "desc":"Assist clients in making informed technology decisions. "
            
    
        },

        {
            "id":6,
            "icon":<MdOutlineAccessTimeFilled/>,
            "head":"UI/UX Design Services",
            "desc":`Provide prototyping, wireframing, and user testing services.`
            
    
        },

        {
            "id":7,
            "icon":<FaFilePdf/>,
            "head":"Cyber security Services",
            "desc":"Implement security measures to protect client data and applications"
            
    
        },
    

    {
        "id":8,
        "icon":<FaLaptop/>,
        "head":"Work On Live Project",
        "desc":"Chance To Work On Live Practical Project "
        

    },
]

export default Product;