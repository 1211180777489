

const ExcelJson = [

  {
    "id": 1,


    "html_title": "intro",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Why Study Excel?",
        // "bootstrap_title2_heading": "",
      
        "bootstrap_title_desc": `It is the most popular spreadsheet program in the world
        It is easy to learn and to get started.
        The skill ceiling is high, which means that you can do more advanced things as you become better
        It can be used with both work and in everyday life, such as to create a family budget
        It has a huge community support
        It is continuously supported by Microsoft
        Templates and frameworks can be reused by yourself and others, lowering creation costs.`,
        "html_title2": `Excel is typically used for:

-- Analysis
-- Data entry
-- Data management
-- Accounting
-- Budgeting
-- Data analysis
-- Visuals and graphs
-- Programming
-- Financial modeling
-- And much, much more!`,
        "bootstrap_title_desc": `Excel is a powerful spreadsheet program developed by Microsoft. It's widely used for organizing, analyzing, and presenting data in tabular formats. Here are some key features and uses of Excel:

Data Entry and Storage: Excel provides a grid interface where users can enter and store data in rows and columns. This makes it easy to organize and manage large amounts of data.
Calculations and Formulas: Excel supports a wide range of mathematical and logical formulas that allow users to perform calculations on their data. Formulas can be used for simple arithmetic operations, as well as complex calculations involving functions like SUM, AVERAGE, IF, and VLOOKUP.
Charts and Graphs: Excel offers various chart types, such as bar charts, line graphs, and pie charts, which can be used to visualize data and trends. Charts can be easily created and customized to suit the user's needs.
Data Analysis: Excel includes features for data analysis, such as sorting, filtering, and pivot tables. These tools make it easy to analyze and summarize data to extract meaningful insights.
Data Visualization: In addition to charts and graphs, Excel offers features like conditional formatting, which allow users to visually highlight important data points based on specific criteria.
Data Import and Export: Excel supports importing data from external sources, such as databases and text files, as well as exporting data to various formats for sharing and collaboration.
Automation: Excel includes a powerful scripting language called Visual Basic for Applications (VBA), which allows users to automate repetitive tasks and customize the functionality of Excel to suit their needs.`
      },

      {
        "bootstrap_title_heading": "Installation",
        
        
        "bootstrap_title_desc": `Once you have successfully logged into Office through www.office.com, click on the Excel icon on the left side to enter the application:,

The Excel view has columns and rows, similar to a squared math exercise book.

Do not worry if the functionality looks overwhelming at first. You will get comfortable as you learn more in the chapters to come.

For now focus on the rows, columns, and the cells.

Ok. Let's make a function!

First, double click the cell A1, the one that is marked with the green rectangle in the picture.
Second, type =1+1.
Third, hit the enter button:

Overall, Excel is a versatile tool that is widely used in various industries for tasks ranging from simple data entry to complex data analysis and reporting.`

      }
    ],

 

    "coursename": "Intro",


  },



  {
    "id": 2,


    "html_title": "overview",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Overview",
        
   
        "bootstrap_title_desc": `This chapter is about giving you an overview of Excel.
Excel's structure is made of two pieces, the Ribbon and the Sheet.
Have a look at the picture below. The Ribbon is marked with a red rectangle 
and the Sheet is marked with a yellow rectangle:`,


      },

      {
        "bootstrap_title_heading": "Multiple Sheets",
        
       

        
        
        "bootstrap_title_desc": `You start with one Sheet by default when you create a new workbook.
You can have many sheets in a workbook. New sheets can be added and removed. 
Sheets can be named to making it easier to work with data sets.
Are you up for the challenge? Let's create two new sheets and give them useful names.
First, click the plus icon, shown in the picture below, create two new sheets:`,

      },

      {
        "bootstrap_title_heading": "Practice Test",
  

        
        
        "bootstrap_title_desc": `Create 100 Data Of Students In Exel
Column Should Be 7`,

      },


    
    ],












    "coursename": "OverView",


  },



  {
    "id": 3,


    "html_title": "exel-function",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Select Function",
        
       

        
        
        "bootstrap_title_desc": `Select the cell C1
Type the equal sign (=)
Left click on A1, the cell that has the (309) value
Type the minus sign (-)
Left click on B2, the cell that has the (35) value
Hit enter`,


      },

      {
        "bootstrap_title_heading": "Ranges",
        
        //

        
        
        "bootstrap_title_desc": `There are four different operations for selection;

Selecting a cell
Selecting multiple cells
Selecting a column
Selecting a row`,
// 
      },

   
      {
        "bootstrap_title_heading": "Select Multiple Cells",
        
       

        
        
        "bootstrap_title_desc": `More than one cell can be selected by pressing and holding down CTRL or Command 
and left clicking the cells. Once finished with selecting, you can let go of CTRL or Command.`,

      },

      {
        "bootstrap_title_heading": "Select Column",
        
       

        
        
        "bootstrap_title_desc": `Columns are selected by left clicking it. This will select 
all cells in the sheet related to the column.`,

      },

      {
        "bootstrap_title_heading": "Select Entire Screen",
        
       

        
        
        "bootstrap_title_desc": `The entire spreadsheet can be selected by clicking the triangle
in the top-left corner of the spreadsheet:`,

      },

      {
        "bootstrap_title_heading": "Sequence of Word",
        
       

        
        
        "bootstrap_title_desc": `The fill function can also be used to fill number.`,

      },


    
    ],












    "coursename": "Exel Function",


  },



 
  {
    "id": 4,


    "html_title": "move-ceil",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Copy And Paste",
        
       

        
        
        "bootstrap_title_desc": `Ranges can be moved by cutting and pasting values from one place to another.
ctrl + X then ctrl + V`,


      },

      {
        "bootstrap_title_heading": "ceil Add",
        
       

        
        
        "bootstrap_title_desc": `Columns can be added and deleted. You access the menu by
right clicking the column letter. New columns are added to the same place you clicked.`,


      },

      {
        "bootstrap_title_heading": "Undo And Redu",
        
        //

        
        
        "bootstrap_title_desc": `The Undo function lets you reverse an action.

Undo is helpful if you regret an action and want to go back to how it was before.

Examples of use

Undo deleting a formula
Undo adding a column
Undo removing a row


The Redo function has the opposite effect as Undo, it reverses the Undo action.

Redo is helpful if you regret using Undo.`,

// 
      },

      
      {
        "bootstrap_title_heading": "Sum Of Ceil",
        
       

        
        
        "bootstrap_title_desc": `Type Ceil(=)
Write SUM
Double click SUM in the menu
Mark the range E2:E4
Hit enter`,


      },

      {
        "bootstrap_title_heading": "Icon In Ceil",
        
       

        
        
        "bootstrap_title_desc": `We can Add Icon In Ceil`,


      },

    

   
     


    
    ],












    "coursename": "Move Ceil",


  },




  {
    "id": 5,


    "html_title": "Sorting",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Sort",
        
       

        
        
        "bootstrap_title_desc": `Ranges can be sorted using the Sort Ascending and Sort Descending commands.

Sort Ascending: from smallest to largest.

Sort Descending: from largest to smallest.

The sort commands work for text too, using A-Z order.`,


      },

      {
        "bootstrap_title_heading": "Filter",
        
       

        
        
        "bootstrap_title_desc": `Filters can be applied to sort and hide data. It makes data analysis easier.
Filters are applied by selecting a range and clicking the Filter command.

It is important to have a row of headers when applying filters. Having headers is
useful to make the data understandable`,


      },

    

    

   
     


    
    ],












    "coursename": "Sorting",


  },






  {
    "id": 6,


    "html_title": "create-tables",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Insert Tables",
        
       

        
        
        "bootstrap_title_desc": `Click Insert, then Table, in the Ribbon.`,


      },

      {
        "bootstrap_title_heading": "Table Style",
        
       

        
        
        "bootstrap_title_desc": `Clicking the Table Style option button opens a menu with different style options.

Here the three categories are presented; Light, Medium and Dark.`,


      },

    

    

   
     


    
    ],












    "coursename": "Create Table",


  },

    {
    "id": 7,


    "html_title": "conditional-formatting",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Conditional Formatting",
        
        //

        
        
        "bootstrap_title_desc": `Conditional formatting is used to change the
appearance of cells in a range based on your specified conditions.

The conditions are rules based on specified numerical values or matching text.`,

// 
      },


        {
        "bootstrap_title_heading": "Highlight Cell Rules",
        
       

        
        
        "bootstrap_title_desc": `Highlight Cell Rules is a premade type of conditional formatting 
in Excel used to change the appearance of cells in a range based on your specified conditions.

The conditions are rules based on specified numerical values, matching text, 
calendar dates, or duplicated and unique values.`,


      },


    
    ],












    "coursename": "Conditional Formatting",


  },





  {
    "id": 8,


    "html_title": "top-bottom-rules",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Top/Bottom Rules",
        
       

        
        
        "bootstrap_title_desc": `Top/Bottom Rules are premade types of conditional
formatting in Excel used to change the appearance of cells in a range based
on your specified conditions.`,


      },


   

    
    ],












    "coursename": "Top Bottom Rules",


  },

  {
    "id": 9,


    "html_title": "data-bars",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Data Bars",
        
       

        
        
        "bootstrap_title_desc": `Let's apply the Data Bars conditional formatting to the Speed values.
Click on the Conditional Formatting icon  in the ribbon, from Home menu
Select Data Bars from the drop-down menu
Select the "Green Data Bars" color option from the Gradient Fill menu`,


      },

      {
        "bootstrap_title_heading": "Color Scale",
        
       

        
        
        "bootstrap_title_desc": `Highlight the Speed values of each Pokemon with Color Scale conditional formatting`,


      },


      {
        "bootstrap_title_heading": "icon set",
        
       

        
        
        "bootstrap_title_desc": `Icon Sets are premade types of conditional formatting in Excel used to add icons to cells in a range to indicate how large the cell values are compared to the other values in the range.`,


      },



    

    ],












    "coursename": "Data Bars",


  },




  {
    "id": 10,


    "html_title": "manage-rules",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Manage Rules",
        
       

        
        
        "bootstrap_title_desc": `Conditional Formatting rules can be managed and removed using the Clear Rules and Manage Rules features.

Here is the Clear Rules part of the conditional formatting menu:`,


      },

    

      

    

    ],












    "coursename": "Manage Rules",


  },


 
 



  {
    "id": 11,


    "html_title": "exel-charts",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Chart",
        
       

        
        
        "bootstrap_title_desc": `Charts are visual representations of data used to make it more understandable.

Commonly used charts are:

Pie chart
Column chart
Line chart`,


      },

      {
        "bootstrap_title_heading": " Graph Chart",
        
       

        
        
        "bootstrap_title_desc": `Column charts are suited for representing values of qualitative (categorical) data.`,


      },

    

      

    

    ],












    "coursename": "Chart",


  },


  {
    "id": 11,


    "html_title": "excel-interview-question",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "What is Excel used for?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: Excel is a spreadsheet software used for tasks
such as data entry, calculation, analysis, and visualization of data.`,


      },

      {
        "bootstrap_title_heading": "Explain the difference between a workbook and a worksheet.",
        
        //

        
        
        "bootstrap_title_desc": `Answer: A workbook is the entire Excel file, while
a worksheet is a single sheet within
that file where data is organized into rows and columns.`,


      },

      {
        "bootstrap_title_heading": "How do you navigate between worksheets in a workbook?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: You can navigate between worksheets by clicking on the
sheet tabs at the bottom of the Excel window.`,


      },

      {
        "bootstrap_title_heading": "What is a cell reference?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: A cell reference is a combination of a letter and a number that
identifies a specific cell in Excel, such as A1 or B3.`,


      },

      {
        "bootstrap_title_heading": "How can you insert a new row or column in Excel?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: To insert a new row, right-click on the row number and select "Insert."
To insert a new column, right-click on the column letter and select "Insert."`,


      },

      {
        "bootstrap_title_heading": "What is the difference between relative and absolute cell references?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: A relative cell reference changes when a formula is copied
to another cell, while an absolute cell reference stays fixed. Absolute references 
are denoted by placing a "$" before the column letter and/or row number.`,


      },

      {
        "bootstrap_title_heading": "Describe the purpose of PivotTables.",
        
        //

        
        
        "bootstrap_title_desc": `PivotTables are used to summarize, analyze, and present large
amounts of data in a concise and tabular format, allowing 
users to make sense of complex datasets.`,


      },

      {
        "bootstrap_title_heading": "How can you remove duplicate values from a dataset in Excel?",
        
        //

        
        
        "bootstrap_title_desc": `Answer: Use the Remove Duplicates feature in the Data 
tab to eliminate duplicate values from selected columns or the entire dataset.`,


      },


      {
        "bootstrap_title_heading": "How can you create a dynamic named range in Excel?",
        
        //

        
        
        "bootstrap_title_desc": `Use the OFFSET function within the Name Manager to create a dynamic
named range that adjusts based on the data in a worksheet.`,


      },

      {
        "bootstrap_title_heading": "Explain the purpose of Power Query in Excel.",
        
        //

        
        
        "bootstrap_title_desc": `Answer: Power Query is a data connection technology that enables
users to discover, connect, combine, and refine data across a wide variety of sources
for better analysis in Excel.`,


      },




     

  
    

      

    

    ],












    "coursename": "Excel Interview Question",


  },




 










]
export default ExcelJson