import React from 'react'
import "./Payment.scss"
import { TbBrandCashapp } from 'react-icons/tb'

import { FaMoneyCheckAlt } from 'react-icons/fa'
import { GiMoneyStack } from 'react-icons/gi'
import { BsFillCreditCard2BackFill } from 'react-icons/bs'
import { MdOutlineBookOnline } from 'react-icons/md'




const Payment = () => {
  return (
    <div className='Main-payment'>
      <div className="circle-main">
        <div className='border'>
          <div className='methods'> web-developement </div>
        </div>
        <div className="payment-method">
          <div className="cash"> <TbBrandCashapp /> <span>FRONTEND</span></div>

          <div className="chaque"> <FaMoneyCheckAlt /> <span>BACKEND</span></div>
          <div className="direct-deposit"> <GiMoneyStack /> <span>API CALL</span></div>
          <div className="cards"> <BsFillCreditCard2BackFill /> <span>DATABASE</span></div>
          <div className="mobile-wallet"> <MdOutlineBookOnline /> <span>GITHUB</span></div>
        </div>
      </div>

      
    

    </div>
  )
}

export default Payment