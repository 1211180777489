// Creative.jsx

import React from 'react';
import "./Creative.scss";
import { useState } from 'react';
import { useEffect } from 'react';

const Creative = () => {

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
      const handleScroll = () => {
          setScrollY(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);

  
  }, []);

  const translateX = Math.min(650, Math.max(0, scrollY - 400)); 

  const Box1 = () =>{
    document.getElementById('box1').style.display="flex"
    document.getElementById('box2').style.display="none"
    document.getElementById('span1').style.display="block"
    document.getElementById('span2').style.display="none"
  }
  const Box2 = () =>{
    document.getElementById('box2').style.display="flex"
    document.getElementById('box1').style.display="none"
    document.getElementById('span1').style.display="none"
    document.getElementById('span2').style.display="block"
  }
  return (
    <div className="creative-container-unic">
      <div className="box-unic" style={{ transform: `translateX(${translateX}px)` }}>
        <h2 onMouseOver={Box1}>Web Solution <span id='span1'>&gt;</span></h2>
        <h2 onMouseOver={Box2}>Mobile App Solution <span id='span2'>&gt;</span></h2>

       
      </div>
      <div className="box-unic" style={{ transform: `translateX(-${translateX}px)` }}>

      <div className='box1' id='box1' >
       
<h3>Website Developement</h3>
<h3>Web Design</h3>
<h3>Digital Marketing</h3>
</div>

<div className='box2' id='box2' >
       
<h3>Mobile Application</h3>
<h3>Android</h3>
<h3>IOS</h3>
</div>

      </div>
    </div>
  );
}

export default Creative;
