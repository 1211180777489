import "./Header.scss";
import { useEffect,useState,useContext } from "react";
import {Link} from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {FiPhoneCall,FiUser} from "react-icons/fi"
import {RiServiceFill} from "react-icons/ri"
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import logo from "../../assets/logo/logo.png"
import 'reactjs-popup/dist/index.css';
import MyContext from "../../Uttis/MyContext";

const Header = () => {
    const [scrolled,setScrolled] = useState(false)
    
const {openMenu,openSearch,openLeft,navigate} =useContext(MyContext)

    const HandleScroll = () =>{
        
        const offset = window.scrollY
        if (offset>300){
            setScrolled(true);

        }else{
            setScrolled(false);
        }
       
    }


    useEffect(()=>{
        window.addEventListener("scroll",HandleScroll)
    },[])

  
    return (
        <>
        <header className={`main-header ${scrolled ? 'sticky-header' : ""}`}>
            <div className="header-content">
                <ul className="left">
                    <Link to="/contact">
                    <div className="icon-part">
                    <FiPhoneCall/> 
                   <li>Contact</li>
                   </div>
                   </Link>

                   <Link to="/service">
                   <div className="icon-part">
                    <RiServiceFill/> 
                   <li> Service</li>
                   </div>
                   </Link>

                   <Link to="/about">
                   <div className="icon-part">
                    <FiUser/> 
                   <li>About us</li>
                   </div>
                   </Link>
                  
                </ul>

                
                <div className="center" onClick={() => navigate('/')}>
                <img src={logo} alt=""/>
                </div>
              
                <div className="right">
                
                <section class="stage">
                <figure class="ball">
                  <span class="shadow"></span>
                  <span class="iris"></span>
                </figure>
          </section>

          
                <button class="animated-button" onClick={() =>window.open('https://components.v-extechsolution.in/','_blank')}>
    
                <span class="text">Components</span>
                <span class="circle"></span>
              
              </button>

            
              
                   <a href="https://view.v-extechsolution.in/login" target="_blank"> <AccountCircleOutlinedIcon/></a>

                 

                   <SearchOutlinedIcon  onClick={openSearch} />

                    <DownloadingOutlinedIcon onClick={openLeft} />


     


                    
                    

                  
                        <MenuOpenOutlinedIcon onClick={openMenu}/>
                       
                </div>
            </div>
        </header>

       
               


         
      



            


           


      
        
    
          
        
        </>
    )
    
    
};

export default Header;