import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./Report.scss"
import jsonData from './Json';
import { Helmet } from 'react-helmet';


const Report = () => {



  
  const handleNavigate = (Item) => {




    const dynamicUrl = `/internship-report/${Item}`;
    const fullUrl = window.location.origin + dynamicUrl;

    //     // Open the link in a new tab
    window.open(fullUrl, '_blank');
};
  return (
<>
    <Helmet>
<title>Iternship final year Report For Students</title>
        <meta name="title" content="internship final year report pdf" />
        <meta name="description" content="Choose In Vadodara Any IT course like data analysis,data science,mern stack development,fullstack devlopement,python,software testing,manual testing.
        providing,nizampura" />

</Helmet>

    <div className='report-main'>
    <Tabs>
    <TabList>
    {jsonData.map((item) => {
      return(

     
        <Tab>{item.title}</Tab>
    
     
      )
    })}
    </TabList>


    {jsonData.map((item) =>{
      return(

       
        <TabPanel>
        <div className='content-on'>
      {
        item.cards.map((item) =>{
          return(
            
            <div className='report-content' onClick={() => handleNavigate(item.value)}>
            <div className='overlay'>  <h1>{item.value}</h1>  <p>{item.project}</p> <p style={{textDecoration:"underLine"}}>click</p></div>
          <p className='vis'>{item.project}</p>
            </div>
           
        
          )
        })
      }
      </div>
      
      </TabPanel>
     
  
      )
    })}
   
 
  </Tabs>
  </div>

  </>
  )
}

export default Report