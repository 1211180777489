import React from 'react';
import { BsDownload } from 'react-icons/bs';
import jsonData from './Json';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import "./ViewReport.scss"

const ViewReport = () => {
    const { program } = useParams();

    return (
        <>
            <Helmet>
                <title>{program} Iternship final year Report For Students</title>
                <meta
                    name="title"
                    content="internship final year report pdf"
                />
                <meta
                    name="description"
                    content="Choose In Vadodara Any IT course like data analysis,data science,mern stack development,fullstack devlopement,python,software testing,manual testing. providing,nizampura"
                />
            </Helmet>

            <div className="report-container">
            {jsonData.map((item, index) => {
              return (
                <div key={index} className="report-card">
                  {item.cards
                    .filter((item) => item.value === program)
                    .map((card, cardIndex) => {
                      return (
                        <div key={cardIndex}>
                          <h2>{card.value}</h2>
                          <a href={card.link} download={card.value} className="download-link">
                            <span><BsDownload />  Download</span>
                          
                          </a>
                          <div className="pdf-container">
                            <embed
                              src={card.link}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
          
        </>
    );
};

export default ViewReport;
