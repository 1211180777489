import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './CertiDetails.scss';
import MyContext from '../../../Uttis/MyContext';

const CertiDetails = () => {
  const {setMessage,setSnack,setLoading}= useContext(MyContext);

const[certiDetails, setCertiDetails] =useState(null)

  const formik = useFormik({
    initialValues: {
      certiid: '',
    },
    validationSchema: yup.object({
      certiid: yup.string('Enter your certiid').required('Certification ID is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetch('https://bottom-seven.vercel.app/certi-details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ certiid: values.certiid }),
        });
        const data = await response.json();
        if (data.success) {
          setCertiDetails(data.data);
        } else {
          setMessage(data.message);
          setSnack(true)
          
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
   
   
     
          <div className="certi-details-form">
            <h1>Certificate Details</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <TextField
                  id="certiid"
                  name="certiid"
                  value={formik.values.certiid}
                  onChange={formik.handleChange}
                  error={formik.touched.certiid && Boolean(formik.errors.certiid)}
                  helperText={formik.touched.certiid && formik.errors.certiid}
                  label="Certification ID"
                  variant="standard"
                  fullWidth
                />
              </div>
              <Button variant="contained" type="submit" fullWidth>
                Submit
              </Button>
            </form>

            {certiDetails && (
              <div className="certi-details-info">
                <h2>Certificate Details</h2>
                <p><strong>Name:</strong> {certiDetails.name}</p>
                <p><strong>DOB:</strong> {certiDetails.dob}</p>
                <p><strong>Start Date:</strong> {certiDetails.startDate}</p>
                <p><strong>End Date:</strong> {certiDetails.endDate}</p>
                <p><strong>Work:</strong> {certiDetails.work}</p>
              </div>
            )}
          </div>
      
      
    
  );
};

export default CertiDetails;
