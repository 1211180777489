import React, { useContext } from 'react'
import './Menu.scss'
import PData from './Json';
import MyContext from '../../../Uttis/MyContext';
import { MdOutlineArrowRightAlt } from "react-icons/md";

const Menu = () => {

  const{closeMenu} =useContext(MyContext)
  
  return (
    
    <div className='side-menuv'>
    
    <div className='menu-listv'>
   

        {PData.map((Item)=>{
            return(

                <li onClick={()  =>window.open(`/learning/${Item.course}/intro`) || closeMenu()} >{Item.course}  {Item.icons} <MdOutlineArrowRightAlt color='rgb(255, 255, 255)'/></li>

            )
        })

        }
            
           


       

    </div>
   
    </div>
   
  )
}

export default Menu