import React from 'react';
import './AboutSection.scss';

const AboutSection = () => {
  return (
    <div className="about-section-u">
      <div className="content-wrapper">
      
          <h2>About V-Ex Tech Solution</h2>
          <p>We are dedicated to crafting innovative software solutions that transform businesses and empower individuals.</p>
        
      </div>
      <div className="details-section">
        <div className="details-header">
          <h3>Our Mission</h3>
          <p>Our mission is to revolutionize the digital landscape through cutting-edge technology, intuitive user experiences, and unparalleled customer support.</p>
        </div>
      
          <div className="details-header">
            <h3>Our Services</h3>
            <ul>
              <li>
                <span>Custom Software Development:</span> From concept to deployment, we build tailored solutions to meet your unique business needs.
              </li>
              <li>
                <span>Mobile App Development:</span> We create engaging and user-friendly mobile applications for iOS and Android platforms.
              </li>
              <li>
                <span>Web Development:</span> Our team specializes in crafting responsive and scalable web applications using the latest technologies.
              </li>
              <li>
                <span>Consulting Services:</span> We offer expert guidance and strategic advice to help you navigate the complexities of the digital landscape.
              </li>
            </ul>
          </div>
        
      </div>
    </div>
  );
};

export default AboutSection;
