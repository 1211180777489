

const Javas = [

  {
 "id": 1,


 "html_title": "intro",


 "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "Introduction",
 

  "bootstrap_title_desc": `JavaScript is the world's most popular programming language.
JavaScript is the programming language of the Web. JavaScript is easy to learn. From basic syntax and data types to advanced topics such as object-oriented programming and DOM manipulation.JavaScript (JS) is the most popular lightweight, interpreted compiled programming language. It can be used for both Client-side as well as Server-side developments. JavaScript also known as a scripting language for web pages.`,
copy:true
},


 

{

  "bootstrap_title_heading": "usage",
  
  "bootstrap_title_desc": `-- JavaScript Can Change HTML Content
One of many JavaScript HTML methods is getElementById().
  
-- javascript can also change css style just on click,
will see everything step by step.`,
copy:true
}
 ,
 


 

{
  "bootstrap_title_heading": "Installation",
 
 
  "bootstrap_title_desc": `You don't have to get or download JavaScript.

JavaScript is already running in your browser on your computer, on your tablet, and on your smart-phone.
  
JavaScript is free to use for everyone.

Holding Keywords
1) getElementById -- for id
2) Queryselecor -- for class
3) QueryseletorAll -- for all class`,
copy:true





}
    ],



    "coursename": "Js Intro (day-1)",


  },



  {
    "id": 2,


    "html_title": "basics",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Change Html Content",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h2>What Can JavaScript Do?</h2>

<p id="demo">JavaScript can change HTML content.</p>

<button type="button" onclick='document.getElementById("demo").innerHTML = "Hello JavaScript!"'>Click Me!</button>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Can Change Styles",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h2>What Can JavaScript Do?</h2>

<p id="demo">JavaScript can change the style of an HTML element.</p>

<button onclick="document.getElementById('demo').style.fontSize='35px'">Click Me!</button>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Can Change Image",
        
        
        

        
        
        "bootstrap_title_desc": `<html>
<body>
<button onclick="document.getElementById('myImage').src='pic_bulbon.gif'">Turn on the light</button>

<img id="myImage" src="pic_bulboff.gif" style="width:100px">

<button onclick="document.getElementById('myImage').src='pic_bulboff.gif'">Turn off the light</button>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "practice-task",
        

        "bootstrap_title_desc": `Change Your Biodata Details with The Help Of Javascript and change
Your Image With The Button Click. Means You have 

-- name
-- adrress
-- pincode
-- state
-- city
-- age
-- dob etc, now fill this details empty by default and add name by
using javascript and change your previous pic into new pic by using
button click function.`,
copy:true

      },


    
    ],


    "coursename": " Js Basics",


  },



  {
    "id": 3,


    "html_title": "output",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "External Javascript",
        
         
         

        
        
        "bootstrap_title_desc": `<!--External file: myScript.js
document.getElementById("demo").innerHTML = "Paragraph changed." -->


<!DOCTYPE html>
<html>
<body>

<h2>Demo External JavaScript</h2>

<p id="demo">A Paragraph.</p>

<p>This example links to "myScript.js".</p>
<p>(myFunction is stored in "myScript.js")</p>

<script src="myScript.js"></script>

</body>
</html>`,
copy:true

      },

      {
        "bootstrap_title_heading": "Using document.write()",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>My First Web Page</h1>
<p>My first paragraph.</p>

<script>
document.write(5 + 6);
</script>

</body>
</html>


Using document.write() after an HTML document is loaded, will delete all existing HTML:`,

      },

      {
        "bootstrap_title_heading": "Using alert()",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h2>My First Web Page</h2>
<p>My first paragraph.</p>

<script>
alert(5 + 6);
</script>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Using console.log()",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<script>
console.log(5 + 6);
</script>

</body>
</html>`,

      },

    

    
    ],


    "coursename": "Js Output (day-2)",


  },



  {
    "id": 4,


    "html_title": "variables",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Automatically",
        
         
         

        
        
        "bootstrap_title_desc": `<!--Variables are Containers for Storing Data
JavaScript Variables can be declared in 4 ways:

1) Automatically
2) Using var
3) Using let
4) Using const-->


<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
x = 5;
y = 6;
z = x + y;
document.getElementById("demo").innerHTML = z;
</script>

</body>
</html>`,



      },

      {
        "bootstrap_title_heading": "Using var",
        
        
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
<h1>JavaScript Variables</h1>

<p>In this example, x, y, and z are variables.</p>

<p id="demo"></p>

<script>
var x = 5;
var y = 6;
var z = x + y;
document.getElementById("demo").innerHTML = z;
</script>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Using let",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
<h1>JavaScript Variables</h1>

<p>In this example, x, y, and z are variables.</p>

<p id="demo"></p>

<script>
let x = 5;
let y = 6;
let z = x + y;
document.getElementById("demo").innerHTML = z;
</script>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Using Const",
        
        
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
<h1>JavaScript Variables</h1>

<p>In this example, x, y, and z are variables.</p>

<p id="demo"></p>

<script>
const x = 5;
const y = 6;
const z = x + y;
document.getElementById("demo").innerHTML = z;
</script>

</body>
</html>
        
        
        `,

      },

    

    
    ],



    "coursename": "Js Variables",


  },




  {
    "id": 5,


    "html_title": "javaScript-operators",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Operator Types",
        
        

        
        
        "bootstrap_title_desc": `
-- Types of JavaScript Operators
-- There are different types of JavaScript operators:

1)Arithmetic Operators
2)Assignment Operators
3)Comparison Operators
4)String Operators
5)Logical Operators
6)Ternary Operators
7)Type Operators`,
copy:true

      },

      {
        "bootstrap_title_heading": "Arithmetic Operator",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
let a = 3;
let x = (100 + 50) * a;
document.getElementById("demo").innerHTML = x;
</script>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Assignment Operators",
        
      
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Arithmetic</h1>
<h2>The += Operator</h2>

<p id="demo"></p>

<script>
var x = 50;
x += 5; //x = x + 5
document.getElementById("demo").innerHTML = x;
</script>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Comparison Operators",
        
         
         

        
        
        "bootstrap_title_desc": `<!-- ==	equal to
===	equal value and equal type
!=	not equal
!==	not equal value or not equal type
>	greater than
<	less than
>=	greater than or equal to
<=	less than or equal to -->


<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>

document.getElementById("demo").innerHTML =5 > 6

</script>

</body>
</html>
`,


      },


      {
        "bootstrap_title_heading": "Logical Operators",
        
          
          

        
        
        "bootstrap_title_desc": `<!-- &&	logical and
||	logical or
!	logical not -->

<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>

document.getElementById("demo").innerHTML =(5 > 6) || (5 > 6)

</script>

</body>
</html>
`,

      },

      {
        "bootstrap_title_heading": "Type Operators",
        
         
         
        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>

x=5
document.getElementById("demo").innerHTML =typeof(x)

</script>

</body>
</html>`,

      },

    

    
    ],


    "coursename": "Js Operators (day-3)",


  },





  {
    "id": 6,


    "html_title": "functions",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Simple Functions",
        
 
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
function myFunction(p1, p2) {
  return p1 * p2;
}

let result = myFunction(4, 3);
document.getElementById("demo").innerHTML = result;
</script>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Arrow Function",
        
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const myFunction = (p1, p2) => p1 * p2;

let result = myFunction(4, 3);
document.getElementById("demo").innerHTML = result;
</script>

</body>
</html>
`,
      },

    ],


    "coursename": "Js Function",


  },






  {
    "id": 7,


    "html_title": "objects",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "without Objects",
   
        "bootstrap_title_desc": `


<!DOCTYPE html>
<html>
<body>

<h2>JavaScript Variables</h2>

<p id="demo"></p>

<script>
let car = "Fiat";
let carmodal = "Ab";
document.getElementById("demo").innerHTML = car+carmodal;
</script>

</body>
</html>
`,


      },

      {
        "bootstrap_title_heading": "With Object",
        
         
         

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h2>JavaScript Objects</h2>

<p id="demo"></p>

<script>

const car = {type:"Fiat", model:"500", color:"white"};


document.getElementById("demo").innerHTML = "The car type is " + car.type;
//person["firstName"]
</script>

</body>
</html>
        `,


      },

    

    ],



    "coursename": "Js Objects (day-4)",


  },

  {
    "id": 8,


    "html_title": "js-events",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Click Event",
 
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<button onclick="document.getElementById('demo').innerHTML=Date()">The time is?</button>

<p id="demo"></p>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "onmouse event",
        
        
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<style>
        h1 {
            transition: font-size 0.3s ease;
        }
</style>
<body>


<h1 onmouseover="big()" id="demo">onmouse over</h1>

<h1 onmouseout="small()" id="demi">onmouse out</h1>



<script>
function big() {
  document.getElementById("demo").innerHTML="changed"
}

function small() {
 document.getElementById("demi").innerHTML="now"
}
</script>

</body>
</html>
        `,


      },

      {
        "bootstrap_title_heading": "db click",
 
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p ondblclick="myFunction()">Double-click this paragraph to trigger a function.</p>

<p id="demo"></p>

<script>
function myFunction() {
  document.getElementById("demo").innerHTML = "Hello World ";
}
</script>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Scroll Event",
        
        
        
    
        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Scroll Down Header Color Change</title>
<style>
  body {
margin: 0;
font-family: Arial, sans-serif;
  }
 h1 {
position: fixed;
top: 0;
left: 0;
width: 100%;
padding: 10px 0;
background-color: transparent;
transition: background-color 0.3s ease;
z-index: 1000;
  }
  h2 {
height: 4000px; 

  }
</style>
</head>
<body>

  <h1 id="demo">Scroll Down to Change Header Color</h1>

<h2></h2>
<script>
  window.addEventListener("scroll", show)


  function show() {
const header = document.getElementById('demo');
if (window.scrollY > 200) {
  header.style.backgroundColor = "black"; 
} 
else{
  header.style.backgroundColor = "pink"; 
}
  };
</script>
</body>
</html>
`,
   
    },

    ],



 "coursename": "Js Events",


  },

  {
 "id": 9,


 "html_title": "js-strings",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "String Method",
  
 
  "bootstrap_title_desc": `String length
String slice()
String substring()
String substr()
String replace()
String replaceAll()
String toUpperCase()
String toLowerCase()
String concat()
String trim()
String trimStart()
String trimEnd()
String padStart()
String padEnd()
String charAt()
String charCodeAt()
String split()`,
copy:true


},
 
{
  "bootstrap_title_heading": "length",
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The length Property</h2>

<p>The length of the string is:</p>
<p id="demo"></p>

<script>
let text = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
document.getElementById("demo").innerHTML = text.length;
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "slice()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The slice() Method</h2>

<p>The extracted part of the string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.slice(7, 12);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "substring()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The substring() Method</h2>

<p>The extracted part of the string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.substring(7, 12);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "substr()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The substr() Method</h2>

<p>The extracted part of the string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.substr(7, 5);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "replace()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The replace() Method</h2>

<p>The new string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.replace("World", "JavaScript");
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "replaceAll()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The replaceAll() Method</h2>

<p>The new string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World! World is great!";
document.getElementById("demo").innerHTML = text.replaceAll("World", "JavaScript");
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "toUpperCase()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The toUpperCase() Method</h2>

<p>The string in uppercase is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.toUpperCase();
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "toLowerCase()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The toLowerCase() Method</h2>

<p>The string in lowercase is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.toLowerCase();
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "concat()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The concat() Method</h2>

<p>The concatenated string is:</p>
<p id="demo"></p>

<script>
let text1 = "Hello, ";
let text2 = "World!";
document.getElementById("demo").innerHTML = text1.concat(text2);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "trim()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The trim() Method</h2>

<p>The trimmed string is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.trim();
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "trimStart()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The trimStart() Method</h2>

<p>The string after trimStart() is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.trimStart();
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "trimEnd()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The trimEnd() Method</h2>

<p>The string after trimEnd() is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.trimEnd();
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "padStart()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The padStart() Method</h2>

<p>The padded string is:</p>
<p id="demo"></p>

<script>
let text = "5";
document.getElementById("demo").innerHTML = text.padStart(4, "0");
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "padEnd()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The padEnd() Method</h2>

<p>The padded string is:</p>
<p id="demo"></p>

<script>
let text = "5";
document.getElementById("demo").innerHTML = text.padEnd(4, "0");
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "charAt()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The charAt() Method</h2>

<p>The character at index 7 is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.charAt(7);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "charCodeAt()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The charCodeAt() Method</h2>

<p>The Unicode of the character at index 7 is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.charCodeAt(7);
</script>

</body>
</html>`,

  },
  {
"bootstrap_title_heading": "split()",
"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>JavaScript Strings</h1>
<h2>The split() Method</h2>

<p>The string split into an array is:</p>
<p id="demo"></p>

<script>
let text = "Hello, World!";
document.getElementById("demo").innerHTML = text.split(", ");
</script>

</body>
</html>`,

  },

  {
 "bootstrap_title_heading": "match",
 
 
 
 
 
 
 "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
let text = "The rain in SPAIN stays mainly in the plain";
const myArr = text.match("ain");
document.getElementById("demo").innerHTML = myArr.length + " " + myArr;
</script>

</body>
</html>`,


  },

  {
"bootstrap_title_heading": "Space match",
 

"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "The rain in SPAIN stays mainly in the plain"; 
const myArr = text.match(/ain/g);
document.getElementById("demo").innerHTML = myArr.length + " " + myArr;
</script>

</body>
</html>`,


  },

],



"coursename": "Js Strings (day-5)",


  },


  {
"id": 10,


"html_title": "BackTics",


"bootstrap_head_desc": [



  {
"bootstrap_title_heading": "Back-Tics",

  

 "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<script>
let text =

'The quick
brown "fox"
jumps over
the lazy dog';

document.getElementById("demo").innerHTML = text;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Expression",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>


<script>
let header = "Templates Literals";


let html = '<h2>$ {veer}</h2>';


document.getElementById("demo").innerHTML = html;
</script>

</body>
</html>`,


},

 ],



 "coursename": "Js Backtick (day-6)",


  },





 



  {
 "id": 11,


 "html_title": "array-method",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "simplay array",

  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const cars = ["Saab", "Volvo", "BMW"];
document.getElementById("demo").innerHTML = cars;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Array Length",

  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const fruits = ["Banana", "Orange", "Apple", "Mango"];
let size = fruits.length;
document.getElementById("demo").innerHTML = size;
</script>

</body>
</html>`,


},
{
  "bootstrap_title_heading": "Array join",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const fruits = ["Banana", "Orange", "Apple", "Mango"];
document.getElementById("demo").innerHTML = fruits.join(" * ");
</script>

</body>
</html>`,


},
{
  "bootstrap_title_heading": "pop & push",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
<p id="demo1"></p>
<p id="demo2"></p>

<script>
const fruits = ["Banana", "Orange", "Apple", "Mango"];
document.getElementById("demo1").innerHTML = fruits;
fruits.pop();
fruits.push("Kiwi");
document.getElementById("demo2").innerHTML = fruits;
</script>

</body>
</html>`,


},
{
  "bootstrap_title_heading": "sort array",
 
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo1"></p>
<p id="demo2"></p>

<script>

const fruits = ["Banana", "Orange", "Apple", "Mango"];
document.getElementById("demo1").innerHTML = fruits;

// First sort the array
fruits.sort();

// Then reverse it:
fruits.reverse();

document.getElementById("demo2").innerHTML = fruits;
</script>

</body>
</html>`,


}



 ],



 "coursename": "Js Array",


  },


{
 "id": 12,


 "html_title": "date-function",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "Get Method",

  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const d = new Date("2021-03-25")
document.getElementById("demo").innerHTML = d.getFullYear();
</script>

</body>
</html>`,


},


{
  "bootstrap_title_heading": "All Get Method",
  
  

  
  
  "bootstrap_title_desc": `getFullYear()
getMonth()
getDate()
getDay()
getHours()
getMinutes()
getSeconds()
getMilliseconds()
getTime()`,

// 
},

{
  "bootstrap_title_heading": "Math",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>
<p id="demo1"></p>

<script>
document.getElementById("demo").innerHTML = Math.ceil(4.4);
document.getElementById("demo1").innerHTML = Math.pow(8, 2);
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Random",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
document.getElementById("demo").innerHTML =
Math.floor(Math.random() * 100);
</script>

</body>
</html>`,


},


  

 ],


 "coursename": "Js Date (day-7)",


  },



 

  {
 "id": 12,


 "html_title": "if-else",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "If Condition",

  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
if (new Date().getHours() < 18) {
  document.getElementById("demo").innerHTML = "Good day!";
}
</script>

</body>
</html>`,


},


{
  "bootstrap_title_heading": "Else Condition",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>

var hour = 19
if (hour < 18) {
  document.getElementById("demo").innerHTML = "good day"
} else {
  document.getElementById("demo").innerHTML = "bed day"
}


</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Else If",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const time = 16

if (time < 10) {
  document.getElementById("demo").innerHTML ="good day";
} else if (time < 20) {
  document.getElementById("demo").innerHTML = "good evening";
} else {
  document.getElementById("demo").innerHTML = "good night";
}

</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Conditional (Ternary) Operator",
  
  
  

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<p id="demo"></p>

<script>
var age=19
let voteable = (age < 18) ? "Too young":"Old enough";
document.getElementById("demo").innerHTML = voteable;
</script>

</body>
</html>`,


},


  

 ],



 "coursename": "Js If Else",


  },




  {
 "id": 14,


 "html_title": "for-loop",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "For Loop",
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
const cars = ["BMW", "Volvo", "Saab", "Ford", "Fiat", "Audi"];

let text = "";
for (let i = 0; i < cars.length; i++) {
text += cars[i] + "<br>";
}

document.getElementById("demo").innerHTML = text;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Break In Loop",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "";
for (let i = 0; i < 10; i++) {
if (i === 3) { break; }
text += "The number is " + i + "<br>";
}

document.getElementById("demo").innerHTML = text;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Conitinue In Loop",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "";
for (let i = 0; i < 10; i++) {
if (i === 3) { continue; }
text += "The number is " + i + "<br>";
}
document.getElementById("demo").innerHTML = text;
</script>

</body>
</html>`,


},

  


  

 ],


 "coursename": "Js Loop (day-8)",


  },

 

  {
 "id": 16,


 "html_title": "regular-rxpressions",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "With a String",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "Visit W3Schools!"; 
let n = text.search("W3Schools");
document.getElementById("demo").innerHTML = n;
</script>

</body>
</html>`,


},


{
  "bootstrap_title_heading": "Find any characters",
  
 
 
  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "Is this all there is?";
let result = text.match(/[h-s]/g);
document.getElementById("demo").innerHTML = result;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "Find any digits",
  
  
  

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "123456789";
let result = text.match(/[1-4]/g);
document.getElementById("demo").innerHTML = result;
</script>

</body>
</html>`,


},

{
  "bootstrap_title_heading": "White Space Find",
  



  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let text = "Is this all there is?";
let result = text.match('/\s/g');
document.getElementById("demo").innerHTML = result.length;
</script>

</body>
</html>`,


},



 ],

 "coursename": "Js RegX",


  },

  {
 "id": 17,


 "html_title": "slider",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "Slider With Javascript",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<style>
 
*,
*:before,
*:after{
padding: 0;
margin: 0;
box-sizing: border-box;
}

body{
height: 100vh;
background: #0690e6;
}

.container{
background-color: #ffffff;
width: 500px;
height: 350px;
position: absolute;
transform: translate(-50%,-50%);
left: 50%;
top: 50%;
border-radius: 5px;
padding: 20px;
box-shadow: 0 15px 30px rgba(0,0,0,0.3);
}

.image-container{
position: relative;
}

img{
position: relative;
width: 460px;
height: 280px;
display: none;
}

.active{
display: block;
}

#prev,#next{
height: 40px;
width: 40px;
position: absolute;
background-color: #076bb8;
color: #ffffff;
margin: auto;
top: 0;
bottom: 0;
border: none;
border-radius: 3px;
font-size: 18px;
font-weight: bolder;
  
}

#prev{
left: 5px;
}

#next{
right: 5px;
}
</style>
<body>
<div class="container">
<div class="image-container">
<img src="https://m.media-amazon.com/images/I/71V4RsIUADL._UY879_.jpg" id="content1" class="active">
<img src="https://m.media-amazon.com/images/I/811WFiZ+QQL._SY879._SX._UX._SY._UY_.jpg" id="content2">
<img src="https://m.media-amazon.com/images/I/61p8NlcDdwS._SY879._SX._UX._SY._UY_.jpg" id="content3">
<img src="https://m.media-amazon.com/images/I/8146wsoMEYL._SY879._SX._UX._SY._UY_.jpg" id="content4">


 
</div>
<button id="prev" onclick="prev()"> &lt; </button>
<button id="next" onclick="next()"> &gt; </button>
</div>
</body>
<script>
const images = document.querySelectorAll(".image-container img");
let i = 0; 
let j = images.length; 

function next(){
document.getElementById("content" + (i+1)).style.display="none"

i = ( j + i + 1) % j;

  

  
document.getElementById("content" + (i+1)).style.display="block"

 
 

} 

function prev(){
document.getElementById("content" + (i+1)).style.display="none"
i = (j + i - 1) % j;
 
document.getElementById("content" + (i+1)).style.display="block"

}

</script>

 </html>  


`,


},


  


 

  

  


  

 ],


 "coursename": "Js Slider (day-9)",


  },

  {
 "id": 18,


 "html_title": "try-catch",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "Error",
  
 
 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
try {
adddlert("Welcome guest!");
}
catch(err) {
document.getElementById("demo").innerHTML = err.message;
}
</script>

</body>
</html>`,


},


  


 

  

  


  

 ],


 "coursename": "Js Try-Catch",


  },



  {
 "id": 19,


 "html_title": "modules",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "Import And Export",

  "bootstrap_title_desc": `<!--person.js

const name = "Jesse";
const age = 40;

export {name, age}; -->



<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script type="module">
import { name, age } from "./person.js";

let text = "My name is " + name + ", I am " + age + ".";

document.getElementById("demo").innerHTML = text;
</script>

</body>
</html>`,
copy:true


},

 


  


 

  

  


  

 ],


 "coursename": "Js Modules (day-10)",


  },

  {
 "id": 20,


 "html_title": "form-validation",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "Create Login Form Validation",
  
  
  

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">

<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Login</title>
</head>
<style>
  
  body{
  margin:0;
  height:100vh;
  }
  
.main {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
 
  
}
  
form {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
  
p {
color: red;
}
  
</style>

<body>
<div class="main">

<h1>Login Form</h1>
<form  onsubmit="return validateForm()" >


<input type="text" name="email" id="emailp" placeholder="email">



<p id="emaili"></p>

<input type="password" name="password" id="passwordm" placeholder="password">

<p id="passwordi"></p>

Show Password<input type="checkbox" onclick="myFunction()">


<input type="submit" value="submit">
</form>
</div>
</body>

<script>
function validateForm() {
var user = document.getElementById('emailp').value;
var pass = document.getElementById('passwordm').value;


if(user == ""){
document.getElementById("emaili").innerHTML="** Please Fill The Username";
return false;
}

if(user.replace(/\s/g,"").length<=0){
document.getElementById("emaili").innerHTML="** White Space Are Not Allowed";
return false;
}

if(user.indexOf('@') <=0){
document.getElementById("emaili").innerHTML="** emailid is not Ok";
return false;
}

if((user.charAt(user.length-4)!='.') && (user.charAt(user.length-3)!='.')){
document.getElementById("emaili").innerHTML="** emailid is not Ok";
return false;
}

if((user.length <=2) || (user.length >25)){
document.getElementById("emaili").innerHTML="** Length Shoud be beetween 3 and 25";
return false;
}

if(!isNaN(user)) {
document.getElementById("emaili").innerHTML="** numbers are not allowd";
return false;
}

if(pass == ""){
document.getElementById("passwordi").innerHTML="** Please Fill The Password";
return false;
}

if((pass.length <8) || (user.pass >15)){
document.getElementById("passwordi").innerHTML="** password should have Atleast 8 Character";
return false;
}

if(pass.replace(/\s/g,"").length<=0){
document.getElementById("passwordi").innerHTML="** White Space Are Not Allowed";
return false;
}




}


function myFunction() {
  var x = document.getElementById("passwordm");
  if (x.type === "password") {
x.type = "text";
  } else {
x.type = "password";
  }
}
</script>

</html>`,


  },




  


 

  

  


  

 ],




 "coursename": "Js Validation",


  },

  {
 "id": 21,


 "html_title": "form-onkeyup",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "From Validation using OnKeyUp",
  
  
  

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">

<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
body {
background-color: aqua;
display: flex;
justify-content: center;
align-items: center;
}

.main {
height: 400px;
width: 600px;
box-shadow: 1px 2px 1px 1px;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}



form {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}

input {
padding: 10px;

}
</style>

<body>

<div class="main">
<h1>
Login
</h1>

<form>

<input type="text" placeholder="email" id="username" onkeyup="white()"><br><br>

<input type="text" placeholder="password" id="password" onkeyup="black()"><br><br>

<error id="alert" style="color: red;"></error><br><br>

<button onclick="return validateForm()">submit</button>


</form>

</div>

</body>

<script>


var patten = /\s/g;
var patten2 = "@";
var alertp = document.getElementById('alert');

function white() {

var user = document.getElementById("username").value



if(user.match(patten)){
alertp.innerHTML="**White Space Are not allowed in E-mail"
return false;
}
if(user.length == 0){
alertp.innerHTML="**please fill E-mail id";
return false;
}
 
if((user.length < 8) || (user.length >25)){
alertp.innerHTML="*length should be between 8 and 25";
return false;
  }

if(user.indexOf('@') == 0){
alertp.innerHTML="** email id is not Ok";
return false;
}
if((user.charAt(user.length-4)!=='.') && (user.charAt(user.length-3)!=='.')){
alertp.innerHTML="*email Id is not valid";
return false;
  }

  if(!user.match(patten2) ){
alertp.innerHTML="*@ is important";
return false;
  }



alertp.innerHTML="";
return true;
}

function black() {

var pass = document.getElementById("password").value



if(pass.match(patten)){
alertp.innerHTML="**White Space Are not allowed in Password"
return false;
}

if(pass==""){
alertp.innerHTML="** Please Fill The Password";
return false;
}

if((pass.length < 8)||(pass.length > 15)){
alertp.innerHTML="*password should have Atleast 8 Character";
return false;
}

alertp.innerHTML="";
return true;
}


function validateForm() {
if (!white() || !black()) {
alertp.innerHTML = "*please fill all details"
return false;
}

}

</script>

</html>`,


  },




  


 

  

  


  

 ],


 "coursename": "Js Onkeyup (day-11)",


  },

  

  {
    "id": 13,
   
   
    "html_title": "Project",
   
   
    "bootstrap_head_desc": [
   
   {
     "bootstrap_title_heading": "Javascript Project",

     "bootstrap_title_desc": `https://testgithubbi.github.io/js-task3/

Open This Link, This is reference Link,Will Get To Know Everything

Question: Write a JavaScript program to declare a variable x and assign it a value of 10. Then, declare another variable y and assign it the value of x + 5. Print both variables to the console.


Question: Write a JavaScript function called calculateSquare that takes one parameter and returns the square of that number. Call this function with the number 4 and print the result.


Question: Create an array called fruits with the following elements: "Apple", "Banana", "Mango". Use the array method to add "Orange" to the end of the array and "Pineapple" to the beginning. Print the updated array.


Question: Write a for loop that prints the numbers from 1 to 10 in the console.


Question: Write a JavaScript program that takes a string, converts it to uppercase, and then prints the length of the string.


Question: Create an object called car with properties: make, model, and year. Assign values to these properties and print the make and model to the console.


Question: Write a JavaScript program that checks whether a given number is even or odd. Print "Even" if the number is even, and "Odd" if the number is odd.


Question: Given an array of numbers [1, 2, 3, 4, 5], use the .map() method to create a new array with each number squared. Print the new array.


Question: Write a JavaScript program that tries to parse a string to a number using parseInt. If the string cannot be converted, throw an error and catch it using try...catch. Print an appropriate error message.


Question: Write a JavaScript function that changes the text content of a paragraph with the id demo to "Hello, World!" when a button is clicked.


Question: Write a JavaScript program that changes the background color of the webpage to blue when the user clicks on a button.


Question: Write a JavaScript program that filters an array of numbers to only include numbers greater than 5. Use the .filter() method and print the result.


`

,


   
copy:true
   },

  
   

  
     
   
   
     
   
    ],

    
   

    "coursename": "Project",
   
   
     },

  {
 "id": 23,


 "html_title": "javascript-interview-question",


 "bootstrap_head_desc": [

{
  "bootstrap_title_heading": "What is JavaScript, and what are its key features?",
  
  

  
  
  "bootstrap_title_desc": `Answer: JavaScript is a high-level, interpreted
programming language with dynamic typing.
Key features include its ability to run in web browsers,
support for event-driven programming, and its prototype-based object-oriented nature.`,
copy:true



  },


  {
 "bootstrap_title_heading": "Explain the difference between let, const, and var.",
 
 

 
 
 "bootstrap_title_desc": `Answer: Answer: let and const were introduced in ES6 and have block scope,
while var has function scope. const is used for constants,
and let is for variables with reassignment.`,
copy:true


},

{
  "bootstrap_title_heading": "What is the difference between == and ===?",
  
  

  
  
  "bootstrap_title_desc": `Answer: == performs type coercion, converting operands to the same type before comparison
. === checks both value and type without coercion.`,
copy:true


},


{
  "bootstrap_title_heading": "What is the difference between == and ===?",
  
  

  
  
  "bootstrap_title_desc": `Answer: == performs type coercion, converting operands to the same type before comparison
. === checks both value and type without coercion.`,
copy:true


},

{
  "bootstrap_title_heading": "What is the difference between localStorage and sessionStorage?",
  
  

  
  
  "bootstrap_title_desc": `Answer: Both are web storage options, but localStorage persists 
even after the browser is closed, while sessionStorage is limited to the session.`,
copy:true


},





  


 

  

  


  

 ],



 "coursename": "Js Short Question",


  },











]
export default Javas