import React from 'react'
import "./Card.scss"
import Product from './Json'

const Card = () => {
  return (
    <div className="services">
    <div className="container">
        <h2 className="title">What Will Get From us</h2>
        
        <div className="cards">

{Product.map((Item)=>{
    return(
        <div className="card" key={Item.id}>
{Item.icon}
<h3>{Item.head}</h3>
<p>{Item.desc} </p>
</div>


    )



})}
           

          

        </div>
    </div>
</div>
  )
}

export default Card