import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import "./CodeEditor.scss"

const CodeEditor = ({ code, onChange }) => {
  const options = {
    selectOnLineNumbers: true,
    roundedSelection: true,
    readOnly: false,
    cursorStyle: 'line',
    automaticLayout: true,
    theme: 'vs-dark',
    minimap: { enabled: false },
    renderLineHighlight: 'none',
    fontSize: 18,
   
    
  };

  const handleEditorChange = (newValue) => {
    onChange(newValue);
  };

  return (

  <div className='monaco-black'>
    <MonacoEditor
     width="100%"
      height="100%"
      language="html"
      theme="vs-dark"
      value={code}
     options={options}
      onChange={handleEditorChange}
      
      
      
    />

    </div>


  );
};

export default CodeEditor;
