import React, { useContext } from 'react'
import "./SqlLoop.scss"
import MyContext from '../../../../Uttis/MyContext';
import { ImArrowRight } from "react-icons/im";



const SqlLoop = ({course,title,data}) => {
  

  const {navigate} = useContext(MyContext)

  const handlestatus = (html) =>{
    navigate(`/learning/${course}/${html}`)
  }

 
  
  return (
    
    
    

    
    <div className='sql-bar'>
     

        {data && data.map((Item)=>{
            return(

              <li style={{backgroundColor:title===Item.html_title?'rgb(12, 162, 243)':null,color:title===Item.html_title?'rgb(255, 255, 255)':null}} onClick={() => handlestatus(Item.html_title)} >{Item.coursename} 
              <ImArrowRight  style={{opacity:title===Item.html_title?'1':null,transform:title===Item.html_title?'translateX(0)':null}} />
              </li>

            )
        })

        }
            
           


        

    </div>
   
   
   
   
  )
}

export default SqlLoop;