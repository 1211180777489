import { useContext } from "react";
import "./Left.scss";



import 'reactjs-popup/dist/index.css';
import MyContext from "../../Uttis/MyContext";
import PDF from "./Pdf";



const Left = () => {
  const{showLeft,closeLeft}=useContext(MyContext)
  

 


  return (


    <>
    
    {
      showLeft &&
      <div className="model-main">
      <div className="opac-layer" onClick={closeLeft}> </div>
        <div className="model-content">
  
          <div className="model-upper">
  
        <div className="model-heading">Download Pdf</div>
        <div className="model-close" onClick={closeLeft}>&#x2715;</div>
        </div>
  
  
       
  
      <div className="model-pdf">
        {PDF.map((Item)=> 
    <a href={Item.pdf}  className="btn_pdf" download={Item.name}>{Item.name}</a>
      
      
        
        
        )}
     
      </div>
        
  
      </div>
      </div>

    }
    </>
  
   
    )}


  
    
    


export default Left;
