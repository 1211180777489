import chintan from '../../../assets/pdf/chintan.pdf'
import Nirmal from '../../../assets/pdf/nirmal.pdf'
import Sanjay from '../../../assets/pdf/sanjay.pdf'
import swar from '../../../assets/pdf/Swar.pdf'
import Dev from '../../../assets/pdf/dev.pdf'
import Aman from '../../../assets/pdf/aman.pdf'
import Jeel from '../../../assets/pdf/Jeel-report.pdf'
import Het from '../../../assets/pdf/het-chunara.pdf'
import Swapnil from '../../../assets/pdf/swapnil.pdf'
import Nidhi from '../../../assets/pdf/nidhi-parmar.pdf'
const jsonData = [
  { title: 'Web Development',
   
cards:[
  {
    id:1,
    project:"cab booking",
    value:'cab-booking-internship-report',
    link:chintan
  },

  {
    id:2,
    project:"E-Commerce",
    value:'e-commerce-internship-report',
    link:Nirmal
  }
,
  {
    id:3,
    project:"Backend E-Commerce",
    value:'backend-internship-report',
    link:Sanjay
  },

  {
    id:4,
    project:"VHX E-Commerce",
    value:'Fullstack-internship-report',
    link:Aman
  },

  
  {
    id:5,
    project:"Frontend E-Commerce",
    value:'Frontend-internship-report',
    link:Het
  },

  {
    id:5,
    project:"Frontend E-Commerce",
    value:'Frontend-report',
    link:Swapnil
  },
]
  
  
  
  },
  { title: 'Data Analysis', 
  cards:[
    {
      id:4,
      value:"e-comerce-analysis-dashboard-report",
      project:"data analysis",
      link:Dev
    },
  
    {
      id:4,
      value:"Sales-dashboard-analysis-report",
      project:"sales analysis",
      link:swar
    },

    {
      id:4,
      value:"shopping-dashboard-analysis-report",
      project:"E-commerce-analysis",
      link:Jeel
    },
  ,
 
  ]



},

{ title: 'UI/UX', 
cards:[
  {
    id:1,
    value:"ui-ux-for-website",
    project:"Cafe Food order",
    link:Nidhi
  },

,

]



},


];

export default jsonData;