

import React, { useState } from 'react';
import {GiCursedStar} from "react-icons/gi"
import "./Course.scss";
import Pagination from './Pagination/Pagination';





const Course = ({Product}) => {

  const [currentPage,setCurrentPage] = useState(1)
  const [postsPerPage,setPostsPerPage] = useState(8)


  const lastPostIndex = currentPage * postsPerPage  
  const firstPostIndex = lastPostIndex - postsPerPage  

  const currentPosts = Product.slice(firstPostIndex,lastPostIndex)

  



 










  const handleNavigate = (Item) => {




    const dynamicUrl = `/course/${Item.Coursename}`;
    const fullUrl = window.location.origin + dynamicUrl;

    window.open(fullUrl, '_blank');
};



 
  return <div className="shop-by-category">

    <div className='headv'> 
    
      <h3>Programs </h3>
      <GiCursedStar/>
     
    </div>

    <div className="categories">

      {
        currentPosts.map((Item) => {
          return (

              <div className="category" key={Item.id} >
                
              
                <img src={Item.student} alt="" onClick={() => handleNavigate(Item)} />
                

              </div>
   

          )
        }

       
        )
      }





    </div>

    <Pagination totalPosts={Product.length}
    postsPerPage={postsPerPage}
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
    
    />
   
  </div>
 
};

export default Course;
