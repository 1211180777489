
  
  const NextJson = [
  
    {
      "id": 1,
  
  
      "html_title": "intro",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "What is Next.js and Why Use It?",
          "bootstrap_title_desc": `Next.js is a React framework that provides infrastructure and simple development experience for server-side rendering (SSR) and static site generation (SSG).
          
Server-Side Rendering (SSR): Renders pages on the server on each request, which is great for dynamic content and SEO.

Static Site Generation (SSG): Generates HTML at build time, which can be served directly as static files.

API Routes: Allows you to create API endpoints within your Next.js app, no need for a separate backend.

Built-in CSS and Image Optimization: Helps with performance and best practices out of the box.

Zero Config: No configuration needed to get started, but it's still flexible when you need custom setups.`
        },


        {
          "bootstrap_title_heading": " Installing Node.js and npm",
          "bootstrap_title_desc": `Before you can create a Next.js app, you need to have Node.js and npm (Node Package Manager) installed on your system.`
        },
      

        {
          "bootstrap_title_heading": "  Setting Up a New Next.js Project",
          "bootstrap_title_desc": `Open your terminal or command prompt.
Navigate to the directory where you want to create your project.
Run the following command


npx create-next-app@latest my-next-app

Replace my-next-app with your preferred project name.`
        },

        
        {
          "bootstrap_title_heading": " overview of directories",
          "bootstrap_title_desc": `/pages: This is where you create your React components that map directly to routes.
/pages/index.js: The main entry point, corresponding to the root URL /.
/pages/_app.js: Custom App component to override the default App for initialization.
/public: Static files like images, fonts, and other assets go here.
/styles: Contains global styles (globals.css) and module-based styles.
package.json: Contains dependencies and scripts for your project.
next.config.js (optional): Allows you to customize Next.js configurations.`
        },

        {
          "bootstrap_title_heading": "Running Your Next.js App",
          "bootstrap_title_desc": `npm run dev`
        },
      ],
  
  
      "coursename": "Intro (day-1)",
  
  
    },


    {
      "id": 1,
  
  
      "html_title": "pages-and-routing",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Understanding the pages Directory",
          "bootstrap_title_desc": `Each file inside the pages directory automatically becomes a route.
The filename determines the URL path.
Supports nested directories for nested routes.
Special files like _app.js and _document.js are used for customizing the default App and Document structures.`
        },


        {
          "bootstrap_title_heading": "Creating Your First Pages",
          "bootstrap_title_desc": `Home Page (index.js)
The index.js file inside the pages directory corresponds to the root route (/).`
        },

        {
          "bootstrap_title_heading": "index.js",
          "bootstrap_title_desc": `// pages/index.js
import React from 'react';

const Home = () => {
  return (
    <div>
      <h1>Welcome to My Next.js App!</h1>
      <p>This is the home page.</p>
    </div>
  );
};

export default Home;
`
        },


        
        {
          "bootstrap_title_heading": "About.js",
          "bootstrap_title_desc": `// pages/about.js
import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the about page.</p>
    </div>
  );
};

export default About;
`
        },


      
      


        
      ],
  
  
      "coursename": "Pages and Routing",
  
  
    },
  
  
  
   
  ]
  export default NextJson