import React, { useContext } from 'react';
import './HeoSection.scss';
import MyContext from '../../../../../Uttis/MyContext';

const HeroSection = ({ img, title }) => {
  const { handleSendMessage } = useContext(MyContext);

  return (
   
     
        <div className="content-dynamic">
          <div className="text-content">
            <h1 className="title">{title}</h1>
            <p className="subtitle">Learn from scratch / Internship / Project</p>
            <button 
              onClick={() => handleSendMessage(title)} 
              className="enroll-button"
            >
              Enroll Now
            </button>
          </div>
          <div className="image-content">
            <img src={img} alt="Nature" />
          </div>
        </div>
     
   
  );
};

export default HeroSection;
