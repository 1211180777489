
  
  const ReactJson = [
  
    {
      "id": 1,
  
  
      "html_title": "intro",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Prerequisites",
          "bootstrap_title_desc": `Before you begin, make sure you have the following installed on your machine:
          
    - **Node.js and npm**: Node.js is a JavaScript runtime, and npm (Node Package Manager) is a package manager for JavaScript. Download them from [here](https://nodejs.org/en/download).`
        },
        {
          "bootstrap_title_heading": "Setting Up Your React Environment",
          "bootstrap_title_desc": `To start building with React, you first need to set up your environment:
    
    1. **Install Create React App**: Create React App is a convenient tool to set up a new React project without configurations.
       
       Run the following command in your terminal to install it globally:
       
       \`\`\`bash
       npx create-react-app my-react-app
       \`\`\`
    
    2. **Navigate to Your Project**: After installation, move into your new project's directory with:
       
       \`\`\`bash
       cd my-react-app
       \`\`\`
    
    You're now ready to start building your React application!`
        }
      ],
  
  
      "coursename": "Intro (day-1)",
  
  
    },
  
  
  
    {
      "id": 2,
  
  
      "html_title": "component",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Update App.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'

const App = () => {
return (
<div>App</div>
)
}
          
export default App`,
 
        },
  

        {
          "bootstrap_title_heading": "New File Home.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'

const Home = () => {
return (
<div>Home</div>
)
}

export default Home`,
 
        },

        
        {
          "bootstrap_title_heading": "updated App.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import Home from "./Home"
const App = () => {
return (
<div>
<Home/>
</div>
)
}

export default App`,
 
        },
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Component",
  
  
    },
  
  
  
    {
      "id": 3,
  
  
      "html_title": "react-styles",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "inline styling",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const App = () => {
return (
<div>
<h1 style={{color: "red",backgroundColor: "DodgerBlue"}}>Hello Style!</h1>
<p>Add a little style!</p>
</div>
)
}
export default App`,
 
        },

        {
          "bootstrap_title_heading": "internal styling",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const Header = () => {
const myStyle = {
color: "red",
backgroundColor: "DodgerBlue",
};
return (
<>
<h1 style={myStyle}>Hello Style!</h1>
<p>Add a little style!</p>
</>
);
}
export default App`,
 
        },


        {
          "bootstrap_title_heading": "external styling",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import "./App.css"
const Header = () => {

return (
<>
<h1>Hello Style!</h1>
<p>Add a little style!</p>
</>
);
}
export default App`,
 
        },


        {
          "bootstrap_title_heading": "App.css",
         
  
          
          
          "bootstrap_title_desc": `h1{
color: "red",
backgroundColor: "DodgerBlue"
}`,
 
        },

       



        {
          "bootstrap_title_heading": "Sass Styling",
         
  
          
          
          "bootstrap_title_desc": `Open your terminal and run
-- npm i sass

after intalling sass check installed or not in package.json`,
 
        },


        {
          "bootstrap_title_heading": "App.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import './App.scss';
const App = () => {
return (

<div className="main">
<button>welcome</button>
</div>

)
}
export default App`,
 
        },

        
        {
          "bootstrap_title_heading": "Mixin.scss",
         
          
  
          
          
          "bootstrap_title_desc": `@mixin btn($bgcolor,$col: rgb(0, 244, 85)){
background-color: $bgcolor;
color: $col;
        
&:hover{
    background-color: aqua;
}
}`,
 
        },

        {
          "bootstrap_title_heading": "App.scss",
         
          
  
          
          
          "bootstrap_title_desc": `@import "../../../Mixin/Mixin.scss";
.main{
    
button{
    @include btn(orange,green)
}

}`,
 
        }
 
      ],

      "coursename": "React Styles (day-2)",
  
  
    },

    {
      "id": 9,
  
  
      "html_title": "react-responsive-header",
  
  
      "bootstrap_head_desc": [
    
        {
          "bootstrap_title_heading": "Header.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import {FiMenu,FiSearch} from "react-icons/fi"
import "./Header.scss"

const Header = () => {
return (
<div className='main-header'>

<div className='left'>
logo
</div>

<div className='center'>
<li>Service</li>
<li>Contact</li>
<li>About</li>
</div>

<div className='right'>
<FiMenu size={25}/>
<FiSearch size={25}/>
</div>


</div>
)
}
        
export default Header`,

        },

        {
          "bootstrap_title_heading": "Header.scss",
         
  
          
          
          "bootstrap_title_desc": `// Header.scss

          @import "./mixins.scss";
.main-header {
display: flex;
justify-content: space-between;
padding: 0 30px;
background-color: #333;
color: #fff;
align-items: center;
line-height: 70px;
       
            
          
          
            
.left {
font-size: 34px;
font-weight: 600;
animation:logo 2s infinite ease-in
}
            
             
            
.center {
display: none;
gap: 20px;
list-style: none;
          
@include md{
  display: flex;
}
            
               
            
.nav-item {
margin: 0 20px;
cursor: pointer;
transition: color 0.3s ease;
            
&:hover {
color: #ffd700;
}
}
}
              
            
.right {
display: flex;
gap: 20px;
           
                   
}
}
          
@keyframes logo{
0%{transform: translateY(0);}
50%{transform: translateY(5px);}
75%{transform: translateY(-5px);}
100%{transform: translateY(0);}
}`,

        },

        {
          "bootstrap_title_heading": "Mixins.scss",
         
          
  
          
          
          "bootstrap_title_desc": `// Mixins.scss
@mixin sm {
@media only screen and (min-width: 640px) {
@content;
}
}
        
        
@mixin md {
@media only screen and (min-width: 768px) {
@content;
}
}`,

        },




    
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React Header",
  
  
    },
  
  
   
  
    {
      "id": 4,
  
  
      "html_title": "react-props",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "Pass Data (App.js)",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const App = () => {
return (
<div>
<Home color = "red" />
</div>
)
}
export default App`,
 
        },

        {
          "bootstrap_title_heading": "Home.js",
         
          
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const Home = ({color}) => {
return (
<div>
<h1> sky is {color} </h1>
</div>
)
}
export default Home`,
 
        },

    

        


      
  
  
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React Props",
  
  
    },
  
  
  
  
    {
      "id": 5,
  
  
      "html_title": "react-routing",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "React Router",
         
  
          
          
          "bootstrap_title_desc": `To add React Router in your application,
run this in the terminal from the root directory of the application:

--- npm i -D react-router-dom

Within the src folder, we'll create a folder named pages with several files:

src/pages/:


Home.js

Contact.js
NoPage.js`,
 
        },

        {
          "bootstrap_title_heading": "App.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home"
import Contact from "./Contact"
import About from "./About"
const App = () => {
return (
<div>
<BrowserRouter>
<Header/>
<Routes>
<Route path='/' element={<Home/>}/>
<Route path='/about' element={<About/>}/>
<Route path='/contact' element={<Contact/>}/>
</Routes>

</BrowserRouter>

</div>
)
}
export default App`,
 
        },

        {
          "bootstrap_title_heading": "Home.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const Home = () => {
return (
<div> Home </div>
)
}
export default Home`,
 
        },


        {
          "bootstrap_title_heading": "About.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const About = () => {
return (
<div> About </div>
)
}
export default About`,
 
        },


        {
          "bootstrap_title_heading": "Contact.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
const Contact = () => {
return (
<div> Contact </div>
)
}
export default Contact`,
 
        },

        {
          "bootstrap_title_heading": "Header.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import {Link } from "react-router-dom";
const Header = () => {
return (
  <div>
  <ul>
    <li>
      <Link to="/">Home</Link>
    </li>
    <li>
      <Link to="/contact">Blogs</Link>
    </li>
    <li>
      <Link to="/about">Contact</Link>
    </li>
  </ul>
</div>
)
}
export default Header`,
 
        },
     

    

        


      
  
  
      
  
  
      
      ],
  
  
  
      "coursename": "React Routing (day-3)",
  
  
    },
  
    {
      "id": 7,
  
  
      "html_title": "react-icons",
  
  
      "bootstrap_head_desc": [
    
        {
          "bootstrap_title_heading": "Take React icons",
         
  
          
          
          "bootstrap_title_desc": `Install React Icons
          
-- npm i react-icons

and take icon from this link

-- https://react-icons.github.io/react-icons/`,

        },

        {
          "bootstrap_title_heading": "App.js",
         
          
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import {AiFillHome} from "react-icons/ai"

const App = () => {
return (
<div>
          
<AiFillHome size={67}/>
</div>
)
}
          
export default App`,

        },
  
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React icons",
  
  
    },
  
  
  
    {
      "id": 6,
  
  
      "html_title": "react-hooks",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "useState() (App.js)",
         
          
  
          
          
          "bootstrap_title_desc": `import React, { useState } from 'react'
const App = () => {
const [color, setColor] = useState("red");

return (
<>
<h1>My favorite color is {color}!</h1>
<button
type="button"
onClick={() => setColor("blue")}
>Blue</button>
<button
type="button"
onClick={() => setColor("red")}
>Red</button>
<button
type="button"
onClick={() => setColor("pink")}
>Pink</button>
<button
type="button"
onClick={() => setColor("green")}
>Green</button>
</>
);
}
export default App`,
 
        },

        {
          "bootstrap_title_heading": "useState() example-2",
         
  
          
          
          "bootstrap_title_desc": `import React, { useState } from 'react'

const Home = () => {

  const [ab,setAb] = useState(0)

  const plus = () => {ab<10 ? setAb(ab+1) : setAb(10)}
  const minus = () =>{ab>0 ? setAb(ab-1) : setAb(0)} 
  return (
    <div>

      <button onClick={plus}>plus</button>
   <p>{ab}</p>
   <button onClick={minus}>minus</button>
    </div>
  )
}

export default Home`,
 
        },

        {
          "bootstrap_title_heading": "useState() example-3",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { useState } from 'react'

const Home = () => {

  const [change,setChange] = useState('off')

  const handle = () => {change=='off' ? setChange('on'):setChange('off')}
  return (
    <div>
      <p>{change}</p>
      <button onClick={handle}>click</button>
    </div>
  )
}

export default Home`,
 
        },

        {
          "bootstrap_title_heading": "useState() example-4",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { useState } from 'react'

const Home = () => {

  const [change,setChange] = useState(false)


  return (
    <div>
      <p> {change ? 'on' :'off'}</p>
      <button onClick={() =>setChange(!change)}>click</button>
    </div>
  )
}

export default Home`,
 
        },

        {
          "bootstrap_title_heading": "useState() example-5",
         
  
          
          
          "bootstrap_title_desc": `import React, { useState } from 'react'

const Home = () => {
  const [cd,setCd] =useState('')
  return (
    <div>
      <p>{cd}</p>

      <input type="text" value={cd} onChange={(e) =>setCd(e.target.value)}/>
    </div>
  )
}

export default Home`,
 
        },

        {
          "bootstrap_title_heading": "useState() example-6",
         
  
          
          
          "bootstrap_title_desc": `import React, { useState } from 'react'
import "./Home.css"

const Home = () => {
  const [open,setOpen] = useState(false)

  const handle = () => setOpen(!open)
  return (
    <div className='modal'>
      <button onClick={handle}>modal</button>

{ open &&
      <div className='modal-main'>

        modal content
      </div>

}
    </div>
  )
}

export default Home`,
 
        },

        
        {
          "bootstrap_title_heading": "Home.css example-6",
         
  
          
          
          "bootstrap_title_desc": `.modal{
   

.modal-main{
    background-color: rgb(250, 250, 250);
    height: 300px;
    width: 400px;
    box-shadow: 0 0 5px rgb(253, 6, 6) ;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: modal 1s;
}
        }
        
        @keyframes modal{
0%{transform: scale(0)}
100%{transform: scale(1) translate(-50%,-50%);}
        }`,
 
        },

        {
          "bootstrap_title_heading": "local & session storage",
         
  
          
          
          "bootstrap_title_desc": `localStorage and sessionStorage
 are two web storage options available in web
browsers that allow you to store data locally
on a user's device

Data stored in localStorage persists even after the browser is closed and reopened.

const [username, setUsername] = useState(() => {
  // Attempt to retrieve username from localStorage
  const storedUsername = localStorage.getItem('username');
  // Return the stored username, or a default value if it doesn't exist
  return storedUsername || '';
});

useEffect(() => {
  localStorage.setItem('username', username);
}, [username]);


Data stored in sessionStorage is only
available for the duration of the page
session. Once the user closes the tab
or window, the data is cleared.

same example as above`,
 
        },

        {
          "bootstrap_title_heading": "useEffect() (App.js)",
         
          
  
          
          
          "bootstrap_title_desc": `import { useState, useEffect } from "react";
const App = () => {
const [count, setCount] = useState(0);
useEffect(() => {
setTimeout(() => {
setCount((count) => count + 1);
}, 1000);
},[]);
return <h1>I have rendered {count} times!</h1>;
}
export default App`,
 
        },

        {
          "bootstrap_title_heading": "useContext() (MyContextProvider.js)",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import MyContext from './MyContext'
import Data from "../Json/Json"

const MyContextProvider = ({children}) => {
const HeadingText = "Popular"
const HeadingText1 = "Related Products"


return (
<MyContext.Provider value={{HeadingText,HeadingText1}} >
{children}
</MyContext.Provider>
)
}

export default MyContextProvider`,
 
        },


        
        {
          "bootstrap_title_heading": "MyContext.js",
         
  
          
          
          "bootstrap_title_desc": `import  { createContext } from 'react'

const MyContext = createContext();

export default MyContext`,
 
        },


           
        {
          "bootstrap_title_heading": "App.js",
         
  
          
          
          "bootstrap_title_desc": `import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";        
import MyContextProvider from "./MyContextProvider";
      
function App() {
  
return (
<>
         
<BrowserRouter>
  
<MyContextProvider>
   
<Routes>
<Route path="/" element={<Home/>} />
 
</Routes>
 
</MyContextProvider>
</BrowserRouter>
          
</>
)
}
          
export default App;`,
 
        },


        {
          "bootstrap_title_heading": "Home.js",
         
  
          
          
          "bootstrap_title_desc": `import Category from "./Category";
              
const Home = () => {
          
             
return <div>
         
<Category  />

</div>;
};
          
export default Home;`,

        },

        {
          "bootstrap_title_heading": "Category.js",
         
          
  
          
          
          "bootstrap_title_desc": `import { useContext } from "react";
import MyContext from "../../../Uttis/MyContext";


const Category = () => {

   
const {HeadingText,HeadingText1} = useContext(MyContext);
             
              
return (

<div>

<h1>{HeadingText}</h1>

<h2>{HeadingText1}</h2>

</div>

)
              
};

export default Category;`,

        },
        

       
     

    

        


      
  
  
      
  
  
      
      ],
  
 
      "coursename": "React Hooks (day-4)",
  
  
    },
  
  
  
  
    {
      "id":20                                      ,
  
  
      "html_title": "react-drawer",
  
  
      "bootstrap_head_desc": [

        {
          "bootstrap_title_heading": "npm install",
         
  
          
          
          "bootstrap_title_desc": `install npm
-- npm i react-modern-drawer`,

        },

        {
          "bootstrap_title_heading": "Header.jsx",
         
  
          
          
          "bootstrap_title_desc": `import { Button } from '@mui/material';
import React, { useState } from 'react'
import Drawers from './Drawers';


const Header = () => {

  const[modal,setModal] =useState(false)

  const HnadleModal = () =>{
    setModal(!modal)
  }

  return (
    <>
    <div className='header-main'>
    
  

   

<Button onClick={HnadleModal}>open</Button>
    
    
    </div>

    {modal &&
    <Drawers setModal={HnadleModal} modal={modal}/>
    }


    </>
  )
}

export default Header`,

        },

        {
          "bootstrap_title_heading": "Drawers.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import "./Drawers.scss"
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

const Drawers = ({setModal,modal}) => {
  return (
 
<div className='modal'>
    <Drawer
    size={300}
    
    open={modal}
    onClose={setModal}
    direction='right'
    duration={3000}
    className='bla bla bla'
    overlayOpacity={0.5}
    overlayColor="red"
    lockBackgroundScroll={true}
>
    <div>Hello World</div>
</Drawer>
</div>
   
  )
}

export default Drawers`,

        },

        {
          "bootstrap_title_heading": "Drawers.scss",
         
  
          
          
          "bootstrap_title_desc": `.modal{
.EZDrawer .EZDrawer__container {
position: fixed;
visibility: hidden;
background: rgb(141, 14, 14);
// transition: width ease-in-out 2s;
animation: modal 0.5s;
box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1); }
            
}

@keyframes modal{
0%{width: 0;}
100%{
    width: 300px;
}
}`,

        },


  


       


        
      

        
      

     


      







  


        



       

     




    
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React Drawer",
  
  
    },

    {
      "id":21                                     ,
  
  
      "html_title": "react-modal",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "npm install",
         
  
          
          
          "bootstrap_title_desc": `First, install React Modal:
npm i react-responsive-modal`,

        },

        
        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const CustomModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
    <button onClick={onOpenModal}>Open modal</button>
    <Modal open={isOpen} onClose={closeModal} center>
      <h2>Simple centered modal</h2>
    </Modal>
  </div>
  );
};

export default CustomModal;
`,

        },

      ],

      "coursename": "React Modal",
  
  
    },
  
 
  
  
    {
      "id": 8,
  
  
      "html_title": "react-loader",
  
  
      "bootstrap_head_desc": [
    
        {
          "bootstrap_title_heading": "React Loader",
         
  
          
          
          "bootstrap_title_desc": `Install React Loader
          
-- npm i react-loader-spinner

take loader from below link

-- https://mhnpd.github.io/react-loader-spinner/docs/category/components/`,

        },


        {
          "bootstrap_title_heading": "Loader.js",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import './Loader.scss'
const Loader = () => {
return (
<div className="loading">
<PuffLoader
color={"blue"}
loading={"true"}
          
// size={20}
aria-label="Loading Spinner"
data-testid="loader"
/>
</div>
)
}
export default Loader; `,

        },

        {
          "bootstrap_title_heading": "Loader.scss",
         
  
          
          
          "bootstrap_title_desc": `.loading{

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%);
// width: 100px;
// height: 100px;
           
}`,

        },


        {
          "bootstrap_title_heading": "App.js",
         
          
  
          
          
          "bootstrap_title_desc": `import React, { useEffect, useState } from 'react'
         
import Load from './Loader'
const App = () => {
const [isLoading, setIsLoading] = useState(true);
useEffect(() => {
          
// Wait for 3 seconds
setTimeout(() => {
setIsLoading(false);
}, 3000);
}, []);
          
return (
isLoading ?
<Load/>:
          
          
<div>
          
home page
</div>
          
)
}
export default App`,

        },

    
      ],
  
  
  
      "coursename": "React Loading (day-5)",
  
  
    },
  
  
    {
      "id":14,
  
  
      "html_title": "react-auto-increament",
  
  
      "bootstrap_head_desc": [
    
      

        {
          "bootstrap_title_heading": "how to use",
         
  
          
          
          "bootstrap_title_desc": `'react-countup' npm install
-- npm i react-countup`,

        },


        {
          "bootstrap_title_heading": "App.js",
         
  
          
          
          "bootstrap_title_desc": ` //App.jsx
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import './App.scss'
import { AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { FaUserGraduate, FaHandshake} from "react-icons/fa";
import { MdPersonPinCircle } from 'react-icons/md'
import { blue } from '@mui/material/colors';

const App = () => {
          
              
return (
<div className='increment'>
  
<div className='main-in'>
<FaUserGraduate size={45}  className='icon'/>
<div className='counting'>
<CountUp
start={0}
end={600}
duration={2.75}
separator=" "
decimals={0}
decimal=","
suffix='+'

/>
<h2>Student Internship </h2>
</div>
</div>

<div className='main-in'>
<MdPersonPinCircle size={45} className='icon'/>
<div className='counting'>
<CountUp
start={0}
end={300}
duration={2.75}
separator=" "
decimals={0}
decimal=","
suffix='+'

/>
<h2>Placement Given</h2>
</div>
</div>

<div className='main-in'>
<FaHandshake size={45}style={{color: blue}} className='icon'/>
<div className='counting'>
<CountUp
start={0}
end={100}
suffix='+'
duration={2.75}
separator=" "
decimals={0}
decimal=","

/>
<h2>Companies Tieup</h2>
</div>
</div>

<div className='main-in'>
<AiOutlineFundProjectionScreen size={45} className='icon'/>
<div className='counting' style={{borderRight: "none"}}>
<CountUp
start={0}
end={60}
duration={2.75}
separator=" "
decimals={0}
decimal=","
suffix='+'

/>

<h2>projects Completed</h2>
</div>
</div>

</div>
)
}

export default App`,

        },

        {
          "bootstrap_title_heading": "App.scss",
         
          
  
          
          
          "bootstrap_title_desc": `// App.scss
@import "../../../css-config/mixins.scss";
.increment{
padding: 40px 20px;
display: flex;
justify-content: center;
align-items: center;
flex-flow: wrap;
gap: 100px;
max-height: fit-content;
font-size: 24px;
width: 100%;border-bottom: 1px solid rgba(0,0,0,0.5);

// min-width: 970px;
@include ml{
flex-flow: row;

}

.main-in{
display: flex;
gap: 20px;
color: rgb(48, 132, 249);
 


 

h2{
font-size: 17px;
}


.counting{
color: rgb(6, 6, 144);


}
}
}
`,

      },


        



       

     




    
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React Auto Increament",
  
  
    },
  
  
  
    {
      "id": 11,
  
  
      "html_title": "react-api-calling",
  
  
      "bootstrap_head_desc": [
    
        {
          "bootstrap_title_heading": "install axious npm",
         
  
          
          
          "bootstrap_title_desc": `Install This Npm For Axious
          
-- npm i axios

take this API Link:https://jsonplaceholder.typicode.com/posts`

,

        },

        {
          "bootstrap_title_heading": "Card.jsx",
         
          
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

const Card = () => {

const [num,setNum] = useState([])
          
useEffect(()=>{
axios.get('https://jsonplaceholder.typicode.com/posts')
.then((res)=> setNum(res.data)
  
          
)
},[])
return (
<div> 
          
          
{num.map((post)=>{
const{id,title,body} = post; //array descructuring
return(
<div class="card" key={id}>
          

<div class="card-body">
{title}
</div>
          
<div class="card-body">
{body}
</div>
          
</div>
)
})}
          
              
              
</div>
)
}
          
export default Card`,

        },

       

     




    
      ],
  
  
 
      "coursename": "React API (day-6)",
  
  
    },
  
    {
      "id": 12,
  
  
      "html_title": "react-json-map",
  
  
      "bootstrap_head_desc": [
    
      

        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `// App.jsx
import React from 'react'
import Data from './Json'

          
const App = () => {
return (
<div>


{Data.map((item)=>{
return(

<div class="card" key={item.title} >


<div class="card-body">
 {item.title}
</div>
</div>

)
})}


</div>
)
}

export default App`,

        },


        {
          "bootstrap_title_heading": "Json.jsx",
         
          
  
          
          
          "bootstrap_title_desc": `// Json.jsx
const Data = [
{
"userId": 1,
"id": 1,
"title": "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
"body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
},
{
"userId": 1,
"id": 2,
"title": "qui est esse",
"body": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
},
{
"userId": 1,
"id": 3,
"title": "ea molestias quasi exercitationem repellat qui ipsa sit aut",
"body": "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut"
},
{
"userId": 1,
"id": 4,
"title": "eum et est occaecati",
"body": "ullam et saepe reiciendis voluptatem adipisci\nsit amet autem assumenda provident rerum culpa\nquis hic commodi nesciunt rem tenetur doloremque ipsam iure\nquis sunt voluptatem rerum illo velit"
},
{
"userId": 1,
"id": 5,
"title": "nesciunt quas odio",
"body": "repudiandae veniam quaerat sunt sed\nalias aut fugiat sit autem sed est\nvoluptatem omnis possimus esse voluptatibus quis\nest aut tenetur dolor neque"
},
{
"userId": 1,
"id": 6,
"title": "dolorem eum magni eos aperiam quia",
"body": "ut aspernatur corporis harum nihil quis provident sequi\nmollitia nobis aliquid molestiae\nperspiciatis et ea nemo ab reprehenderit accusantium quas\nvoluptate dolores velit et doloremque molestiae"
},
{
"userId": 1,
"id": 7,
"title": "magnam facilis autem",
"body": "dolore placeat quibusdam ea quo vitae\nmagni quis enim qui quis quo nemo aut saepe\nquidem repellat excepturi ut quia\nsunt ut sequi eos ea sed quas"
},
{
"userId": 1,
"id": 8,
"title": "dolorem dolore est ipsam",
"body": "dignissimos aperiam dolorem qui eum\nfacilis quibusdam animi sint suscipit qui sint possimus cum\nquaerat magni maiores excepturi\nipsam ut commodi dolor voluptatum modi aut vitae"
},
{
"userId": 1,
"id": 9,
"title": "nesciunt iure omnis dolorem tempora et accusantium",
"body": "consectetur animi nesciunt iure dolore\nenim quia ad\nveniam autem ut quam aut nobis\net est aut quod aut provident voluptas autem voluptas"
},
{
"userId": 1,
"id": 10,
"title": "optio molestias id quia eum",
"body": "quo et expedita modi cum officia vel magni\ndoloribus qui repudiandae\nvero nisi sit\nquos veniam quod sed accusamus veritatis error"
},

]
        
export default Data;`,

        },

       

     




    
      ],
  
  
      "coursename": "React Map Json",
  
  
    },

    {
      "id":19                                      ,
  
  
      "html_title": "react-nested-json",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import data from './Data

const App = () => {
return (
<div>
  
{
  
data.map((item,id)=>{
return(

<div class="card" key={id} >


<div class="card-body"
 style={{ backgroundColor: "grey" }}>
 {item.id}
</div>
 


{item.student.map((data=>

<div>
<h3>student details</h3>
<div class="card-body"
 style={{backgroundColor:"yellow"}}>
{data.name}
 </div>

 <div class="card-body"
 style={{backgroundColor:"green"}}>
{data.state}
 </div>

<div class="card-body"
style={{backgroundColor:"pink"}}>
{data.city}
</div>
          
</div>
  
  
))}
</div>
  
)
})
}
          
                     
          
</div>
)
}
          
export default App`,

        },

        {
          "bootstrap_title_heading": "Data.jsx",


"bootstrap_title_desc": `const data = [
{
"userId": 1,
"reviews":"feedback",
"id": 1,
"student":[{
"name":"veer",
"state":"gujarat",
"city":"vadodara"
}

],
"body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
},
{
"userId": 1,
"reviews":"feedback",
"id": 2,
"student":[{
"name":"het",
"state":"maharashtra",
"city":"mumbai"
}

],
"body": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
},
{
"userId": 1,
"reviews":"feedback",
"id": 3,
"student":[{
"name":"jay",
"state":"uttarpradesh",
"city":"aagra"
}

],
"body": "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut"
},
{
"userId": 1,
"reviews":"feedback",
"id": 4,
"student":[{
"name":"meet",
"state":"bihar",
"city":"vadodara"
}

],
"body": "ullam et saepe reiciendis voluptatem adipisci\nsit amet autem assumenda provident rerum culpa\nquis hic commodi nesciunt rem tenetur doloremque ipsam iure\nquis sunt voluptatem rerum illo velit"
},
{
"userId": 1,
"reviews":"feedback",
"id": 5,
"student":[{
"name":"veer",
"state":"gujarat",
"city":"vadodara"
}

],
"body": "repudiandae veniam quaerat sunt sed\nalias aut fugiat sit autem sed est\nvoluptatem omnis possimus esse voluptatibus quis\nest aut tenetur dolor neque"
},
{
"userId": 1,
"reviews":"feedback",
"id": 6,
"student":[{
"name":"veer",
"state":"gujarat",
"city":"vadodara"
}

],
"body": "ut aspernatur corporis harum nihil quis provident sequi\nmollitia nobis aliquid molestiae\nperspiciatis et ea nemo ab reprehenderit accusantium quas\nvoluptate dolores velit et doloremque molestiae"
}

]

export default data;`,

},


      ],
  

      "coursename": "React Nested Json",
  
  
    },


    
    {
      "id":18                                       ,
  
  
      "html_title": "react-accordian",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "accordian npm",
         
  
          
          
          "bootstrap_title_desc": `Install Below Accordian
--- npm i react-accessible-accordion`,

        },

        {
          "bootstrap_title_heading": "App.jsx",
         
          
  
          
          
          "bootstrap_title_desc": `import React from 'react';

import {
Accordion,
AccordionItem,
AccordionItemHeading,
AccordionItemButton,
AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function App() {
return (
<Accordion>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
What harsh truths do you prefer to ignore?
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p>
Exercitation in fugiat est ut ad ea cupidatat ut in
cupidatat occaecat ut occaecat consequat est minim minim
esse tempor laborum consequat esse adipisicing eu
reprehenderit enim.
</p>
</AccordionItemPanel>
</AccordionItem>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
Is free will real or just an illusion?
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p>
In ad velit in ex nostrud dolore cupidatat consectetur
ea in ut nostrud velit in irure cillum tempor laboris
sed adipisicing eu esse duis nulla non.
</p>
</AccordionItemPanel>
</AccordionItem>
</Accordion>
);
}`,

        },


       


        
      

        
      

     


      







  


        



       

     




    
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "React Accordian",
  
  
    },

   
    {
      "id":16,
  
  
      "html_title": "routing-state",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "useLocation()(Example 1)",
         
  
          
          
          "bootstrap_title_desc": `// GoToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoToTop = () => {
const routePath = useLocation();

useEffect(() => {
 window.scrollTo(0, 0);
}, [routePath]);
return null;
}
          
export default GoToTop`,

        },

        
        {
          "bootstrap_title_heading": "App.jsx",
         
          
  
          
          
          "bootstrap_title_desc": `// App.jsx
import React from 'react'
import GoToTop from "./GoToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home"
import Contact from "./Contact"
import About from "./About"
const App = () => {
return (
<div>
<BrowserRouter>
<Header/>
<Routes>
<Route path='/' element={<Home/>}/>
<Route path='/about' element={<About/>}/>
<Route path='/contact' element={<Contact/>}/>
</Routes>
<GoToTop/>
</BrowserRouter>
 
</div>
)
}
export default App`,

        },

        
        {
          "bootstrap_title_heading": "useLocation()(Example 2)",
         
  
          
          
          "bootstrap_title_desc": `// Header.jsx
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
const location = useLocation();
const [hideHeader, setHideHeader] = useState(false);
const hideHeaderRoutes = ['/checkout']; // Replace with your route

useEffect(() => {
// Check if the current route should hide the header
setHideHeader(hideHeaderRoutes.includes(location.pathname));
}, [location.pathname]);

if (hideHeader) {
// Don't render the header if hideHeader is true
return null;
  }

return(
  <div>header</div>
)
}
          
export default Header`,

        },


        {
          "bootstrap_title_heading": "useNavigate()",
         
  
          
          
          "bootstrap_title_desc": `// Header.jsx
import React from 'react'
import {Link,useNavigate} from "react-router-dom";
const Header = () => {

const Navigate = useNavigate()
return (
<div>
<ul>
<li>
<Link to="/">Home</Link>
</li>
<li onClick = {() => Navigate('/blogs')}>Blogs</li>
<li>
<Link to="/about">Contact</Link>
</li>
</ul>
</div>
)
}
export default Header`,

        },

    
      ],
  

      "coursename": "Router State (day-7)",
  
  
    },

    {
      "id":17,
  
  
      "html_title": "react-useparam",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import {
BrowserRouter as Router,
          
Route,
Routes
} from "react-router-dom";
import ExploreContainer from './ExploreContainer';
import ExploreDetail from './ExploreDetail';
const App = () => {
const data = [
{
id: 1,
name: 'Hong Kong Disneyland',
category: 'Entertainment'
},
{
id: 2,
name: 'Repulse Bay and the Beaches',
category: 'Outdoor'
},
{
id: 3,
name: 'Star Ferry PierVictoria Harbour',
category: 'Attraction'
}
]
    
          
return (
<Router>
<Routes>
<Route path='/explore' element={<ExploreContainer data={ data }/>}/>
<Route path='/explore/:name' element={<ExploreDetail data={ data } />}/>
          
          
</Routes>
</Router>
)
}
export default App`,

        },

        {
          "bootstrap_title_heading": "ExploreConatiner.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import ExploreCard from './ExploreCard'
const ExploreContainer = ({data}) => {
return (
<div>
<ExploreCard data={data}/>
</div>
)
}
export default ExploreContainer`,

        },

        {
          "bootstrap_title_heading": "ExploreCard.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { Link } from 'react-router-dom'
const ExploreCard = ({ data }) => {
return (
<>
{ data.map(list => (
<div key={ list.id }>
<h4>Name: <Link to={'/explore/'$'{list.name}'}>{list.name}</Link></h4>
</div>
))}
</>
)
}
export default ExploreCard;`,

        },

        {
          "bootstrap_title_heading": "ExploreDetails.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { useParams } from 'react-router-dom'
const ExploreDetail = ({ data }) => {
const { name } = useParams();
return (
<div className="full-detail">
<div className="explore-container">
{
data
.filter((list) => list.name === name)
.map((list) => (
<div className="full-card" key={ list.id }>
          
<h2>Name: {list.name}</h2>
<h4>Category: {list.category}</h4>
</div>
))}
</div>
</div>
)
}
export default ExploreDetail; `,

        },



      ],
  

      "coursename": "React useParam() (day-8)",
  
  
    },

    {
      "id":15,
  
  
      "html_title": "react-scroll-to-top",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "Type-Writter",
         
  
          
          
          "bootstrap_title_desc": ` install below npm
-- npm i typewriter-effect`,

        },

        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `// App.jsx
import React from 'react';
  

import Typewriter from "typewriter-effect";
import './App.css';
  
function App() {
return (
<div className="App">
 

<Typewriter
options={{
strings: ['Hello', 'World'],
autoStart: true,
loop: true,
}}
/>
</div>
);
}

export default App;`,

        },

        {
          "bootstrap_title_heading": "App.scss",
         
          
  
          
          
          "bootstrap_title_desc": `// App.scss
.App {
font-family: sans-serif;
font-weight:800;
font-size:40px;
text-align: center;
display:flex;
justify-content:center;
align-items:center;
min-height:100vh;
background:rgb(0, 117, 128);
}`,

        },


        {
          "bootstrap_title_heading": "Scroll-To-Top",
         
  
          
          
          "bootstrap_title_desc": `install below npm 

-- npm i react-scroll-to-top`,

        },

        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `// App.jsx
import React from 'react'
import './App.css'
import ScrollToTop from "react-scroll-to-top";


const App = () => {
return (


<div>

 

<ScrollToTop smooth />
<div className='main1'>
Home
</div>

<div className='main2'>
contact
</div>

<div className='main3'>
service
</div>

<div className='main4'>
about
</div>

</div>

}

export default App`,

        },

        {
          "bootstrap_title_heading": "App.scss",
         
  
          
          
          "bootstrap_title_desc": `App.scss
.main1{
background-color: burlywood;
height: 700px;
}

.main2{
background-color: cadetblue;
height: 800px;
}
          
.main3{
background-color: chocolate;
height: 900px;
}

.main4{
background-color: cornflowerblue;
height: 400px;
}`,

        },


    
      ],
  
 
      "coursename": "Go-Top & Typewritter",
  
  
    },


    
    {
      "id":16,
  
  
      "html_title": "react-formik",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "Formik-install npm",
         
  
          
          
          "bootstrap_title_desc": `Install Below npm For Formik

npm install formik --save

npm install yup --save`,

        },

        
        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./App.scss"

const validationSchema = yup.object({
email: yup
.string('Enter your email')
.email('Enter a valid email')
.required('Email is required'),
password: yup
.string('Enter your password')
.min(8, 'Password should be of minimum 8 characters length')
.required('Password is required')
.matches((/^([^0-9]*)$/),"dont't allow Numeric Value"),
 


});

const App = () => {
const formik = useFormik({
initialValues: {
email: '',
password: '',

},
validationSchema: validationSchema,
onSubmit: (values) => {
alert(JSON.stringify(values, null, 2));
},

});
return (
<div className='mains'>
<h1>Login Here</h1>

<form className='main' onSubmit={formik.handleSubmit}>

<TextField 
id="email"
name="email"

value={formik.values.email}
onChange={formik.handleChange}
error={formik.touched.email && Boolean(formik.errors.email)}
helperText={formik.touched.email && formik.errors.email} 
label="Email" 
variant="standard" />

<TextField variant="standard" 
id="password"
name="password"
label="Password"
value={formik.values.password}
onChange={formik.handleChange}
error={formik.touched.password && Boolean(formik.errors.password)}
helperText={formik.touched.password && formik.errors.password}/>

<Button variant="contained" type='submit'>Login</Button>

</form>

</div>
)
}

export default App;`,

        },

        {
          "bootstrap_title_heading": "App.scss",
         
          
  
          
          
          "bootstrap_title_desc": `.mains{
display: flex;
justify-content: center;
align-items: center;
height: 600px;
background-color: rgba(72, 255, 0, 0.516);
flex-direction: column;
}

.main{
display: flex;
justify-content:center ;
align-items: center;
flex-direction: column;
gap: 70px;
box-shadow: 1px 1px 1px 1px;
height: 400px;
width: 500px;
}`,

        },




    
      ],
  

  
      "coursename": "React Formik (day-9)",
  
  
    },

    {
      "id":24,
  
  
      "html_title": "react-pdf-form-data",
  
  
      "bootstrap_head_desc": [

        {
          "bootstrap_title_heading": "install npm",
         
  
          
          
          "bootstrap_title_desc": `jspdf`,

        },

        {
          "bootstrap_title_heading": "MyForm.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from 'react';
 import { Formik, Form, Field, ErrorMessage } from 'formik';
 import * as Yup from 'yup';
 import jsPDF from 'jspdf';
 
 const MyForm = () => {
 
const generatePdf = (name, mobileNumber) => {
  const pdf = new jsPDF();
  
  // Add border
  pdf.setDrawColor(0); 
  pdf.setLineWidth(0.5);
  pdf.rect(5, 5, 200, 100);
  
  // Add title
  pdf.setFontSize(16);
  pdf.text('Form Data', 10, 20);
  
  // Add form data
  pdf.setFontSize(12);
  pdf.text(Name: name, 10, 40);
  pdf.text(Mobile Number:mobileNumber, 10, 50);
  
  pdf.save('form-data.pdf');
};
 
return (
  <Formik
 initialValues={{
name: '',
mobileNumber: ''
 }}
 validationSchema={Yup.object({
name: Yup.string().required('Name is required'),
mobileNumber: Yup.string().required('Mobile number is required')
 })}
 onSubmit={(values, { resetForm }) => {
generatePdf(values.name, values.mobileNumber);
resetForm();
 }}
  >
 <Form>
<label>
  Name:
  <Field type="text" name="name" />
  <ErrorMessage name="name" component="div" />
</label>
<br />
<label>
  Mobile Number:
  <Field type="tel" name="mobileNumber" />
  <ErrorMessage name="mobileNumber" component="div" />
</label>
<br />
<button type="submit">Submit</button>
 </Form>
  </Formik>
);
 };
 
 export default MyForm;`,

        },
      ],

      "coursename": "Form Into Pdf",
  
  
    },
  
    {
      "id":23,
  
  
      "html_title": "react-search-data",
  
  
      "bootstrap_head_desc": [

        {
          "bootstrap_title_heading": "Search.jsx",
         
  
          
          
          "bootstrap_title_desc": `import React from "react";
import { MdClose } from "react-icons/md";
 
import { useState } from 'react';
import { useNavigate } from "react-router-dom";


const Search = () => {

const productsData= [
{
"id": 1,
"searchname": "Basic Tee",
},
{
"id": 2,
"searchname": "Basic Tee1",
},
{
"id": 3,
"searchname": "Basic Tee2",
},
]

let navigate = useNavigate();

const [value, setValue] = useState("");

const onChange = (event) => {
setValue(event.target.value);
};

return (
<div>
<div>
<input
autoFocus
type="text"
placeholder="Search for Course"
value={value} onChange={onChange}

/>
<MdClose

/>
</div>
<div>

<div>

{Product
.filter((Item) => {
const searchTerm = value.toLowerCase();
const Coursename = Item.searchname.toLowerCase();
return (
searchTerm &&
Coursename.startsWith(searchTerm) 


)
})



.map((Item) => (
<div onClick={() => navigate('/searchitem')}}>



 
<span>
{Item.searchname}
</span>


</div>

)

)}



</div>
</div>
</div>
);
};

export default Search;`,

        },

      ],

      "coursename": "React Search (day-10)",
  
  
    },

   

    {
      "id":25,
  
  
      "html_title": "react-product-filter",
  
  
      "bootstrap_head_desc": [

        {
          "bootstrap_title_heading": "Product.jsx",
         
  
          
          
"bootstrap_title_desc": `import { useContext, useState } from "react";
import "./Category.scss";
import { useParams } from "react-router-dom";
import MainProduct from "../MainProduct/MainProduct";
import MyContext from "../../Uttis/MyContext";

const Category = () => {
  const { data } = useContext(MyContext);

  const [sortOrder, setSortOrder] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

const handleSizeChange = (e) => {
setSelectedSize(e.target.value);
};

const sortedProducts = data.map((Item) => {
const sortedVariants = [...Item.product_container].sort((a, b) => {
if (sortOrder === "asc") return a.product_price - b.product_price;
if (sortOrder === "desc") return b.product_price - a.product_price;
if (sortOrder === "rating") return b.count - a.count;
return 0;
});

return { ...Item, product_container: sortedVariants };
});

const { title } = useParams();

return (
<div className="category-page">
{sortedProducts
.filter((Item) => Item.product_category === title)
.map((Item) => {
return (
<div key={Item.product_heading}>
<div className="category-heading">
{Item.product_heading}
<select onChange={handleSortChange} className="select-filter">
<option selected disabled>
Filter
</option>
<option value="asc">Low to High</option>
<option value="desc">High to Low</option>
<option value="rating">By Rating</option>
</select>
<select onChange={handleSizeChange} className="select-filter">
<option selected disabled>
Select Size
</option>
<option value="">All Sizes</option>
<option value="S">Small</option>
<option value="M">Medium</option>
<option value="L">Large</option>
<option value="XL">Extra Large</option>
</select>
</div>
<div className="category-products">
{Item.product_container
.filter((Data2) =>
selectedSize ? Data2.size === selectedSize : true
)
.map((Data2) => {
return (
<MainProduct key={Data2.id} Data={Data2} Item={Item} />
);
})}
</div>
</div>
);
})}
</div>
);
};

export default Category;
`,

        },

        
      ],

      "coursename": "product filter",
  
  
    },
  
  
    {
      "id": 10,
  
  
      "html_title": "react-responsive-slider",
  
  
      "bootstrap_head_desc": [
    
        {
          "bootstrap_title_heading": "install npm",
         
  
          
          
          "bootstrap_title_desc": `install npm for slider 

--- npm i react-multi-carousel`,

        },

        {
          "bootstrap_title_heading": "Slider.jsx",
         
  
          
          
          "bootstrap_title_desc": `// Slider.jsx

import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Slider = () => {
return (
<div>
<Carousel
additionalTransfrom={0}
arrows
autoPlaySpeed={3000}
centerMode={false}
className=""
containerClass="container-with-dots"
dotListClass=""
draggable
focusOnSelect={false}
infinite
itemClass=""
keyBoardControl
minimumTouchDrag={80}
pauseOnHover
renderArrowsWhenDisabled={false}
renderButtonGroupOutside={false}
renderDotsOutside={false}
responsive={{
desktop: {
breakpoint: {
max: 3000,
min: 1024
},
items: 3,
partialVisibilityGutter: 40
},
mobile: {
breakpoint: {
max: 464,
min: 0
},
items: 1,
partialVisibilityGutter: 30
},
tablet: {
breakpoint: {
max: 1024,
min: 464
},
items: 2,
partialVisibilityGutter: 30
}
}}
rewind={false}
rewindWithAnimation={false}
rtl={false}
shouldResetAutoplay
showDots={false}
sliderClass=""
slidesToSlide={1}
swipeable
>

 
<img src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"alt='img1'/>


<img src="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60" alt='img1' />


<img src="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" alt='img1' />

<img src="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" alt='img1' />
 


 
</Carousel>
</div>
)
}

export default Slider`,

        },

     




    
      ],
  

      "coursename": "React Slider (day-11)",
  
  
    },
  
  
    {
      "id":22,
  
  
      "html_title": "react-google-map",
  
  
      "bootstrap_head_desc": [
    
      

    


        {
          "bootstrap_title_heading": "App.jsx",
         
  
          
          
          "bootstrap_title_desc": `{/*step1:go to google maps
step2:search your location which is to be updated
step3:go to share button
step4:go to embed option
step5:copy the link
step6:paste the link which you need
step7:remove inline style form the link*/}

App.jsx//

import React from 'react'

const App = () => {
return (
<div>
<iframe 
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.4643629692873!2d73.172746608397!3d22.33608854151717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc9e2c4382d89%3A0xc25c2a592f9acd4!2sV-Ex%20Tech%20-%20IT%20Training%2FInternship%20%26Placement!5e0!3m2!1sen!2sin!4v1708704539719!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
)
}
          
export default App

`,

        },

        
      

      ],

      "coursename": "React Google Map",
  
  
    },
   
  
  
    {
      "id": 13,
  
  
      "html_title": "react-github-live",
  
  
      "bootstrap_head_desc": [
    
      

        {
          "bootstrap_title_heading": "Deploy Backend",
         
  
          
          
          "bootstrap_title_desc": `for backend 

step1: create .gitignore
/node_modules

step2:create vercel.json
{
  "version":2,
  "builds":[
      {
          "src":"./index.js",
          "use":"@vercel/node"
      }
  ],
  "routes":[
      {
          "src":"/(.*)",
          "dest":"/"
      }
  ]
}

step3:upload it on vercel

`,

        },

        {
          "bootstrap_title_heading": "Deploy Frontend",
         
  
          
          
          "bootstrap_title_desc": `follow Below steps for deploy code on github:

          
step1 - create new repository On Github

step2 - git init

step3 - git commit -m "first commit"

step4 - git branch -M main

step5 -git remote add origin https://github.com/veer2238/hi.git - replace your path

step6-git push -u origin main

step7-go vercel and upload it


don't forget to add below code in the frontend(vercel.json)
{
  "version":2,
  "builds":[
      {
          "src":"./index.js",
          "use":"@vercel/node"
      }
  ],
  "routes":[
      {
          "src":"/(.*)",
          "dest":"/"
      }
  ]
}


`,

        },



       

     




    
      ],
  

      "coursename": "React Deploy (day-12)",
  
  
    },


    {
      "id": 13,
  
  
      "html_title": "react-project-live",
  
  
      "bootstrap_head_desc": [
    
      

        {
          "bootstrap_title_heading": "Project",
         
  
          
          
          "bootstrap_title_desc": `Link:https://www.figma.com/design/FfU1enbZatURChH96rxZWv/Oasis---Modern-%26-Contemporary-eCommerce-UI-KIt-(Community)?node-id=3-3&t=R7TDMucFYh5zMB9m-1

open the link and convert from figma file to React File          `,

        },

      

      ],
  

      "coursename": "React Project",
  
  
    },
  

  
  ]
  export default ReactJson