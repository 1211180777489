import React, { useContext } from 'react'
import './Load.scss'
import MyContext from '../../Uttis/MyContext'
import logo from '../../assets/logo/logo.png';

const MyLoad = () => {
    const {loading} = useContext(MyContext)
  return (

    <>
    {loading &&
    <div className={`loader-container ${loading ? 'loading' : ''}`}>
      
      <img src={logo} alt='' className='logo-loader' />
    </div>
    }
    </>
  )
}

export default MyLoad