import React from 'react';
import './Deve-service.scss';
import { CiMobile3 } from 'react-icons/ci';
import { RiPagesLine } from 'react-icons/ri';
import { BsFillBuildingFill } from 'react-icons/bs';
import { TbPageBreak } from 'react-icons/tb';
import { BsSpeedometer2 } from 'react-icons/bs';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { GrServer } from 'react-icons/gr';
import { AiOutlineSolution } from 'react-icons/ai';
import { useState } from 'react';
import { useEffect } from 'react';

const DeveService = () => {

  const[mi,setMi]=useState('rgb(230, 227, 227)')

const handlemi =() =>{
  if(window.scrollY>1550){
  setMi('rgba(0, 174, 255, 0.653)')
  }
  else{
    setMi('rgb(230, 227, 227)')
  }
}

  useEffect(()=>{
    window.addEventListener('scroll',handlemi)
  },[])
 


  return (
    <div className="Main-info" >
      <div className="Info-pages">
        <div className="desc-box" style={{backgroundColor:mi}}>
          <CiMobile3 />
          <h4> Mobile App development </h4>
          <p> Create Android/Ios Application, App Is Easy To Use.</p>
        </div>
        <div className="desc-box" style={{backgroundColor:mi}}>
          <RiPagesLine />
          <h4>Web Development</h4>
          <p>You Can Connect Globally With Your Own Website.</p>
        </div>
        <div className="desc-box" style={{backgroundColor:mi}}>
          <BsFillBuildingFill />
          <h4>Infrastructure management</h4>
          <p> Maintaining Infrastructure lifecycle.</p>
        </div>
        <div className="desc-box" style={{backgroundColor:mi}}>
          <TbPageBreak />
          <h4>Fastest Coding</h4>
          <p>Customized Coding that Make You Faster.</p>
        </div>
     
          <div className="desc-box" style={{backgroundColor:mi}}>
            <BsSpeedometer2 />
            <h4>virtualization Solution</h4>
            <p> Converting IT solution in low cost. Purpose build system.</p>
          </div>
          <div className="desc-box" style={{backgroundColor:mi}}>
            <AiOutlineCloudDownload />
            <h4>Cloud Computing</h4>
            <p>Scalable cloud computing that servers valid data across verticals.</p>
          </div>
          <div className="desc-box" style={{backgroundColor:mi}}>
            <GrServer />
            <h4>Big Data Solution</h4>
            <p>Analysis Is The Best Solution For Big Data.</p>
          </div>
          <div className="desc-box" style={{backgroundColor:mi}}>
            <AiOutlineSolution />
            <h4>IOT Solution</h4>
            <p>It refers to the collective network of connected devices and the technology.</p>
          </div>
   
      </div>
    </div>
  );
};

export default DeveService;
