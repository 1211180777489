
   
  
  
  
  
  
 
  
  const Tjson = [
  
    {
      "id": 1,
      "title":"Image Api",
      "desc":"If you want Image ,just type hint text for image it will be automatic generated.",
  "link":"https://www.midjourneyai.ai/"

  
  
    },

    {
        "id": 2,
        "title":"how to remove background noise",
        "desc":"Enhance Speech makes voice recordings sound as if they were recorded in a professional podcasting studio.",
    "goto":"adobe enhace",
    "link":"https://podcast.adobe.com/enhance"
  
    
    
      },

      {
        "id": 3,
        "title":"how to take image for web pages",
        "desc":"Adobe Express, the fast, easy and fun, all-in-one app, with Adobe Firefly generative AI built in now offers even more.",
    "goto":"adobe express",
    "link":"https://new.express.adobe.com/"
  
    
    
      },


      {
        "id": 4,
        "title":"best screen recorder for codding",
        "desc":`Free Tool for Creating Screencasts
        Easily create screencasts, edit recorded videos, and upload to YouTube. No watermark or time limits.`,
    "goto":"free cam",
    "link":"https://www.freescreenrecording.com/"
  
    
    
      },

      {
        "id": 5,
        "title":"the best logo maker software",
        "desc":`To design a logo on LOGO.com, start by adding your business name and (optional) slogan, and the platform will generate hundreds of logo designs.

        The logo editor is super easy and fun to use. It demands no design or tech knowledge, and anyone can use it without instructions or training. It’s intuitive and streamlined. Just pick a design and start customizing.
        
        When you’re happy with the result, download the logo for free and start using it immediately!`,
    "goto":"logo",
    "link":"https://logo.com/"
  
    
    
      },

      {
        "id": 6,
        "title":"How To Reduce Image Size",
        "desc":`To Reduce Image Size We Use Image Optimization.`,
    "goto":"Optimizer",
    "link":"https://jpeg-optimizer.com"
  
    
    
      },

      {
        "id": 7,
        "title":"How To Convert Link From Image",
        "desc":`Convert Your Image Into Link`,
    "goto":"imgbb",
    "link":"https://imgbb.com/"
  
    
    
      },

  
  
  
  
   
  
  ]
  export default Tjson