import fullPdf from "../../assets/pdf/Fullstack.pdf"
import reactPdf from "../../assets/pdf/REACT.pdf"
import cLangPdf from "../../assets/pdf/C_Languge.pdf"
import javascript from "../../assets/pdf/javascript.pdf"
import python from "../../assets/pdf/python.pdf"
import cplus from "../../assets/pdf/cplus.pdf"
import csspdf from "../../assets/pdf/Css.pdf"
import cssapdf from "../../assets/pdf/css-advance.pdf"
import sqlpdf from "../../assets/pdf/Sql.pdf"
import powerbi from "../../assets/pdf/power-bi.pdf"

const PDF = [

    {
        id:1,
        pdf:fullPdf,
        name:"HTML"
    },

    {
        id:2,
        pdf:csspdf,
        name:"CSS"
    },

    {
        id:3,
        pdf:cssapdf,
        name:"CSS ADVANCE"
    },

    {
        id:4,
        pdf: javascript,
        name:"JAVASCRIPT"
    },

    {
        id:5,
        pdf: reactPdf,
        name:"REACT JS"
    },

    {
        id:6,
        pdf: python,
        name:"PYTHON"
    },

    {
        id:7,
        pdf: sqlpdf,
        name:"SQL"
    },

    {
        id:8,
        pdf: powerbi,
        name:"POWERBI"
    },

    {
        id:9,
        pdf: cLangPdf,
        name:"C LANGUAGE"
    },

    {
        id:10,
        pdf: cplus,
        name:"C++LANGUAGE"
    },



]

export default PDF