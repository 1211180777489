import React from 'react'


import "./Paragraph.scss"
import Product from './Json'


const Paragraph = () => {
  return (
    <div className="single-product-main-content">

      
           

           

            {Product.map((Item)=>{
                return(

                    <div className="layout">

                    <div className="single-product-page" style={Item.stylei}>

                       
            
                    <div className="left">
                            <img src={Item.img_page} alt="" />
                        </div>
                       
                        <div className="right">
            
                       
                        <span className="price">{Item.title}</span>
                        <span className="desc">{Item.desc}
                        
                        </span>
                       
            
                        
            
                       
            
            
                        
                        </div>
            
                        
                    </div>
            
                    
                </div>

                )
            })

            }

            
      
   
    </div>
  )

}

export default Paragraph