import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Product from "./components/Header/Service/Course/Jason";
import Header from "./components/Header/Header";
import Newsletter from "./components/Footer/Newsletter/Newsletter";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Header/Contact/Contact";
import Service from "./components/Header/Service/Service";
import Student from "./components/Header/Students/Student";
import Load from "./components/Loader/Load";
import Fullstack from "./components/Header/Service/Course/Programs/Fullstack/Fullstack";
import ScrollToTop from "react-scroll-to-top";
import GoToTop from "./components/GoToTop/GoToTop";
import { Helmet } from "react-helmet";
import NoPage from "./components/NoPage/NoPage";
import Tools from "./components/Footer/Tools/Tools";
import WebBatch from "./components/WebBatch/WebBatch";
import AttendanceForm from "./components/AttendanceForm/AttendanceForm";
import CodeMaster from "./components/CodeEditor/CodeMaster";
import MyContextProvider from "./Uttis/MyContextProvider";
import Bottom from "./components/Footer/Bottom/Bottom";
import CertiVerification from "./components/CertiVerification/CertiVerification";
import CertiDetails from "./components/CertiVerification/CertiDetails/CertiDetails";
import { TbArrowBigUpLinesFilled } from "react-icons/tb";
import Register from "./components/Register/Register";
import Passport from "./components/Passport/Passport";
import Report from "./components/Footer/GtuReport/Report";
import ViewReport from "./components/Footer/GtuReport/ViewReport";
import PortFolio from "./components/Home/PortFolio/PortFolio";
import MyLoad from "./components/Loader/MyLoad";
import Success from "./Common/Success/Success";
import Batch from "./Common/Batch/Batch";
import Search from "./components/Header/Search/Search";
import Left from "./components/LeftSideBar/Left";
import Right from "./components/Right/Right";
import SqlContent from "./components/Lerning/Sql/SqlContent/SqlContent";
import Terms from "./components/Footer/Tearm/Tearm";
import JoinForm from "./components/WebBatch/JoinForm/JoinForm";
import Confirm from "./components/WebBatch/JoinForm/Confirm/Confirm";

const App = () => {
  return  (   

       


       

        
    <BrowserRouter>

    <MyContextProvider>
  
<Load/>




<Helmet>
    <title>V-Ex Tech Solution</title>
    <meta name="title" content="Best IT Course In Vadodara Nizampura." />
    <meta name="description" content="V Ex Tech Solution is a software development company that provides IT training and internship programs. We help students develop the skills they need to get jobs in the software industry. V-Ex Tech, a software training institute and software company in Vadodara, Gujarat, India, has been helping students develop the skills they need to get jobs in the software industry. V-Ex Tech offers IT training and internship programs in a variety of software technologies, including web development, mobile development, and data science. V-Ex Tech is a trusted partner for students and businesses alike, helping students launch their careers and businesses to find qualified talent." />

  </Helmet>


<Batch/>

       <MyLoad/>
       <Success/>
       <Search/>
       <Left/>
       <Right />
       <JoinForm/>
            <Header/>
            
          
            
           
             
            <Routes>
        
               
                <Route exact path="/" element={<Home />} />
               <Route path="/contact" element={<Contact/>} />
                <Route path="/batch/:batch" element={<WebBatch/>}/>
                <Route path="/confirm" element={<Confirm/>}/>
                <Route path="*" element={<NoPage/>} />
                <Route path="/terms-condition" element={<Terms/>} />
             
                <Route path="/certi-verify" element={<CertiVerification/>} />
                <Route path="/certi-details" element={<CertiDetails/>} />
                <Route path="/vxeditor" element={<CodeMaster/>} />
                <Route path="/student-registration" element={<Register/>} />
                

             
                <Route path="/tools/" element={<Tools/>}/>
               

                <Route path="/service" element={<Service Product={Product}/>}  />
                <Route path="/course/:coursename" element={<Fullstack Product={Product}/>}/>
                
                
              <Route path="learning/:course/:title" element={<SqlContent/>} />
               
                <Route path="/internship-report/:program" element={<ViewReport/>} />
                <Route path="/about" element={<Student />} />
                <Route path="/attendance" element={<AttendanceForm />} />
                <Route path="/check-status" element={<Passport />} />
                <Route path="/internship-report" element={<Report />} />
                <Route path="/portfolio" element={<PortFolio />} />
              
          
              

         

            </Routes>
  
           
            <Newsletter />
            <Footer />
            <Bottom/>
        
   
          

            

           
            <ScrollToTop smooth style={{display:"flex",justifyContent:"center",alignItems:"center",zIndex:9}} component={<TbArrowBigUpLinesFilled size={29}/>} />
          
      
           
           <GoToTop/>
    
           </MyContextProvider>
    </BrowserRouter>
    

  )
}

export default App