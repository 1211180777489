

const HTML = [

  {
    "id": 1,


    "html_title": "intro",


    "bootstrap_head_desc": [
      {
        
        "bootstrap_title_heading": "Introduction",
        "bootstrap_title_desc": `HTML (Hypertext Markup Language) 
is the standard language for creating and designing web pages.
It provides a structure for content on the web.`,
"copy":true



      },

      {
        "bootstrap_title_heading": "Syntex",
        "bootstrap_title_desc": `The <!DOCTYPE html> declaration defines that this document is an HTML5 document
The <html> element is the root element of an HTML page
The <head> element contains meta information about the HTML page
The <title> element specifies a title for the HTML page (which is shown in the browser's title bar or in the page's tab)
The <body> element defines the document's body, and is a container for all the visible contents, such as headings, paragraphs, images, hyperlinks, tables, lists, etc.
The <h1> element defines a large heading
The <p> element defines a paragraph`,
"copy":true

      },

      {
        "bootstrap_title_heading": "Installation",
        
        "bootstrap_title_desc": `HTML text editors are used to create and modify web pages.
HTML codes can be written in any text editor including the notepad.
One just needs to write
HTML in any text editor and save the file with an
extension “.html” or “.htm”. we will use vs code to write codding.`,
"copy":true
       

      },

      {

        "bootstrap_title_heading": "Tags",
        "bootstrap_title_desc": `An HTML element is defined by a start tag, some content, and an end tag:

<tagname> Content goes here... </tagname>
The HTML element is everything from the start tag to the end tag:

<h1>My First Heading</h1>
<p>My first paragraph.</p>

All Elements
1) heading
2) paragraph
3) links
4) images
5) videoes`,
"copy":true



      }

      
    ],



    "coursename": "Intro (day-1)",


  },



  {
    "id": 2,


    "html_title": "elements",


    "bootstrap_head_desc": [

   
      {
        "bootstrap_title_heading": "Headings",
      
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>This is heading 1</h1>
<h2>This is heading 2</h2>
<h3>This is heading 3</h3>
<h4>This is heading 4</h4>
<h5>This is heading 5</h5>
<h6>This is heading 6</h6>
        
        
</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Paragraphs",
       
        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<body>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
Perspiciatis reprehenderit dolorem obcaecati incidunt
consectetur velit accusantium possimus explicabo! 
Quidem quae, minus ex a iste deleniti voluptatum saepe
quos alias harum?</p>
</body>
</html>`,
      },

      {
        "bootstrap_title_heading": "Practice Test",
      

        
        
        "bootstrap_title_desc": `Create One Html Page In Which Your
- name
- dob
- address 
- pincode
- state
- city
- age
- father's name
- mother's name
is mentioned. means have to create biodata,use heading tag and
paragraph tag.`,
copy:true

      },


    
    ],



    "coursename": "Elements",


  },



  {
    "id": 3,


    "html_title": "atributes",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "href Attribute",
   

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h3>open link</h3>
<a href="https://v-extechsolution.in/" >Visit site</a>

<h3>open link in new tab</h3>
<a href="https://v-extechsolution.in/" target="_blank">Visit site</a>

<h3>download pdf or image</h3>
<a href="https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf" download="my.pdf">Visit site</a>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Images Atributes",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<img src="https://m.media-amazon.com/images/I/81OKATHvbeL._UY879_.jpg" alt="boy a jacket" width="200px" height="200px">

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "The style Attribute",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<body>
<p style="color:red;">This is a red paragraph.</p>
</body>
</html>`,

  },

  {
        "bootstrap_title_heading": "The title Attribute",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h2 title="I'm a header">The title Attribute</h2>

<p title="I'm a tooltip">Mouse over this paragraph, to display the title attribute as a tooltip.</p>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Practice Test",
       
       

        
        
        "bootstrap_title_desc": `Create One Html Page In Which Your
- name
- dob
- address 
- pincode
- state
- city
- age
- father's name
- mother's name
is mentioned. means have to create biodata,use heading tag and
paragraph tag, mantion your favourite Youtube Video Link By name of My Video,Link
Should Be Open In New Tab.And Add One Image Whatever You Want,
and when i move cursor on text and images,title will be visible.`,
copy:true

      },


    
    ],












    "coursename": "Atributes (day-2)",


  },



 
  {
    "id": 4,


    "html_title": "display",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Line Breaks",
       
      
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<p>This is<br>a paragraph<br>with line breaks.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "<pre> Element",
       
    
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<pre>
   My Bonnie lies over the ocean.

   My Bonnie lies over the sea.

   My Bonnie lies over the ocean.
   
   Oh, bring back my Bonnie to me.
</pre>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Horizontal Rules",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1>This is heading 1</h1>
<p>This is some text.</p>
<hr>

<h2>This is heading 2</h2>
<p>This is some other text.</p>
<hr>

<h2>This is heading 2</h2>
<p>This is some other text.</p>

</body>
</html>`,

      },

     


    
    ],




    "coursename": "Display (day-3)",


  },




  {
    "id": 5,


    "html_title": "text-formating",


    "bootstrap_head_desc": [
      {
"bootstrap_title_heading": "Text-Formating",
       
      
        

        
        
        "bootstrap_title_desc": ` <!-- <b> - Bold text
<strong> - Important text
<i> - Italic text
<mark> - Marked text
<small> - Smaller text
<del> - Deleted text
<ins> - Inserted text
<sub> - Subscript text
<sup> - Superscript text -->



<!DOCTYPE html>
<html>
<body>

<b>This text is bold.</b>
<strong>This text is important!</strong>
<i>This text is italic</i>
<small>This is some smaller text.</small>
<p>Do not forget to buy <mark>milk</mark> today.</p>
<p>My favorite color is <del>blue</del> red.</p>
<p>My favorite color is <del>blue</del> <ins>red</ins>.</p>
<p>This is <sub>subscripted</sub> text.</p>
<p>This is <sup>superscripted</sup> text.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Comments",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<!-- This is a comment -->
<p>This is a paragraph.</p>
<!-- Comments are not displayed in the browser -->

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "html-icon",

        "bootstrap_title_desc": ` <!-- For Taking Icon In html,We Use Fontawesome,
just type fontawesome in google,you will see first website,click on it,
select icon tab,and serch your fav icon,and copy it,paste in your code,

now go to fontawesome cdn in google and copy first url and paste in the head tag
in your code. -->


<!DOCTYPE html>
<html>
<head>
<title>My Page Title</title>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
</head>
<body>

<i class="fa-solid fa-magnifying-glass"></i>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Practice Test",
       
       

        
        
        "bootstrap_title_desc": `--Question
Create paragraph of 10 words,and break it by single word,
and take another paragraph with pre tag ,output will be visible same as above,
and below the perticular word horizontal line should be mentioned and create
10 chemical formulas.2 chemical formula should be comment means hide
,and create 5 icons -home,search,contact,map,menu, `,
copy:true

      },
   

      


    
    ],












    "coursename": "Text-Formating",


  },





  {
    "id": 6,


    "html_title": "Table-list",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Create Table",
       
    
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>

<body>

<table>
<tr>
<th>Company</th>
<th>Contact</th>
<th>Country</th>
</tr>
<tr>
<td>Alfreds Futterkiste</td>
<td>Maria Anders</td>
<td>Germany</td>
</tr>
<tr>
<td>Centro comercial Moctezuma</td>
<td>Francisco Chang</td>
<td>Mexico</td>
</tr>
</table>

</body>
</html>`,


      },


      {
        "bootstrap_title_heading": "List",
       
 
        "bootstrap_title_desc": `<!--there are two types of list
order list - which is in order
unorder list - which is in unorder-->



<!DOCTYPE html>
<html>
<body>

<ul>
<li>Coffee</li>
<li>Tea</li>
<li>Milk</li>
</ul> 

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "unorder List",
       
    
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<ol>
<li>Coffee</li>
<li>Tea</li>
<li>Milk</li>
</ol> 

</body>
</html>


<!-- atributes for ol  
type="1"
type="A"
type="a"
type="I"
type="i"
start="50" -->`,

      },

     

      


    
    ],


    "coursename": "Table and List (day-4)",


  },






  {
    "id": 7,


    "html_title": "video-tag",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Iframe tag",
       
  
    

        
        
        "bootstrap_title_desc": `<!--for adding youtube video follow this steps:
1) go to youtube,
2) select your video,
3) go to share button,
4) click on embed button,
5)copy code and paste it in your code-->


<iframe width="560" height="315" src="https://www.youtube.com/embed/A7AWfD6Pes8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,


      },

      {
        "bootstrap_title_heading": "Video Tag",
       
    
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<video width="320" height="240" autoplay>
<source src="movie.mp4" type="video/mp4">
Your browser does not support the video tag.
</video>

</body>
</html>`,

      },

      {
        "bootstrap_title_heading": "Practice Test",
       
       

        
        
        "bootstrap_title_desc": `--Question 1)
create One Table as below and

--question 2)
create cafe list for 
fast food - orderlist
resturent menu - unorderlist

--QUESTION 3)
CREATE ONE YOUTUBE VIDEO USING IFRAME TAG AND 
ONE ANY DOWNLOADED VIDEO WITH AUTOPLAY`,
copy:true


      },

    ],












    "coursename": "Video Tag",


  },

  {
    "id": 8,


    "html_title": "entities",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Entities",
       
    
      

        
        
        "bootstrap_title_desc": `<!-- non-breaking space	&nbsp;	&#160;	
-- <	less than	&lt;	&#60;	
-- >	greater than	&gt;	&#62;	
-- &	ampersand	&amp;	&#38;	
-- "	double quotation mark	&quot;	&#34;	
-- '	single quotation mark (apostrophe)	&apos;	&#39;	
-- ¢	cent	&cent;	&#162;	
-- £	pound	&pound;	&#163;	
-- ¥	yen	&yen;	&#165;	
-- €	euro	&euro;	&#8364;	
-- ©	copyright	&copy;	&#169;	
-- ®	registered trademark	&reg;	&#174;

one example for entities-->
<!DOCTYPE html>
<html>
<body>

<h1>HTML Entity Example</h1>

<h2>The copyright sign: &copy;</h2>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Symbol Entities",

        "bootstrap_title_desc": `<!-- ∀	&#8704;	&forall;	FOR ALL	
-- ∂	&#8706;	&part;	PARTIAL DIFFERENTIAL	
-- ∃	&#8707;	&exist;	THERE EXISTS	
-- ∅	&#8709;	&empty;	EMPTY SETS	
-- ∇	&#8711;	&nabla;	NABLA	
-- ∈	&#8712;	&isin;	ELEMENT OF	
-- ∉	&#8713;	&notin;	NOT AN ELEMENT OF	
-- ∋	&#8715;	&ni;	CONTAINS AS MEMBER	
-- ∏	&#8719;	&prod;	N-ARY PRODUCT	
-- ∑	&#8721;	&sum;	N-ARY SUMMATION
-- Α	&#913;	&Alpha;	GREEK CAPITAL LETTER ALPHA	
-- Β	&#914;	&Beta;	GREEK CAPITAL LETTER BETA	
-- Γ	&#915;	&Gamma;	GREEK CAPITAL LETTER GAMMA	
-- Δ	&#916;	&Delta;	GREEK CAPITAL LETTER DELTA	
-- Ε	&#917;	&Epsilon;	GREEK CAPITAL LETTER EPSILON	
-- Ζ	&#918;	&Zeta;	GREEK CAPITAL LETTER ZETA
-- ©	&#169;	&copy;	COPYRIGHT SIGN	
-- ®	&#174;	&reg;	REGISTERED SIGN	
-- €	&#8364;	&euro;	EURO SIGN	
-- ™	&#8482;	&trade;	TRADEMARK	
-- ←	&#8592;	&larr;	LEFTWARDS ARROW	
-- ↑	&#8593;	&uarr;	UPWARDS ARROW	
-- →	&#8594;	&rarr;	RIGHTWARDS ARROW	
-- ↓	&#8595;	&darr;	DOWNWARDS ARROW	
-- ♠	&#9824;	&spades;	BLACK SPADE SUIT	
-- ♣	&#9827;	&clubs;	BLACK CLUB SUIT	
-- ♥	&#9829;	&hearts;	BLACK HEART SUIT	
-- ♦	&#9830;	&diams;	BLACK DIAMOND SUIT

-one example-->
<!DOCTYPE html>
<html>
<body>

<p>I will display &euro;</p>
<p>I will display &#8364;</p>
<p>I will display &#x20AC;</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Emojis",
       
 
   

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
</head>
<body>

<h1>My First Emoji</h1>

<p>&#128512;</p>

</body>
</html>`,


      },

    

    ],


    "coursename": "Entities (day-5)",


  },



  {
    "id": 11,


    "html_title": "division-tag",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Div Tag",
       

  

        
        
        "bootstrap_title_desc": `<!--You Can Divide Website Into Many Part
-- example:-->
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<body>
<div style="background-color: aquamarine; height: 80px;"></div>
<div style="background-color: rgb(255, 155, 127); height: 100px;"></div>
<div style="background-color: rgb(0, 0, 0); height: 280px;"></div>
</body>
</html>`,


      },


      {
        "bootstrap_title_heading": "Example",
       

  

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Div Tag Example</title>
</head>
<body>

<div>
<h1>Welcome to My Website</h1>
<p>This is an example of a simple HTML page using the div tag. The div tag is used to create a container for other HTML elements. It is often used to group elements together.</p>
</div>

<div>
<h2>About Us</h2>
<p>Our website is dedicated to providing the best content for our users.</p>
</div>

<div>
<h2>Contact</h2>
<p>You can reach us at contact@mywebsite.com.</p>
</div>

</body>
</html>
`,


  },



      {
        "bootstrap_title_heading": "Practice Test",
       
       

        
        
        "bootstrap_title_desc": `-- Question
Craete Divison In Flipkart Website And
Amazon Website,color should be different for per Division.`,
copy:true


      },

    

    ],


    "coursename": "Division Tag",


  },

  {
    "id": 12,


    "html_title": "input-forms",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "input Forms",
       
    
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>


<form>

  name:<input type="text">
  
</form> 


</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Other Input Type",
       
     

        
        
        "bootstrap_title_desc": `there are so many input type availbale.
<input type="button">
<input type="checkbox">
<input type="date">
<input type="email">
<input type="file">
<input type="number">
<input type="password">
<input type="radio">
<input type="submit">
<input type="text">
<input type="time">`,
copy:true

      },

      {
        "bootstrap_title_heading": "Example",
       
     

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Input Types Example</title>
</head>
<body>


<form method="post">
<!-- Button -->
 
<input type="button" value="Click Me">
<br/><br/>

<!-- Checkbox -->
Checkbox:<input type="checkbox">
<br/><br/>

<!-- Date -->
  Date: <input type="date" >
<br/><br/>

<!-- Email -->
   Email:<input type="email" >
  <br/><br/>

<!-- File -->
  File:<input type="file" >
<br/><br/>

<!-- Number -->
   
Number:<input type="number">
<br/><br/>

<!-- Password -->
 password: <input type="password" >
   <br/><br/>

<!-- Radio -->
   
Gemder:
Option2 <input type="radio" name="radio">
Option 1 <input type="radio" " name="radio" >

<br/><br/>

<!-- Submit -->
<input type="submit" value="Submit">
<br/><br/>

<!-- Text -->
 <input type="text">
<br/><br/>

<!-- Time -->
   time:<input type="time" id="time" name="time">
   
</form>

</body>
</html>
`,

  },


      {
        "bootstrap_title_heading": "Input Atributes",
       
     

        
        
        "bootstrap_title_desc": `1) value="John"
2) readonly
3) disabled
4) maxlength="3"
5) max="2023-09-13" --only for date
6) min="2023-09-13" -- only for date
7) multiple --only for file
8) placeholder="name"
`,
copy:true


      },

      {
        "bootstrap_title_heading": "Example",
       
     

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Input Types Example</title>
</head>
<body>


<form method="post">




<!-- Date -->
  Date: <input type="date" max="2023-09-13" min="2023-09-09">
<br/><br/>


<!-- File -->
  File:<input type="file" multiple>
<br/><br/>



 <input type="text" value="John">
<br/><br/>

<input type="text" value="John" readonly>
<br/><br/>

<button disabled>click me</button>
<br/><br/>

<input type="text" maxlength="3">
<br/><br/>

<input type="text" placeholder="name">


   
</form>

</body>
</html>
`,

  },


    

    ],












    "coursename": "Input Forms (day-6)",


  },

  {
    "id": 13,


    "html_title": "form-element",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Form Elements",

        "bootstrap_title_desc": `<!--there are so many element in the form
1) <input>
2) <label>
3) <select>
4) <option> -- selected atributes
5) <textarea>
6) <button>
7) <fieldset>
8) <legend>


example-->

<!DOCTYPE html>
<html>
<body>


<form>
<fieldset>
<legend>details:</legend>
<label>First name:</label>
<input type="text">

<select >
  <option>Volvo</option>
  <option>Saab</option>
  <option>Fiat</option>
  <option>Audi</option>
</select>

<textarea name="message" rows="10" cols="30">
The cat was playing in the garden.
</textarea>

<button>Click Me!</button>

</fieldset>
</form> 


</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Practice Test",
       
       

        
        
        "bootstrap_title_desc": `Create Registration Form it contains:
first name
last name 
email id
dob
mobile number
address
pincode
state
city
gender
hobbies
submit button

use legend tag`,
copy:true


      },



     


    

    ],












    "coursename": "Form-Element (day-7)",


  },

  {
    "id": 15,


    "html_title": "html-Project",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Project",
       
       

        
        
        "bootstrap_title_desc": `Question 1: Basic Form Creation
Create an HTML form with the following fields:

Text input for "First Name"
Text input for "Last Name"
Email input for "Email Address"
Password input for "Password"
Submit button


Question 2: Image and Link
Create an HTML page that:

Displays an image with a width of 300 pixels.
Provides a link below the image that opens in a new tab and leads to 
"https://v-extechsolution.in/static/media/react.c8eddbda4810db5719a0.png".


Question 3: Table Creation
Create an HTML table with the following structure:

A table with a header row containing three columns: "Name", "Age", "City".
Two additional rows with sample data.

Question 4: List Creation
Create an HTML document that contains:

An unordered list with at least three list items.
An ordered list with at least three list items.

Question 5: Input Attributes
Create an HTML form that includes:

- A text input with a default value of "John Doe" and a
maximum length of 20 characters.
- A date input with a minimum date of "2023-01-01" and a
maximum date of "2024-12-31".
- A file input that allows multiple file uploads.
- A number input that allows only values between 1 and 10.
- A submit button.

Question 7: Forms with Different Input Types
Create an HTML form with the following input types:

Text input for "Username"
Password input for "Password"
Radio buttons for "Gender" with options "Male" and "Female"
Checkbox for "Agree to Terms"
Dropdown select for "Country" with at least three options
Submit button

Question 8: Video
Create an HTML page that:

Embeds a video file with controls.

Question 9: Hyperlinks and Anchor Tags
Create an HTML document that includes:

A hyperlink to "https://v-extechsolution.in/" with the text 
"Go to V-Ex TEch Solution".
An anchor tag within the same page 
and another tab


submit : covert into github link and submit this project in the
v-ex tech dashboard `,
copy:true


      },


    ],


    "coursename": "Html Project",


  },


  {
    "id": 14,


    "html_title": "html-overview",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "all tags",
       
     

        
        
        "bootstrap_title_desc": `Basic Structure:


<!DOCTYPE html>: Document type declaration.
<html>: Root element.
<head>: Contains meta-information about the document.
<title>: Specifies the title of the document.
<meta>: Provides meta-information such as character set and viewport.
<body>: Contains the content of the document.


Text Content:

<h1> to <h6>: Heading tags.
<p>: Paragraph.
<br>: Line break.
<hr>: Horizontal rule.


Lists:

<ul>: Unordered list.
<li>: List item.
<ol>: Ordered list.
<li>: List item.
<dl>: Definition list.
<dt>: Term in a definition list.
<dd>: Description in a definition list.


Links and Anchors:

<a>: Anchor (used for links).
href: Specifies the URL.
target: Specifies where to open the linked document.
download: Specifies that the target will be downloaded when a
user clicks on the hyperlink.
<link>: Defines relationships between the current document
and an external resource (e.g., stylesheet).


Images:

<img>: Image.
src: Specifies the path to the image file.
alt: Alternative text for the image.


Forms:

<form>: Form container.
action: Specifies where to send the form data.
method: Specifies the HTTP method (e.g., "GET" or "POST").
<input>: Input field.
type: Specifies the type of input (text, checkbox, radio, etc.).
name: Specifies the name of the input field.
value: Specifies the initial value.
<select>: Dropdown list.
<option>: Defines an option in a dropdown list.
<textarea>: Multiline text input.
<button>: Button.


Tables:

<table>: Table.
<tr>: Table row.
<th>: Table header cell.
<td>: Table data cell.
<thead>, <tbody>, <tfoot>: Table sections.


Semantic Tags:

<header>: Header section.
<footer>: Footer section.
<section>: Section of a document.
<article>: Independent, self-contained content.
<nav>: Navigation links.
<main>: Main content of the document.


Multimedia:

<audio>: Audio content.
<source>: Specifies media resources.
<video>: Video content.
<source>: Specifies media resources.


Meta Information:
<meta>: Provides meta-information about the document.


Comments:
<!-- ... -->: Comments in HTML.
These are some of the fundamental HTML tags.
Remember that HTML is extensible, and new tags
may be introduced over time. Always refer to the
official HTML specification for the latest information.`,
copy:true


      },

 

    ],












    "coursename": "Html Overview",


  },




  {
    "id": 16,


    "html_title": "html-interview-question",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "1) What is HTML?",
       
     

        
        
        "bootstrap_title_desc": `
Ans. - HTML stands for Hyper Text Markup Language. 
It is used to display pages on the Web.
it can turn text into images, tables, links.`,
copy:true


      },


      {
        "bootstrap_title_heading": "2) How many types of heading does an HTML contain?",
       
     

        
        
        "bootstrap_title_desc": `
Ans. -The HTML contains six types of headings which are defined with the <h1> to <h6> tags.
Each type of heading tag displays different text size from another.
So, <h1> is the largest heading tag and <h6> is the smallest one.
<h1>Heading no. 1</h1>    
<h2>Heading no. 2</h2>    
<h3>Heading no. 3</h3>    
<h4>Heading no. 4</h4>    
<h5>Heading no. 5</h5>    
<h6>Heading no. 6</h6> `,
copy:true


      },

      {
        "bootstrap_title_heading": "3)What are some common lists that are used when designing a page?",
       
     

        
        
        "bootstrap_title_desc": `Ans. There are many common lists which are 
used to design a page. You can choose any or a combination
of the following list types:

Ordered list - The ordered list displays elements in numbered format.
It is represented by <ol> tag.
Unordered list - The unordered list displays elements in bulleted format.
It is represented by <ul> tag. `,
copy:true


      },


      {
        "bootstrap_title_heading": "4)What is semantic HTML?",
       
     

        
        
        "bootstrap_title_desc": `Ans. Semantic HTML is a coding style. 
It is the use of HTML markup to reinforce the semantics or
meaning of the content. For example: In semantic HTML <b> </b>
tag is not used for bold statement as well as <i> </i> tag is used for italic.
Instead of these we use <strong></strong> and <em></em> tags. `,
copy:true


      },

      {
        "bootstrap_title_heading": "5)How to insert a copyright symbol on a browser page?",
       
     

        
        
        "bootstrap_title_desc": `Ans. You can insert a copyright symbol by using
&copy; or &#169; in an HTML file.`,
copy:true


      },


      {
        "bootstrap_title_heading": "6)What is a marquee?",
       
     

        
        
        "bootstrap_title_desc": `Ans. Marquee is used to put the scrolling text on a web page.
It scrolls the image or text up, down, left or right automatically. 
You should put the text which you want to scroll within the <marquee>......</marquee> tag.`,
copy:true


      },

      {
        "bootstrap_title_heading": "7)What are empty elements?",
       
     

        
        
        "bootstrap_title_desc": `Ans. HTML elements with no content are called empty elements.
For example: <br>, <hr> etc.`,
copy:true


      },
      

      {
        "bootstrap_title_heading": "8)What is the use of a span tag? Give one example.",
       
     

        
        
        "bootstrap_title_desc": `Ans. Applying CSS styles to specific portions of text.
You can use the style attribute or define a CSS class for the <span>

example:
<p>This is <span style="color: red;">highlighted</span> text.</p>`,
copy:true


      },


      {
        "bootstrap_title_heading": "9)Is there any need to change the web browsers to support HTML5?",
       
     

        
        
        "bootstrap_title_desc": `Ans. Almost all browsers (updated versions) support HTML 5.
For example Chrome, Firefox, Opera, Safari, IE.`,
copy:true


      },
      



     


    

    ],












    "coursename": "Html Short Question",


  },
 

]
export default HTML