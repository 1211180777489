

const Styles = {

    "box1":{
        borderBottom: "3.5px solid red",
        alignSelf:"flex-start"
    },
    "box2":{
        borderBottom: "3.5px solid blue",
        alignSelf:"flex-end"
    },
    "box3":{
        borderBottom: "3.5px solid green",
        alignSelf:"flex-start"
    },
    "box4":{
        borderBottom: "3.5px solid black",
        alignSelf:"flex-end"
    },
    "box5":{
        borderBottom: "3.5px solid orange",
        alignSelf:"flex-start"
    },
    "box6":{
        borderBottom: "3.5px solid red",
        alignSelf:"flex-end"
    },
    "box7":{
        borderBottom: "3.5px solid blue",
        alignSelf:"flex-start"
    },
  
}

const Data = [

    {
        "id":1,
        "batch":"Web Development",
        "develop":"Website",
        "start_date":"FROM BEGINEER TO ADVANCE",
        "param":"web-development-batch1",
        "container":[
            {
                "id": 1,
                "name":"Frontend",
                "dec":"Complete HTML, CSS & Javascript along with frameworks like Bootstrap...",
               "color":Styles.box1,
               "topics":[
                {
                    "id":1,
                    "list":"What is Web?"
                },

                {
                    "id":2,
                    "list":"HTML Basics"
                },
                {
                    "id":3,
                    "list":"Table & Forms"
                },
                {
                    "id":4,
                    "list":"List & div"
                },
                {
                    "id":5,
                    "list":"Css Intro"
                },
                {
                    "id":6,
                    "list":"Selector Types"
                },
                {
                    "id":7,
                    "list":"Box Model"
                },
                {
                    "id":8,
                    "list":"Transform"
                },
                {
                    "id":9,
                    "list":"Animation"
                },
                {
                    "id":10,
                    "list":"Display Flex"
                },
                {
                    "id":11,
                    "list":"Media Query"
                },
                {
                    "id":12,
                    "list":"Bootstrap"
                },
                {
                    "id":13,
                    "list":"Javascript Basic"
                },
                {
                    "id":14,
                    "list":"String Method"
                },,
                {
                    "id":15,
                    "list":"Functions"
                },
                {
                    "id":16,
                    "list":"Array & Evnets"
                },
              
                {
                    "id":17,
                    "list":"Manipulation"
                },
                {
                    "id":18,
                    "list":"Object & class"
                },
                {
                    "id":19,
                    "list":"Callback"
                },
                {
                    "id":20,
                    "list":"Bootstrap"
                },
                {
                    "id":21,
                    "list":"If Else"
                },
                {
                    "id":22,
                    "list":"For Loop"
                },
                {
                    "id":23,
                    "list":"Regx"
                },
                {
                    "id":24,
                    "list":"Arrow Function"
                },
                {
                    "id":26,
                    "list":"Modules"
                },
                {
                    "id":25,
                    "list":"Validation"
                }
               ],
               "accordian":[
                {"id":1,
            "ques":"I am fresher about Coding, is this batch good for me?",
            "ans":"Yes, this batch will cover all important concepts from basic till advanced. So, there is no need to know anything about coding beforehand."
        },
        {"id":2,
        "ques":"Will I Get Job Placement Support?",
        "ans":"Yes,after Course Completion,Will Get Placement"
    },
    {"id":3,
    "ques":"Can we Do Internship In This Batch?",
    "ans":"Yes,Projrcts Will Be Live,so you Can Join As Intern Also."
}
               ]
               
               
        
                
        
              
            },
        
            {
                "id": 2,
                "name":"React JS",
                "dec":"Advanced Frontend Framework Concepts like states, event, Api Calling,Json etc..",
               "color":Styles.box2,
               "topics":[
                {
                    "id":1,
                    "list":"API Calling"
                },

                {
                    "id":2,
                    "list":"React Hooks"
                },
                {
                    "id":3,
                    "list":"Routers"
                },
                {
                    "id":4,
                    "list":"usecallback()"
                },
                {
                    "id":5,
                    "list":"Dynamic Routes"
                },
                {
                    "id":6,
                    "list":"npm"
                },
                {
                    "id":7,
                    "list":"Validation"
                },
                {
                    "id":8,
                    "list":"Dependend dropdown"
                }
               ],
               "accordian":[
                {"id":4,
                "ques":"How will I resolve my doubts?",
                "ans":"We always encourage our students to be self-sufficient in solving their problems. We'll also add you in a community with your fellow batchmates where you can interact with them & help each other. Additionally, if your doubts still not Solved, Will be Solved separately by Teacher."
            },
            {"id":5,
            "ques":"What is the batch duration?",
            "ans":"The batch duration is almost 4.5 months."
        },
        {"id":6,
        "ques":"Will Sat-sun Be Holiday?",
        "ans":"Saturday we take doubts session and sunday will be holiday."
    }
               ]
                
        
              
            },

            {
                "id": 3,
                "name":"BACKEND",
                "dec":"Complete Node Js,Express Js,MongoDb,Login Process,Session etc...",
                "color":Styles.box3,
                "topics":[
                    {
                        "id":1,
                        "list":"Express js"
                    },
                    {
                        "id":2,
                        "list":"Node Js"
                    },
                    {
                        "id":3,
                        "list":"JWT Token"
                    },
                    {
                        "id":4,
                        "list":"login authentication"
                    },
                    {
                        "id":5,
                        "list":"smtp mail"
                    },
                    {
                        "id":6,
                        "list":"session"
                    }


                   
    
                
                   ],
                   "accordian":[
              
                   ]
           
                
        
              
            },
        
            {
                "id": 4,
        
                "name":"Projects",
                "dec":"we'll build Multiple Projects to get a practical & hands-one experience...",
                "color":Styles.box4,
                "topics":[
                    {
                        "id":1,
                        "list":"Projects"
                    }

                   ],
                   "accordian":[
                    {"id":7,
                    "ques":"i am doing job,can i join sat-sun batch only?",
                    "ans":"yes,here there are so many students who are doing job,they join sat-sun batch only."
                }
                   ]
                
        
              
            },
        
            {
                "id": 5,
                "name":"Github",
                "dec":"Complete Git & Github, Learn about How To Deploy Code,pull,push and many more..",
                "color":Styles.box5,
                "topics":[
                    {
                        "id":1,
                        "list":"Git & Github"
                    },
    
                    {
                        "id":2,
                        "list":"Terminal"
                    }
                   ],
                   "accordian":[
                    {"id":8,
                    "ques":"Is the batch in Hindi or English?",
                    "ans":"The batch is taught in Hinglish (a mix of Hindi & English),also in gujarati."
                },
                {"id":9,
                "ques":"How long is the batch access?",
                "ans":"The batch will be accessible for the duration of 1.5 years."
            }
                   ]
                
                
        
              
            },
        
            {
                "id": 6,
                "name":"Deploy Project Live",
                "dec":"Deploy Project Live With Hosting And Domain. Complete Circle Process...",
                "color":Styles.box6,
                "topics":[
                    {
                        "id":1,
                        "list":"Hosting"
                    }
                   ],
                   "accordian":[
                    {"id":10,
                    "ques":"Will there be a Certificate of completion?",
                    "ans":"Yes, you will get a certificate after finishing the batch."
                },
                {"id":11,
                "ques":"Will Classes be Online or Offline,Beecause I Am Not From Vadodara?",
                "ans":"Classes Will Be Online And Offline,Both Will Be Taken,You Can Join Online also."
            }
                    
                   ]
        
                
        
              
            },
        
            {
                "id": 7,
                "name":"Rank On Google",
                "dec":"Complete SEO Tips For Website,How to Rank On Google With Keywords...",
                "color":Styles.box7,
                "topics":[
                    {
                        "id":1,
                        "list":"Keywords SEO"
                    }
    
                
                   ],
                   "accordian":[
                    {"id":12,
                    "ques":"I just completed 12th and I want to start learning coding, can I take it?",
                    "ans":"Yes, you are eligible to enrol as we will cover everything from basics to advanced. It is always better to start as early as possible. It will give you a good head start and ample time to get practical experience. "
                },
                {"id":13,
                "ques":"may i know Batch Time?",
                "ans":"Morning 9am to 10am and alternate second 5pm to 6pm"
            }
                   ]
           
                
        
              
            },



        ]
    }

   

  



  


  




]
export default Data