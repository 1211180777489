import React, { useEffect, useRef } from 'react';
import "./Output.scss";

const Output = ({ code }) => {
  const iframeRef = useRef();

  useEffect(() => {
    const iframe = iframeRef.current;
    const document = iframe.contentDocument;
    document.open();
    document.write(code);
    document.close();
  }, [code]);

  return <iframe title="output" ref={iframeRef} className='output-code'  />;
};

export default Output;
