import { useFormik } from 'formik';
import * as yup from 'yup';
import "./Newsletter.scss";
import {
    FaGoogle,
    FaYoutube,
    FaInstagram,
    FaLinkedinIn,
} from "react-icons/fa";
import { useContext } from "react";
import MyContext from "../../../Uttis/MyContext";

const Newsletter = () => {
    const { location, setSnack, setLoading, setMessage } = useContext(MyContext);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: yup.object({
            email: yup
                .string('Enter your email')
                .email('Enter a valid email')
                .required('Email is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            document.querySelector('body').style.overflow = "hidden";
            try {
                const response = await fetch("https://vhx.vercel.app/newlater", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                });

                const data = await response.json();
                if (data.success) {
                    setSnack(true);
                    setMessage(data.message);
                    resetForm();
                } else {
                    setSnack(true);
                    setMessage(data.error);
                }
            } catch (error) {
                alert(error.message);
            } finally {
                setLoading(false);
                document.querySelector('body').style.overflow = "auto";
            }
        },
    });

    if (location.pathname.match(/^\/(html|learning|react-native|sql|css|student-registration|css-advance|javascript|project|reactjs|vxeditor|express-js|excel|cyber-security|php)\//) || location.pathname === '/vxeditor') {
        return null;
    }

    return (
        <div className="newsletter-section">
            <div className="newsletter-content">
                <span className="small-text">Newsletter</span>
                <span className="big-text">Sign up for latest updates</span>
                <form className="form" onSubmit={formik.handleSubmit}>
                <div className='form-o'>
                    <input
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                    /><br/>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                    ) : null}

                    </div>
                    <button type="submit" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>
                        Subscribe
                    </button>
                </form>
                <div className="text">
                    Will be used in accordance with our Privacy Policy
                </div>
               
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/v-ex-tech-software-company-in-vadodara/" target="_blank">
                        <div className="icon">
                            <FaLinkedinIn size={14} />
                        </div>
                    </a>
                    <a href="https://youtube.com/@Veer_Agraval" target="_blank">
                        <div className="icon">
                            <FaYoutube size={14} />
                        </div>
                    </a>
                    <a href="https://www.instagram.com/v_extech/?igshid=Zjc2ZTc4Nzk%3D" target="_blank">
                        <div className="icon">
                            <FaInstagram size={14} />
                        </div>
                    </a>
                    <a href="https://g.co/kgs/dGFrja" target="_blank">
                        <div className="icon">
                            <FaGoogle size={14} />
                        </div>
                    </a>
                </div>
              
            </div>
        </div>
    );
};

export default Newsletter;
