import React from 'react'
import Product from './Json' 
import './Servicepage.scss'

const Servicepage = () => {
  return (
    <div className='main-service-page'>
    
    {Product.map((Item)=>{
        return(
            <div className='main-page' key={Item.id}>

           <div className='page-image'> <img src={Item.image}/> </div>
            <h1>{Item.title}</h1>
            <p>{Item.description}</p>
            </div>
        )

    })}

    </div>
  )
}

export default Servicepage