import React from 'react';
import './ServicePage.scss';

const ServicePage = () => {
  return (
    <div className="service-page">
     
        <h1>Our Services</h1>
        <div className="grid">
          {/* E-Payment Gateway */}
          <div className="service-card bg-blue-200">
            <h2>E-Payment Gateway</h2>
            <p>
              Simplify online transactions with our secure and reliable e-payment gateway solutions. We offer seamless integration with popular payment processors and ensure compliance with industry standards.
            </p>
          </div>
          {/* User Login System */}
          <div className="service-card bg-green-200">
            <h2>User Login System</h2>
            <p>
              Enhance user experience and security with our robust user login systems. From simple username/password authentication to multi-factor authentication, we provide customizable solutions to fit your requirements.
            </p>
          </div>
          {/* E-Commerce Platform */}
          <div className="service-card bg-yellow-200">
            <h2>E-Commerce Platform</h2>
            <p>
              Accelerate your online business with our feature-rich e-commerce platforms. Our solutions are scalable, responsive, and equipped with essential features such as product management, shopping cart, and secure payment processing.
            </p>
          </div>
          {/* Custom Applications */}
          <div className="service-card bg-purple-200">
            <h2>Custom Applications</h2>
            <p>
              Unlock new possibilities with tailor-made software applications built to address your specific business challenges. Our experienced team works closely with you to deliver innovative solutions that drive efficiency and growth.
            </p>
          </div>
          {/* Admin Panel */}
          <div className="service-card bg-red-200">
            <h2>Admin Panel</h2>
            <p>
              Manage your business operations effectively with our intuitive admin panels. From content management to analytics and reporting, our solutions empower you with the tools needed to make informed decisions and streamline processes.
            </p>
          </div>
        </div>
      
    </div>
  );
};

export default ServicePage;
