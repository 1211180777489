import React from 'react'
import Slider from './Slider/Slider'
import Certificate from './Certification/Certificate'
// import Accordian from './Accordian/Accordian'


import Industry from './Industry/Industry'
import Grid from './Grid/Grid'
import Loader from '../../Loader/Load'
import { Helmet } from 'react-helmet'










const Student = () => {
  return (
    <div>

<Helmet>
<title>Best Programing Course Training In Vadodara</title>
        <meta name="title" content="Best Programing Course Training In Vadodara." />
        <meta name="description" content="programs is very high demanding, v-ex tech solution will help you to get a job and get you skills,live practical training approch,join now" />

</Helmet>

      <Loader/>
      <Slider/>
     
      

      <Grid/>
    
   <Certificate/>
     <Industry/>

    
  
    
       
    </div>
  )
}

export default Student