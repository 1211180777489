const Col = [
    {
      "userid":1, 
      "collage_img":require("../../../assets/College_Slider_img/parul.png"),
      "collage_name":"Parul University",
    
     
      
      
    
    },
    {
      "userid":2,
      "collage_img":require("../../../assets/College_Slider_img/neotech.png"),
      "collage_name":"Neotech Technology",
     
    },
    {
      "userid":3,
      "collage_img":require("../../../assets/College_Slider_img/msu.png"),
      "collage_name":"MS University",
     
  
    },
    {
      "userid":4,
      "collage_img":require("../../../assets/College_Slider_img/kjit.png"),
      "collage_name":"KJIT College"
    },
    {
      "userid":5,
      "collage_img":require("../../../assets/College_Slider_img/svit.png"),
      "collage_name":"SVIT College",
    },
    {
      "userid":6,
      "collage_img":require("../../../assets/College_Slider_img/tuwa.png"),
      "collage_name":"Tuwa College",

    },
    {
      "userid":7,
      "collage_img":require("../../../assets/College_Slider_img/djmit.png"),
      "collage_name":"Dr. Jivraj Maheta",

    },
    {
      "userid":8,

      "collage_img":require("../../../assets/College_Slider_img/itm.jpeg"),
      "collage_name":"ITM University"
    },
    {
      "userid":9,

      "collage_img":require("../../../assets/College_Slider_img/gsfc.png"),
      "collage_name":"GSFC Collage",
   
     
    },
    {
      "userid":10,
      "collage_img":require("../../../assets/College_Slider_img/sigma.png"),
      "collage_name":"Sigma Institute",

    },
    {
      "userid":11,

      "collage_img":require("../../../assets/College_Slider_img/Silver.png"),
      "collage_name":"Silver Oak Uni."
  
    },
    {
      "userid":12,
      "collage_img":require("../../../assets/College_Slider_img/rms polytechnic.png"),
      "collage_name":"RMS Polytechnic."
    },
    {
      "userid":13,
      "collage_img":require("../../../assets/College_Slider_img/vit.png"),
      "collage_name":"VIT."
    },
  
  
  
  
  
  
  
  
    
  ]
  
  export default Col;