import React from 'react'
import certificate from '../../../../assets/Certificate/certificate.png'
import './Certificate.scss'
import {AiFillCheckCircle} from 'react-icons/ai'

const Certificate = () => {
    return (
     

            <div className='certificate-box'>

             
                    <img src={certificate} alt=''/>
               

                <div className='certificate-content'>
                    
                    <li><AiFillCheckCircle/> Skill Development</li>
                    <li><AiFillCheckCircle/> International Recognition</li>
                    <li><AiFillCheckCircle/> Valid for jobs and college Internship</li>
                    <li><AiFillCheckCircle/> Get Higher Salary</li>
                    <li><AiFillCheckCircle/> Certification By Software Company</li>
                  
                </div>

            </div>

    )
}

export default Certificate