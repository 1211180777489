import "./Right.scss";
import { MdClose } from 'react-icons/md'
import { RiServiceFill } from 'react-icons/ri'
import { AiOutlineHome } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import Menu from "./Menu/Menu";
import { MdLocationPin } from "react-icons/md"
import { BsPersonFill } from "react-icons/bs"
import { useContext } from "react";
import MyContext from "../../Uttis/MyContext";


const Right = () => {
   const{navigate,showRight,closeMenu} =useContext(MyContext)
    return (
    

        <>
        {
showRight &&

<div className="cart-panel">
        <div className="opac-layer" onClick={closeMenu}></div>
        <div className="cart-content">
            <div className="cart-header">
                <span className="logo">V-Ex Tech Solution</span>
                <span className="close-btn" onClick={closeMenu}>
                    <MdClose />
                    <span className="text">close </span>
                </span>

            </div>

         
                <div className="home-content">
                    <div className="route" onClick={() => closeMenu() || navigate('/')}><AiOutlineHome />Home</div>
                    <div className="route" onClick={() => closeMenu() || navigate('/about')}><BsPersonFill />About Us</div>
                    <div className="route" onClick={() => closeMenu() || navigate('/service')}><RiServiceFill />Service</div>
                    <div className="route" onClick={() => closeMenu() || navigate('/contact')}><FiPhoneCall />Contact</div>
                </div>
          

            <div className="heading">PROGRAMS</div>
            <Menu />
            <div className="logo2"><MdLocationPin /> Nizampura, Vadodara. </div>
        </div>





    </div>
        }
        </>
    

    )
};

export default Right;