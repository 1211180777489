import { Helmet } from "react-helmet";
import Banner from "./Banner/Banner";
import Card from "./Card/Card";
import Category from "./Category/Category";
import Collage from "./Collage-slider/Collage";
import DeveService from "./Development-service/Deve-serivice";
import Increment from "./Increament/Increament";
import Payment from "./Paymentpage/Payment";
import Project from "./Projects/Project";
import Review from "./Reviewslider/Review";
import Servicepage from "./Servicepage/Servicepage";
import Creative from "./Creative/Creative";
import Landing from "./Landing/Landing";
import Video from "./Video/Video";
import "./Home.scss";

const Home = () => {


  return (
    <>


      <Helmet>
        <title>Best Software Company In Vadodara</title>
        <meta name="title" content="Best Coding Classes In Vadodara Nizampura." />
        <meta name="description" content=" Nizampura -Python Html Css Javascript React all courses are availbale here with placement,take practical knowledge about website in vadodara." />
      </Helmet>

      <Category />
      
    <Landing />
     <Creative />
        <Banner />
     <Project />
     <DeveService />
      <Servicepage />
        <Collage />
     <Card />
       <Review />
    <Payment />
      <Video />
      <Increment />
    </>
  );
};

export default Home;
