import React, { useContext} from 'react';
import CodeEditor from './CodeEditor';
import "./CodeMaster.scss"
import Output from './Output';
import MyContext from '../../Uttis/MyContext';

const CodeMaster = () => {
const{code,setCode} = useContext(MyContext)

  return (
    <div className='codemaster-main'>
    <div className='main-editor'>
 <div className='codeitor-name'>V-Ex Tech Solution</div>
      <button  className='run-btn' onClick={()=>window.location.reload()}>Run</button>
      </div>
      <div className='code-master'>
      <div className='editor-container'>
        <CodeEditor code={code} onChange={setCode}/>
        </div>

        <div className='output-container'>
        <Output code={code} className='output-container'/>
        </div>
      </div>
    </div>
  );
};

export default CodeMaster;
