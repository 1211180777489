import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './Confirm.scss';
import { TiTick } from "react-icons/ti";
import MyContext from '../../../../Uttis/MyContext';


const Confirm = () => {
  const [paymentStatus, setPaymentStatus] = useState('Verifying payment...');
  const [errorMessage, setErrorMessage] = useState(null);
  const [paid, setPaid] = useState(null);
  const [tid, setTid] = useState(null);
  const [amount, setAmount] = useState(null);
  const{url,navigate,store} = useContext(MyContext)
  
  const orderId = new URLSearchParams(window.location.search).get('orderId'); 

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const { data } = await axios.post(`${url}/verify-payment`, { 
          orderId,
          email: store.email, 
          name: store.name,    
          mobile: store.mobile}
        );

        if (data.success) {
          setPaymentStatus('Confirmation Successful!');
         setAmount(data.amount)
         setTid(data.transactionId)
         setPaid(data.paymentMethod)
          
        } else {
          setPaymentStatus('Payment failed.');
          setErrorMessage(data.message || 'Failed to verify payment.');
          // You can also choose to redirect to a failure page or show an error message
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setPaymentStatus('An error occurred while verifying the payment.');
        setErrorMessage(error.response?.data?.error || error.message);
      }
    };

    if (orderId) {
      verifyPayment();
    } else {
      setPaymentStatus('Order ID not found.');
    }
  }, [orderId, navigate,url]);



return (
 
 
      <div className="confirmation-page">
       {paymentStatus && paymentStatus==='Confirmation Successful!' && <span><TiTick /></span>}
        <div className='con-text'>
          <h1>{paymentStatus}</h1>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         {paymentStatus && paymentStatus==='Confirmation Successful!' &&
        <>
        <p>Transaction Id:{tid}</p>
        <p>Total paid:{amount/100}  By {paid}</p>
        <p>Thank you for Enroll. Your Registration has been received For Further Details call on 9664768292.</p>

        </>
         }
        </div>
      </div>
 
);
}



export default Confirm;
