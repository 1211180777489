

const CssJson = [

  {
    "id": 1,


    "html_title": "intro",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "What is CSS?",
       
      
        "bootstrap_title_desc": `-- CSS stands for Cascading Style Sheets
-- CSS describes how HTML elements are to be displayed on screen, paper,
 or in other media
-- CSS saves a lot of work. It can control the layout of multiple
 web pages all at once`,
 copy:true



      },

      {
        "bootstrap_title_heading": `Basic Syntex:`,

        "bootstrap_title_desc": `p {
color: red;
text-align: center;
}`,
copy:true
      },

      {




        "bootstrap_title_heading": `Syntext Explained:`,

        "bootstrap_title_desc":`-- p is a selector in CSS (it points to the HTML element you want to style: <p>).
-- color is a property, and red is the property value
-- text-align is a property, and center is the property value`,
copy:true


      }

     
    ],

   

    "coursename": "Css Intro (day-1)",


  },



  {
    "id": 2,


    "html_title": "selectors",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Element Selector",
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
p {
text-align: center;
color: red;
} 
</style>
</head>
<body>

<p>Every paragraph will be affected by the style.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Class Selector",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
.center1 {
text-align: center;
color: blue;
}

.center {
text-align: center;
color: red;
}
</style>
</head>
<body>

<p class="center1">Red and center-aligned paragraph.</p>
<p class="center">Red and center-aligned paragraph.</p> 

</body>
</html>`,

      },

  {
    "bootstrap_title_heading": "Universal Selector",

"bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
* {
text-align: center;
color: blue;
}
</style>
</head>
<body>

<h1>Hello world!</h1>

<p>Every element on the page will be affected by the style.</p>
<p>And me!</p>

</body>
</html>`,

  },

  {
    "bootstrap_title_heading": "Grouping Selector",
   
  
    "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
h1, h2, p {
text-align: center;
color: red;
}
</style>
</head>
<body>

<h1>Hello World!</h1>
<h2>Smaller heading!</h2>
<p>This is a paragraph.</p>

</body>
</html>`,

  },

  {
    "bootstrap_title_heading": "Practice Test",
 
    "bootstrap_title_desc": `Question:
Create 7 boxes with a height of 150px and width of 150px.
Use a class selector to change the background color of the boxes
and give a background color to the body. Inside each box, use an
<h3> tag to mention the name of the box (e.g., "Box 1", "Box 2", etc.).
The text color for "Box 2", "Box 3", "Box 4", and "Box 6" should be the
same, using a grouping selector.`,
copy:true

  },


    
    ],












    "coursename": "Selectors",


  },



  {
    "id": 3,


    "html_title": "css-types",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Css Types",
     
        "bootstrap_title_desc": `Three Ways to Insert CSS
There are three ways of inserting a style sheet:

1) External CSS
2) Internal CSS
3) Inline CSS`,
copy:true
      },

      {
        "bootstrap_title_heading": "Inline Css",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<h1 style="color:blue;text-align:center;">This is a heading</h1>
<p style="color:red;">This is a paragraph.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Internal Css",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
body {
  background-color: linen;
}

h1 {
  color: maroon;
  margin-left: 40px;
} 
</style>
</head>
<body>

<h1>This is a heading</h1>
<p>This is a paragraph.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "External Css",
       
        

        
        
        "bootstrap_title_desc": `"mystyle.css"

body {
  background-color: lightblue;
}

h1 {
  color: navy;
  margin-left: 20px;
}


<!DOCTYPE html>
<html>
<head>
<link rel="stylesheet" href="mystyle.css">
</head>
<body>

<h1>This is a heading</h1>
<p>This is a paragraph.</p>

</body>
</html>

`,

copy:true


      },

    


    
    ],












    "coursename": "Css Type",


  },




  {
    "id": 4,


    "html_title": "backgrounds (day-2)",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Background Color and Opacity",
       
          
           

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
div {
  background-color: green;
}

.first {
  opacity: 0.1;
}

.second {
  opacity: 0.3;
}

.third {
  opacity: 0.6;
}
</style>
</head>
<body>


<div class="first">
  <h1>opacity 0.1</h1>
</div>

<div class="second">
  <h1>opacity 0.3</h1>
</div>

<div class="third">
  <h1>opacity 0.6</h1>
</div>

<div>
  <h1>opacity 1 (default)</h1>
</div>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Background Images",
     
        "bootstrap_title_desc": `<!--background-image
background-repeat
background-attachment
background-position-->


<!DOCTYPE html>
<html>
<head>
<style>
body {
  background: url("https://m.media-amazon.com/images/I/71vFtHisg9L._UY879_.jpg")
no-repeat right top;
  
}
</style>
</head>
<body>


</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Border",
       
       

        
        
        "bootstrap_title_desc": `dotted - Defines a dotted border
dashed - Defines a dashed border
solid - Defines a solid border
double - Defines a double border
groove - Defines a 3D grooved border. The effect depends on the border-color value
ridge - Defines a 3D ridged border. The effect depends on the border-color value
inset - Defines a 3D inset border. The effect depends on the border-color value
outset - Defines a 3D outset border. The effect depends on the border-color value
none - Defines no border
hidden - Defines a hidden border`,
copy:true

      },

      {
        "bootstrap_title_heading": "Border style",
       
          
           

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
.dotted {border:1px dotted black;}
.dashed {border:1px dashed black;}
.solid {border:1px solid black;}
.double {border:1px double black;}
.groove {border:1px groove black;}
.ridge {border:1px ridge black;}
.inset {border:1px inset black;}
.outset {border:1px outset black;}
.none {border:1px none black;}
.hidden {border:1px hidden black;}
.mix {border:1px dotted dashed solid double;}
</style>
</head>
<body>


<p class="dotted">A dotted border.</p>
<p class="dashed">A dashed border.</p>
<p class="solid">A solid border.</p>
<p class="double">A double border.</p>
<p class="groove">A groove border.</p>
<p class="ridge">A ridge border.</p>
<p class="inset">An inset border.</p>
<p class="outset">An outset border.</p>
<p class="none">No border.</p>
<p class="hidden">A hidden border.</p>
<p class="mix">A mixed border.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Practice Task",
       
       

        
        
        "bootstrap_title_desc": `Background Color
Question:

Change the background color of a <div> element to lightblue.
Also, set the text color of the <div> to navy.


Background Image
Question:

Set a background image for a <div> element.
Make sure the background image covers the entire <div>


Border
Question:

Add a solid border to a <div> element.
The border should be 2px thick and red in color.`,

copy:true


      },

      

    

    
    ],












    "coursename": "Backgrounds (day-2)",


  },




  {
    "id": 5,


    "html_title": "margin-padding",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "What Is Margin",

        "bootstrap_title_desc": `The CSS margin properties are used to create space around elements,
outside of any defined borders.

margin-top
margin-right
margin-bottom
margin-left`,

copy:true
      },

      {
        "bootstrap_title_heading": "Example - Margin",
       
           
            

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
div {
border: 1px solid black;
margin-top: 100px;
margin-bottom: 100px;
margin-right: 150px;
margin-left: 80px;
background-color: lightblue;
}
</style>
</head>
<body>


<div>This div element has a top margin of 100px, a right 
margin of 150px, a bottom margin of 100px, and a left margin
of 80px.</div>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Padding",
       
   
        "bootstrap_title_desc": `The CSS padding properties are used to generate space 
around an element's content, inside of any defined borders.

padding-top
padding-right
padding-bottom
padding-left

Note: Negative values are not allowed.`,
copy:true

      },

      {
        "bootstrap_title_heading": "Padding - Example",
       

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
div {
border: 1px solid black;
background-color: lightblue;
padding-top: 50px;
padding-right: 30px;
padding-bottom: 50px;
padding-left: 80px;
}
</style>
</head>
<body>

<div>This div element has a top padding of 50px, a
right padding of 30px, a bottom padding of 50px,
and a left padding of 80px.</div>

</body>
</html>`,


      },

     

      

    

    
    ],












    "coursename": "Margin & Padding",


  },





  {
    "id": 6,


    "html_title": "min-width-hinght",


    "bootstrap_head_desc": [

      {
        "bootstrap_title_heading": "Height And Width",
       
       

        
        
        "bootstrap_title_desc": `CSS height and width Values
The height and width properties may have the following values:
1)auto - This is default. The browser calculates the height and width
2)fit content - content will be cover
3)max and min`,

copy:true


      },

      {
        "bootstrap_title_heading": "max and min",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
ul{
background-color: aquamarine;
/* min-width: 20px; */
max-width: 10px;
/* width: auto; */
/* width: fit-content; */
}
</style>
<body>
<ul>
<li>list1</li>
<li>list2</li>
<li>list3</li>
<li>list4</li>
<li>list5</li>
<li>list6</li>
</ul>
</body>
</html>`,


      },

     

      {
        "bootstrap_title_heading": "Practice Test",
       
        

        
        
        "bootstrap_title_desc": `Max-Width
Question:

Set a maximum width of 500px for a <div> element.
Ensure the element does not grow larger than 500px even if the content is wider.
Add some padding and a border for better visualization.


Min-Width
Question:

Set a minimum width of 200px for a <div> element.
Ensure the element does not shrink smaller than 200px even if the content is narrower.
Add some padding and a border for better visualization.`,
        copy:true


      },

     

      

    

    
    ],












    "coursename": "Min & Max Height Width",


  },






  {
    "id": 7,


    "html_title": "outline",


    "bootstrap_head_desc": [

      {
        "bootstrap_title_heading": "Outlines",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
p {
border: 2px solid black;
outline: #4CAF50 solid 10px; 
padding: 20px;
text-align: center;
outline-offset: 1px;
}
</style>
</head>
<body>

<p>This element has a 2px black border and a green outline with a width of 10px.</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Text Decoration",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
.underline {
  text-decoration: underline;
}

.overline {
  text-decoration: overline;
}

.line-through {
  text-decoration: line-through;
}
</style>
</head>
<body>

<p class="underline">This text should be underlined.</p>

<p class="overline">This text should have an overline.</p>

<p class="line-through">This text should have a line through it.</p>

</body>
</html>`,


      },

     
    ],


    "coursename": "Outlines (day-3)",


  },


   {
    "id": 8,


    "html_title": "text-formating",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Text Transformation",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
.uppercase {
text-transform: uppercase;
}

.lowercase {
text-transform: lowercase;
}

.capitalize {
text-transform: capitalize;
}
</style>
</head>
<body>


<p class="uppercase">This text is transformed to uppercase.</p>
<p class="lowercase">This text is transformed to lowercase.</p>
<p class="capitalize">This text is capitalized.</p>

</body>
</html>`,


      },

      
      {
        "bootstrap_title_heading": "Font Style",
       
       
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
p {
text-indent: 50px;
letter-spacing: 5px;
line-height: 1.8;
word-spacing: 10px;
white-space: nowrap;
text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;
font-size:20px ;
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-weight: 400;
}
</style>
</head>
<body>



<p>In my younger and more vulnerable years my father
gave me some advice that I've been turning over in my mind ever since.
'Whenever you feel like criticizing anyone,' he told me, 'just remember
that all the people in this world haven't had the advantages that you've had.'</p>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Practice Test",
       
        "bootstrap_title_desc": `Question:

Apply different text decorations to multiple paragraphs
to showcase various styles: underline, overline, line-through, and none.

Question:

Align text to the left, right, center, and justify different paragraphs.


Question:

Transform text to uppercase, lowercase, and capitalize different paragraphs.


Question:

Apply different text shadows to paragraphs to create various shadow effects.


Question:

Apply different font styles such as italic, normal, bold, and light to paragraphs.`,

copy:true
      },

      

     

     

      

    

    
    ],












    "coursename": "Text-Formating",


  },

  {
    "id": 10,


    "html_title": "addtional-stles",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Icon Style",
       
     
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<title>Bootstrap Icons</title>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" 
href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
</head>
<style>

i{
font-size:48px;color:red;
}
</style>
<body>

<i class="glyphicon glyphicon-cloud"></i>

</body>
</html>`,


      },


      {
        "bootstrap_title_heading": "Links",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
a {
text-decoration: none;
}

</style>
</head>
<body>


<a href="https://v-extechsolution.in" target="_blank">This is a link</a>


</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "Cursor Style",
       
     
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>

<span style="cursor:auto">auto</span><br>
<span style="cursor:crosshair">crosshair</span><br>
<span style="cursor:default">default</span><br>
<span style="cursor:e-resize">e-resize</span><br>
<span style="cursor:help">help</span><br>
<span style="cursor:move">move</span><br>
<span style="cursor:n-resize">n-resize</span><br>
<span style="cursor:ne-resize">ne-resize</span><br>
<span style="cursor:nw-resize">nw-resize</span><br>
<span style="cursor:pointer">pointer</span><br>
<span style="cursor:progress">progress</span><br>
<span style="cursor:s-resize">s-resize</span><br>
<span style="cursor:se-resize">se-resize</span><br>
<span style="cursor:sw-resize">sw-resize</span><br>
<span style="cursor:text">text</span><br>
<span style="cursor:w-resize">w-resize</span><br>
<span style="cursor:wait">wait</span><br>

</body>
</html>
        
        
        `,

      },

      {
        "bootstrap_title_heading": "List style",

        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
ul{
list-style: none;
}

</style>
</head>
<body>

<ul>
<li>Coffee</li>
<li>Tea</li>
<li>Coca Cola</li>
</ul>


</body>
</html>`,


      },


    ],


    "coursename": "Additional Styles (day-4)",


  },





  {
    "id": 11,


    "html_title": "table-style",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Table Border",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 1px solid;
  border-collapse: collapse;
  text-align: center;
}
</style>
</head>
<body>

<h2>Add a border to a table:</h2>

<table>
<tr>
<th>Firstname</th>
<th>Lastname</th>
</tr>
<tr>
<td>Peter</td>
<td>Griffin</td>
</tr>
<tr>
<td>Lois</td>
<td>Griffin</td>
</tr>
</table>

</body>
</html>`,


      },

      {
        "bootstrap_title_heading": "practice task",
       
       

        
        
        "bootstrap_title_desc": `Question:

Create a table with 3 columns and 4 rows.
Apply border styling to the table, table rows, and table cells.
Center align the text in the header row.

Question:

Create a table with 4 columns and 5 rows.
Apply border styling to the table, table rows, and table cells.
Apply alternate row colors for better readability.`,
copy:true


      },

    
    ],












    "coursename": "Table Styles",


  },



  {
    "id": 12,


    "html_title": "nav-bar",


    "bootstrap_head_desc": [

    


      {
        "bootstrap_title_heading": "Header",
       
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
<style>
* {
    margin: 0;
}

.header {
    background-color: black;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: center;
    list-style: none;
    line-height: 40px;
}

.left {
    display: flex;
    gap: 20px;
}

.right {
    display: flex;
    gap: 20px;
}
</style>
</head>
<body>
<div class="header">
    <div class="left">
        <li>service</li>
        <li>contact</li>
        <li>about</li>
    </div>
    <div class="center">Logo Name</div>
    <div class="right">
        <li>icon1</li>
        <li>icon2</li>
        <li>icon3</li>
    </div>
</div>
</body>
</html>
`,


      },

    
    

     




     

      
    
      

     

     

      

    

    
    ],












    "coursename": "Navigation Bar",


  },

  {
    "id": 13,


    "html_title": "display",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "none and hidden",
  
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
h1 {
    display: none;
   {/* visibility: hidden; */}
}
</style>
</head>
<body>

<h1>This is a visible heading</h1>
<h1>This is a hidden heading</h1>
<p>Notice that the h1 element with display: none; does not take up any space.</p>

</body>
</html>
`,


    },

    
    {
      "bootstrap_title_heading": "Potition fixed",
  
      "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Fixed Position Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: black;
    color: white;
    padding: 10px 0;
    text-align: center;
    z-index: 1000;
}

.content {
    padding: 80px 20px;
}

p {
    margin-bottom: 20px;
}
</style>
</head>
<body>

<div class="header">
    Fixed Header
</div>

<div class="content">
    <p>This is some content below the fixed header. Scroll down to see the fixed header staying in place.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Mauris ante ligula, facilisis sed ornare eu, lobortis in odio. Praesent convallis urna a lacus interdum ut hendrerit risus congue. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Mauris ante ligula, facilisis sed ornare eu, lobortis in odio. Praesent convallis urna a lacus interdum ut hendrerit risus congue. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Mauris ante ligula, facilisis sed ornare eu, lobortis in odio. Praesent convallis urna a lacus interdum ut hendrerit risus congue. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</p>
</div>

</body>
</html>
`,


  },

  {
    "bootstrap_title_heading": "Potition Sticky",

    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Sticky Position Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.header {
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 24px;
}

.sticky {
    position: sticky;
    top: 0;
    background-color: #ff6347; 
    padding: 10px;
    font-size: 20px;
    z-index: 1000;
}

.content {
    padding: 20px;
}

.section {
    margin-bottom: 100px;
}

p {
    margin-bottom: 20px;
}
</style>
</head>
<body>

<div class="header">
    Sticky Position Example
</div>

<div class="content">
    <div class="sticky">
        I will stick to the top of the page when you scroll past me.
    </div>

    <div class="section">
        <h2>Section 1</h2>
        <p>This is some content in section 1. Scroll down to see the sticky element in action.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa.</p>
    </div>

    <div class="section">
        <h2>Section 2</h2>
        <p>This is some content in section 2. Keep scrolling to see the sticky element remain in place.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa.</p>
    </div>

    <div class="section">
        <h2>Section 3</h2>
        <p>This is some content in section 3. Notice how the sticky element is still visible at the top.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa.</p>
    </div>
</div>

</body>
</html>
`,


},


    
  {
    "bootstrap_title_heading": "Potition Absolute And Relative",
   
   
    

    
    
    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Relative and Absolute Positioning Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    position: relative;
    width: 300px;
    height: 300px;
    background-color: #f0f0f0;
    margin: 50px auto;
    border: 2px solid #333;
}

.box {
    position: absolute;
    top: 80px;
    left: 50px;
    width: 100px;
    height: 100px;
    background-color: #ff6347;
}


</style>
</head>
<body>

<div class="container">
    <div class="box">I am absolutely positioned</div>
</div>



</body>
</html>
`,


},






 




 

  

      

     

     

      

    

    
    ],



    "coursename": "Display (day-5)",


  },




  {
    "id": 14,


    "html_title": "z-index-overflow",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Z-Index",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>z-index Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    position: relative;
    width: 300px;
    height: 300px;
    background-color: #f0f0f0;
    margin: 50px auto;
    border: 2px solid #333;
}

.box1 {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 100px;
    height: 100px;
    background-color: #ff6347;
    z-index: 1;
}

.box2 {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 100px;
    height: 100px;
    background-color: #4682b4;
    z-index: 2;
}

.box3 {
    position: absolute;
    top: 150px;
    left: 150px;
    width: 100px;
    height: 100px;
    background-color: #32cd32;
    z-index: 3;
}
</style>
</head>
<body>

<div class="container">
    <div class="box1">Box 1</div>
    <div class="box2">Box 2</div>
    <div class="box3">Box 3</div>
</div>

</body>
</html>
`,


},


{
  "bootstrap_title_heading": "overflow",
 

 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Overflow Property Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}



.overflow-visible {
    width: 100%;
    height: 100px;
    background-color: #ff6347;
    overflow: visible;
    margin-bottom: 20px;
}

.overflow-hidden {
    width: 100%;
    height: 100px;
    background-color: #4682b4;
    overflow: hidden;
    margin-bottom: 20px;
}

.overflow-scroll {
    width: 100%;
    height: 100px;
    background-color: #32cd32;
    overflow: scroll;
    margin-bottom: 20px;
}

.overflow-auto {
    width: 100%;
    height: 100px;
    background-color: #dda0dd;
    overflow: auto;
}
</style>
</head>
<body>


    <div class="overflow-visible">
        This is a box with overflow: visible. is a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleContent that overflows visible. Content that overflowsvisible. Content that overflowsthe box will be visible. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet.
    </div>
    <div class="overflow-hidden">
        This is a box with overflow: hidden. Content that overflows the box will be hidden. Lorem ipsum dolor sit amet, consecteturvisible. Content that overflowsvisible. Content that overflows adipiscing elit. Phasellus impis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleerdiet.
    </div>
    <div class="overflow-scroll">
        This is a box withis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visible overflow: svisible. Content that overflowsvisible. Content that overflowscroll. Content that overflows the box will be scrollable. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet.
    </div>
    <div class="overflow-auto">
        This is a box with overflow: auto. Content that overfvisible. Content that overflowsvisible. Content that overflowslows the box will have scrollbaris a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibleis a box with overflow: visibles if needed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet.
    </div>


</body>
</html>
`,


},
    

    ],












    "coursename": "Z-Index & Overflow",


  },

 
 



  {
    "id": 16,


    "html_title": "sudo-class",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Hover Block",
       
       
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
h1{
background-color: blue;
width: fit-content;
}

h1:hover p{
display: block;
}

p{
display: none;
}
</style>
<body>
<div class="main">


<h1>hover

<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque fugiat rerum dolores est, eveniet aut quia neque sed deserunt quae qui asperiores architecto fuga quisquam repellendus officiis fugit consequuntur possimus.</p>

</h1>
</div>
</body>
</html>`,


},

{
  "bootstrap_title_heading": "nth child",
 

 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
.box{
height: 150px;
width: 150px;
background-color: blue;
}

.box:nth-child(1){
background-color: aquamarine;

}

.box:nth-child(2){
background-color: rgb(255, 127, 148);

}
</style>
<body>
<div class="box">box1</div>
<div class="box">box2</div>
<div class="box">box3</div>
<div class="box">box4</div>
</body>
</html>`,


},



  

    
    ],












    "coursename": "sudo-class (day-6)",


  },


  {
    "id": 17,


    "html_title": "sudo-element",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "before after",
       
       
        

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>

::selection {
  
background-color: #00ff11; /* Change to your desired background color */
}

p::first-line{
color: brown;
}

p::first-letter{
font-size: 44px;
}

p::before{
content: "welcome";
}

p::after{
content: "welcome";
}
</style>
</head>
<body>

<p>You can use the ::first-line pseudo-element to add a special effect to the first line of a text. Some more text. And even more, and more, and more, and more, and more, and more, and more, and more, and more, and more, and more, and more.</p>

</body>
</html>`,


},


{
  "bootstrap_title_heading": "Dropdown hover",
 
// 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<style>
.sub-header{
background-color: crimson;
color: white;
list-style: none;
display: flex;
justify-content: space-around;
line-height: 50px;
font-size: 18px;
}
  
li{
padding: 0 22px;
cursor: pointer;
}
  
li:hover{
background-color: white;
color: black;
}
  
*{
margin: 0;
}
  
.banner{
background-image: url("../images/banner.png");
          height: 400px;
}
  
.content{
position: absolute;
background-color: white;
color: black;
display: none;
          
         
  
}
  
.content > li:hover{
background-color: red;
color: white;
}
  
.hover{
position: relative;
  
  

}
  
.hover:hover .content{
display: block;
}
</style>
<body>

<div class="sub-header">
<div class="hover">
<li>list1</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list2</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list3</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list4</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list5</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list6</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>
<div class="hover">
<li>list7</li>
<div class="content">
<li>home1</li>
<li>home2</li>
<li>home3</li>
<li>home4</li>
</div>
</div>

</div>

<div class="banner"></div>
</body>
</html>`,


},







  






 




 

  

  

     

     

      

    

    
    ],












    "coursename": "Sudo-Element",


  },


  {
    "id": 18,


    "html_title": "form-styling",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Input style",
       
      
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
        
<style>
input{
/* background-color: rgba(2, 2, 11, 0.918); */
/* color: white; */
outline: 0;

border: none;
border-bottom: 1px solid brown;
}
        
        
input::placeholder{
color: chartreuse;
}

input[type=text]:focus {
background-color: lightblue;
}
        
h1{
color: green;
color: blue;
}
</style>
<body>
<form>
name:<input type="text" placeholder="name">
</form>
        
<h1>hi</h1>
</body>
</html>`,


},

 
    ],




    "coursename": "form-style (day-7)",


  },


  {
    "id": 19,


    "html_title": "css-power-calculation",


    "bootstrap_head_desc": [

      {
        "bootstrap_title_heading": "Style Power",
"bootstrap_title_desc":`<html>
<head>
<style>
.test {color: green;}
p {color: red;}
</style>
</head>
<body>
 
<p class="test" style="color: pink;">Hello World!</p>
 
</body>
</html>`,   
      },
      
      
      {
        "bootstrap_title_heading": "Style Power Data",

        "bootstrap_title_desc": `element selector - 1
class selector - 10
inline <p style="color: blue;"> - 1000
*(the universal selector is ignored) - 0

important
p {
background-color: red !important;
}`,
copy:true
      
      
      },

      {
        "bootstrap_title_heading": "calculation",
       
  
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
p {

width: calc(100% - 100px);
border: 1px solid black;
background-color: yellow;
padding: 5px;
}
</style>
</head>
<body>
        
        
<p>Some text...</p>

</body>
</html>`,


},


 
    ],


    "coursename": "Style Power & Calc",


  },


  {
    "id": 20,


    "html_title": "Css Project",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "Project",
 
        "bootstrap_title_desc": `1. Basic Styling
Question:

Create a webpage with a heading, a paragraph, and a link.
Style the heading to be bold and centered, the paragraph to have
a font size of 18px, and the link to be blue with no underline.


2. Box Model
Question:

Create a box with a width of 200px, height of 200px, 
a red border, 10px padding, and 20px margin. Center
the box horizontally on the page.


3. Flexbox
Question:

Create a container with three boxes inside it.
 Use Flexbox to arrange the boxes in a row, equally spaced apart.


 4. Pseudo-classes and Pseudo-elements
Question:

Style a list of items. Use the :hover
pseudo-class to change the background 
color of each item when hovered, and 
use the ::before pseudo-element to add
 a bullet before each item.


5. Forms and Inputs
Question:

Create a form with styled inputs for name, email,
and a submit button. Style the inputs to have a
border, padding, and change color when focused.


6. Backgrounds and Borders
Question:

Create a box with a background image, a solid border, and rounded corners.


7. Custom Fonts and Icons
Question:

Use a custom font from Google Fonts and include an icon
from Font Awesome in your webpage. Style the text and icon appropriately.
`,
copy:true


},

    
    ],

    "coursename": "Css Project",


  },


  {
    "id": 21,


    "html_title": "css-interview-question",


    "bootstrap_head_desc": [

    

      {
        "bootstrap_title_heading": "1) What is CSS?",
       
       

        
        
        "bootstrap_title_desc": `CSS stands for Cascading Style Sheet. It is a popular styling 
language which is used with HTML to design websites.`,
copy:true


},

{
  "bootstrap_title_heading": "2) What is a CSS selector?",
 
 

  
  
  "bootstrap_title_desc": `It is a string that identifies the elements 
to which a particular declaration apply. It is also referred as a
link between the HTML document and the style sheet. It is equivalent 
of HTML elements. There are several different types of selectors in CSS: -

CSS Element Selector
CSS Id Selector
CSS Class Selector
CSS Universal Selector
CSS Group Selector`,
copy:true


},


{
  "bootstrap_title_heading": "3)What is the difference between class selectors and id selectors?",
 
 

  
  
  "bootstrap_title_desc": `An overall block is given to class selector
while id selectors take only a single element differing from other elements.

CSS Class Selector
<style>    
.center {    
text-align: center;    
color: blue;    
}    
</style>  
     
CSS Id Selector
<style>    
#para1 {    
text-align: center;    
color: blue;    
}    
</style> `,
copy:true


},


{
  "bootstrap_title_heading": "4)What are the advantages of External Style Sheets?",
 
 

  
  
  "bootstrap_title_desc": `You can create classes for reusing it in many documents.
By using it, you can control the styles of multiple documents from one file.
In complex situations, you can use selectors and grouping methods to apply styles.`,
copy:true


},


{
  "bootstrap_title_heading": "5)What is RWD?",
 
 

  
  
  "bootstrap_title_desc": `RWD stands for Responsive Web Design. This technique is
used to display the designed page perfectly on every screen size and device, for example,
mobile, tablet, desktop and laptop. You don't need to create a different page for each device.`,
copy:true


},



{
  "bootstrap_title_heading": "6)What is the float property of CSS?",
 
 

  
  
  "bootstrap_title_desc": `The CSS float property is used to move the image to the
right or left along with the texts to be wrapped around it. It doesn't change
the property of the elements used before it.`,
copy:true


},


{
  "bootstrap_title_heading": "7)What is the purpose of the z-index and how is it used?",
 
 

  
  
  "bootstrap_title_desc": `The z-index helps to specify the stack order of
positioned elements that may overlap one another. The z-index default
value is zero and can take on either a positive or negative number.`,
copy:true


},

{
  "bootstrap_title_heading": "8)What is the purpose of the z-index and how is it used?",
 
 

  
  
  "bootstrap_title_desc": `The z-index helps to specify the stack order of
positioned elements that may overlap one another. The z-index default
value is zero and can take on either a positive or negative number.`,
copy:true


},

{
  "bootstrap_title_heading": "9)Explain the difference between visibility: hidden and display: none?",
 
 

  
  
  "bootstrap_title_desc": ` <!DOCTYPE html>  
<html>  
<head>  
<style>  
h1.vis {  
visibility: visible;  
}  
    
h1.hid {  
visibility: hidden;  
}  
</style>  
</head>  
body>  
<h1 class="vis">It is visible</h1>  
<h1 class="hid">It is hidden</h1>  
    
<p>Note - Second heading is hidden, but it still occupy space.</p>  
</body>  
</html> 


display: none also hides the element but not occupy space.
It will not affect the layout of the document.

<!DOCTYPE html>  
<html>  
<head>  
<style>  
h1.vis {  
    display: block;  
}  
  
h1.hid {  
     display: none;  
}  
</style>  
</head>  
<body>  
<h1 class="vis">It is visible</h1>  
<h1 class="hid">It is hidden</h1>  
  
<p>Note - Second heading is hidden and not occupy space.</p>  
</body>  
</html> `,
copy:true


},

{
  "bootstrap_title_heading": "10)What are the various positioning properties in CSS?",
 
 

  
  
  "bootstrap_title_desc": `The position property in CSS tells about the
method of positioning for an element or an HTML entity. 
There are five different types of position properties available in CSS:

Fixed
Static
Relative
Absolute
Sticky`,
copy:true


},


{
  "bootstrap_title_heading": "11)What are CSS Combinators?",
 
 

  
  
  "bootstrap_title_desc": `CSS combinators are explaining the relationship
between two selectors. CSS selectors are the patterns used to select
the elements for style purposes. A CSS selector can be a simple selector
or a complex selector consisting of more than one selector connected using combinators.
There are four types of combinators available in CSS which are discussed below:
  
General Sibling selector (~)
Adjacent Sibling selector (+)
Child selector (>)
Descendant selector (space)`,
copy:true


},


{
  "bootstrap_title_heading": "12)Can we add 2D transformations to our project using CSS?",
 
 

  
  
  "bootstrap_title_desc": `Yes, we can, a transformation modifies an element by its shape, size, and position. It transforms the elements along the X-axis and Y-axis.
There are six main types of 2D transformations which are listed below:
  
translate()
rotate()
scale()
skewX()
skewY()
matrix()`,
copy:true


},


{
  "bootstrap_title_heading": "13)How can we make our website responsive using CSS?",
 
 

  
  
  "bootstrap_title_desc": `Media query is used to create a responsive web design.
It means that the view of a web page differs from system to system based
on screen or media types.`,
copy:true


},


{
  "bootstrap_title_heading": "14)What is CSS flexbox?",
 
 

  
  
  "bootstrap_title_desc": `It is also called a flexible box model. 
It is basically a layout model that provides an easy and clean way
to arrange items within a container. Flexbox is different from the block 
model which is vertically biased and the inline which is horizontally biased.
Flexbox was created for small-scale layouts and there’s another standard 
called grids which are geared more towards larger-scale layouts, It works
similar to the way to Twitter bootstrap grid system works. Flexbox 
is responsive and mobile-friendly. To start with flexbox firstly 
create a flex container. To create a flex container set the display property to flex.

Syntax:
  
.main-container {
display: flex;
}
Flex Properties:
  
flex-direction
flex-wrap
flex-flow
justify-content
align-items
align-content`,
copy:true


},


{
  "bootstrap_title_heading": "15)What is CSS Grid?",
 
 

  
  
  "bootstrap_title_desc": `It is a CSS property that offers a grid-based layout system,
with rows and columns,making it easier to design web pages without floats and positioning.`,
copy:true


},

{
  "bootstrap_title_heading": "16)What is the difference between flexbox and grid?",
 
 

  
  
  "bootstrap_title_desc": `Flexbox offers greater control over alignment and space distribution between items.
Being one-dimensional, Flexbox only deals with either columns or rows.
The grid has two-dimension layout capabilities which allow flexible widths
as a unit of length. This compensates for the limitations in Flex.`,
copy:true


},














  






 




 

  

  

     

     

      

    

    
    ],


    "coursename": "Css Short Question",


  },










]
export default CssJson