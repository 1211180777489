import React from 'react'
import "./Slider.scss"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Typewriter from "typewriter-effect";

import Slider1 from "../../../../assets/Circular_Carousel-img/slider1.png"
import Slider3 from "../../../../assets/Circular_Carousel-img/slider3.png"
import Slider5 from "../../../../assets/Circular_Carousel-img/slider5.png"

const Slider = () => {
  return (


      
 

   

  
            <div className="layout-s">

               
    
      


            <Carousel
  additionalTransfrom={0}
  arrows={false}
  className="left"
  autoPlay={true}
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover={false}
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={true}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=""
  slidesToSlide={1}
  removeArrowOnDeviceType={["tablet", "mobile"]}
  swipeable={true}
>

  <img
    src={Slider1}
    
  />

  <img
    src={Slider3}
   
  />

  <img
    src={Slider5}
    
  />
</Carousel>
                
               
                <div className="right">
    
               
                <span className="price">  
                <Typewriter
                options={{
                  strings: ['V-Ex Tech Solution '],
                  autoStart: true,
                  loop: true,
                  deleteSpeed:"10",
                  startSpeed:"5",
                  
                }}
              
   
  
  /></span>
           
                <span className="desc">
                V-Ex Tech is a software development company that provides IT training and internship programs. We help students develop the skills they need to get jobs in the software industry. V-Ex Tech, a software training institute and software company in Vadodara, Gujarat, India, has been helping students develop the skills they need to get jobs in the software industry.V-Ex Tech offers IT training and internship programs in a variety of software technologies.
  
                
                </span>
               
    
                
    
               
    
    
                
            
    
                
            </div>
    
            
        </div>

  

    



)
}

export default Slider