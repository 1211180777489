import { Alert, Snackbar } from '@mui/material';
import React, { useContext } from 'react';
import MyContext from '../../Uttis/MyContext';

const Success = () => {
    const { snack, message, setSnack } = useContext(MyContext);
    const match = ['Thanks', 'Attendance'];
    const severity = match.find(word => message.includes(word)) ? 'success' : 'error';

    return (
        <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={() => setSnack(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setSnack(false)}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Success;
