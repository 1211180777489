const Proj = [
    {
      "userid":1,
      "card_img":require("../../../assets/Project/car.png"),
      "overlay_text": `Building a car booking website involves frontend development, backend development, database management, and integration with third-party services.`,
      "project_title": "Smart Cab Point",
      "project_desc":"Cab Booking Software - React JS, Node Js, Mongo Db, Express js",
      "project_link":"https://smartcabpoint.vhxview.com/"
    },

    {
        "userid":2,
        "card_img":require("../../../assets/Project/cafe.png"),
        "overlay_text": `The technologies used could include React for the frontend, Node.js or Django for the backend, and a database like MongoDB or MySQL.`,
        "project_title": "Smile Cafe Point",
        "project_desc":"Food Ordering Software-React JS, NodeJs,Mongo Db, Express js",
        "project_link":"https://smilecafepoint.vhxview.com/"
      },

      {
        "userid":3,
        "card_img":require("../../../assets/Project/vxstore.png"),
        "overlay_text": `Build a E-Commerce Website , With Dynamic Pages, Api Calling,States and more`,
        "project_title": "Zephyr",
        "project_desc":"E-Commerce Site - React JS, Node Js, Mongo Db, Express js",
        "project_link":"https://zephyr.v-extechsolution.in/"
      },
    
  
  
  
  
    
  ]
  
  export default Proj;