import React, { useContext } from 'react';
import './Register.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from '../../Uttis/MyContext';
import { Checkbox, FormControlLabel, FormHelperText, Tooltip } from '@mui/material';

const Register = () => {

  const {setLoading,setMessage,setSnack,loading} = useContext(MyContext)



  const handleSubmit = async (values, {resetForm }) => {
    
    setLoading(true)
     document.querySelector('body').style.overflow='hidden'
    
        try {
       const response =   await fetch('https://bottom-seven.vercel.app/upload', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
            'Content-type': 'application/json',
            },
            });
    
            const data = await response.json()
            if(data.success){
              setSnack(true)
              setMessage(data.message)
          
              resetForm();
            }else{
              setSnack(true)
              setMessage(data.error)
           
            }
            setLoading(false)
              document.querySelector('body').style.overflow='auto'
        
        
        } catch (error) {
          console.error('Error uploading file:', error);
          setLoading(false);
        }
      
      };
    

  const formik = useFormik({
    initialValues: {
      name: '',
    mobile: '',
    dob:'',
    email:'',
    permanentadd:'',
    presentadd:'',
    pincode:'',
    institutename:'',
    education:'',
    currentstatus:'',
    techopted:'',
    duration:'',
    fees:'',
    pendingfees:'',
    referedby:'',
    photo:'',
    aadhar:'',
    terms:false
    },

    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      mobile: yup.string().required('Mobile Number is required').matches(/^[0-9]+$/, 'Mobile Number must be numeric').min(10, 'Mobile Number must be at least 10 digits'),
      email: yup.string().required('email id is required'),
      permanentadd: yup.string().required('permanentadd is required'),
      presentadd:yup.string().required('presentadd is required'),
      pincode: yup.string().required('Pincode is required'),
      institutename:yup.string().required('institute is required'),
      education:yup.string().required('education is required'),
      currentstatus:yup.string().required('current Status is required'),
      techopted:yup.string().required('Technology is required'),
      duration:yup.string().required('Duration is required'),
      dob:yup.string().required('dob is required'),
      fees:yup.string().required('fees is required').matches(/^[0-9]+$/, 'Mobile Number must be numeric'),    
      pendingfees:yup.string().required('pending fees is required').matches(/^[0-9]+$/, 'Mobile Number must be numeric'),   
      referedby:yup.string().required('fees is required'),
      photo:yup.string().required('Photo is required'),
      aadhar:yup.string().required('Aadhar card is required'),
      terms: yup.boolean().oneOf([true], 'You must accept the Terms and Conditions')
  
    }),

    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, { resetForm });
    },
  });

  return (
    <div className='attenp'>
      <div className='atten-form'>
        <form onSubmit={formik.handleSubmit}>
     
<h1>register Here</h1>
        
        <Tooltip title="Enter First Name + middle Name + Last Name" placement="top" arrow>
          <TextField
            id='name'
            name='name'
            label='name'
            size='small'
            value={formik.values.name || ''}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
         
          />
          </Tooltip>

          <Tooltip title="Enter Mobile Number" placement="top" arrow>
          <TextField
            id='mobile'
            name='mobile'
            label='mobile'
            size='small'
            value={formik.values.mobile || ''}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
         
          />
          </Tooltip>

          <Tooltip title="Enter date of birth" placement="top" arrow>
          <TextField
            id='dob'
            name='dob'
            label='dob'
            type="date"
            size='small'
            value={formik.values.dob || ''}
            onChange={formik.handleChange}
            error={formik.touched.dob && Boolean(formik.errors.dob)}
            helperText={formik.touched.dob && formik.errors.dob}
         
          />
          </Tooltip>

          <Tooltip title="Enter email id" placement="top" arrow>
          <TextField
            id='email'
            name='email'
            label='email'
            size='small'
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
         
          />
          </Tooltip>

          <Tooltip title="Enter permanentadd" placement="top" arrow>
          <TextField
            id='permanentadd'
            name='permanentadd'
            label='permanentadd'
            multiline
            rows={3}
            size='small'
            value={formik.values.permanentadd || ''}
            onChange={formik.handleChange}
            error={formik.touched.permanentadd && Boolean(formik.errors.permanentadd)}
            helperText={formik.touched.permanentadd && formik.errors.permanentadd}
         
          />
          </Tooltip>

          <Tooltip title="Enter presentadd" placement="top" arrow>
          <TextField
            id='presentadd'
            name='presentadd'
            multiline
            rows={3}
            label='presentadd'
            size='small'
            value={formik.values.presentadd || ''}
            onChange={formik.handleChange}
            error={formik.touched.presentadd && Boolean(formik.errors.presentadd)}
            helperText={formik.touched.presentadd && formik.errors.presentadd}
         
          />
          </Tooltip>

          <Tooltip title="Enter pincode" placement="top" arrow>
          <TextField
            id='pincode'
            name='pincode'
            label='pincode'
            size='small'
            value={formik.values.pincode || ''}
            onChange={formik.handleChange}
            error={formik.touched.pincode && Boolean(formik.errors.pincode)}
            helperText={formik.touched.pincode && formik.errors.pincode}
         
          />
          </Tooltip>

          <Tooltip title="Enter institutename" placement="top" arrow>
          <TextField
            id='institutename'
            name='institutename'
            label='institutename'
            size='small'
            value={formik.values.institutename || ''}
            onChange={formik.handleChange}
            error={formik.touched.institutename && Boolean(formik.errors.institutename)}
            helperText={formik.touched.institutename && formik.errors.institutename}
         
          />
          </Tooltip>

          <Tooltip title="Enter education" placement="top" arrow>
          <TextField
            id='education'
            name='education'
            label='education'
            size='small'
            value={formik.values.education || ''}
            onChange={formik.handleChange}
            error={formik.touched.education && Boolean(formik.errors.education)}
            helperText={formik.touched.education && formik.errors.education}
         
          />
          </Tooltip>

          <Tooltip title="Enter currentstatus" placement="top" arrow>
          <TextField
            id='currentstatus'
            name='currentstatus'
            label='currentstatus'
            size='small'
            value={formik.values.currentstatus || ''}
            onChange={formik.handleChange}
            error={formik.touched.currentstatus && Boolean(formik.errors.currentstatus)}
            helperText={formik.touched.currentstatus && formik.errors.currentstatus}
         
          />
          </Tooltip>

          <Tooltip title="Enter techopted" placement="top" arrow>
          <TextField
            id='techopted'
            name='techopted'
            label='techopted'
            size='small'
            value={formik.values.techopted || ''}
            onChange={formik.handleChange}
            error={formik.touched.techopted && Boolean(formik.errors.techopted)}
            helperText={formik.touched.techopted && formik.errors.techopted}
         
          />
          </Tooltip>

          <Tooltip title="Enter duration" placement="top" arrow>
          <TextField
            id='duration'
            name='duration'
            label='duration'
            size='small'
            value={formik.values.duration || ''}
            onChange={formik.handleChange}
            error={formik.touched.duration && Boolean(formik.errors.duration)}
            helperText={formik.touched.duration && formik.errors.duration}
         
          />
          </Tooltip>

          <Tooltip title="Enter total fees" placement="top" arrow>
          <TextField
            id='fees'
            name='fees'
            label='Total fees'
            size='small'
            value={formik.values.fees || ''}
            onChange={formik.handleChange}
            error={formik.touched.fees && Boolean(formik.errors.fees)}
            helperText={formik.touched.fees && formik.errors.fees}
         
          />
          </Tooltip>

          <Tooltip title="Enter  pendingfees" placement="top" arrow>
          <TextField
            id='pendingfees'
            name='pendingfees'
            label='Total pendingfees'
            size='small'
            value={formik.values.pendingfees || ''}
            onChange={formik.handleChange}
            error={formik.touched.pendingfees && Boolean(formik.errors.pendingfees)}
            helperText={formik.touched.pendingfees && formik.errors.pendingfees}
         
          />
          </Tooltip>

          <Tooltip title="Enter  referedby" placement="top" arrow>
          <TextField
            id='referedby'
            name='referedby'
            label='referedby'
            size='small'
            value={formik.values.referedby || ''}
            onChange={formik.handleChange}
            error={formik.touched.referedby && Boolean(formik.errors.referedby)}
            helperText={formik.touched.referedby && formik.errors.referedby}
         
          />
          </Tooltip>

          <Tooltip title="Enter  google frive link for passport size photo" placement="top" arrow>
          <TextField
            id='photo'
            name='photo'
            label='google drive link for photo'
            size='small'
            value={formik.values.photo || ''}
            onChange={formik.handleChange}
            error={formik.touched.photo && Boolean(formik.errors.photo)}
            helperText={formik.touched.photo && formik.errors.photo}
         
          />
          </Tooltip>

          <Tooltip title="Enter google drive link for aadhar" placement="top" arrow>
          <TextField
            id='aadhar'
            name='aadhar'
            label='enter google drive link for aadhar'
            size='small'
            value={formik.values.aadhar || ''}
            onChange={formik.handleChange}
            error={formik.touched.aadhar && Boolean(formik.errors.aadhar)}
            helperText={formik.touched.aadhar && formik.errors.aadhar}
         
          />
          </Tooltip>


          <Tooltip title="Accept terms and conditions" placement="top" arrow>
          <FormControlLabel
            control={
              <Checkbox
                id='terms'
                name='terms'
                checked={formik.values.terms}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="Accept Terms and Conditions"
          />
        </Tooltip>
        {formik.touched.terms && formik.errors.terms && (
          <FormHelperText error>{formik.errors.terms}</FormHelperText>
        )}
     

          <Button
            variant='contained'
            className='SM'
            type='submit'
         
          >
            {loading ? 'Sending...' : 'Submit'} 
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Register;
