import React, {useContext } from 'react';
import './AttendanceForm.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MyContext from '../../Uttis/MyContext';

const AttendanceForm = () => {
  const {loading,setLoading,setSnack,setMessage} = useContext(MyContext)
  const currentDate = new Date().toISOString().split('T')[0];
 

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      document.querySelector('body').style.overflow='hidden'
      const response = await fetch('https://bottom-seven.vercel.app/', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = await response.json();

      if (!data.success) {
        setMessage(data.error);
        setSnack(true)
      } else {
        setMessage(data.message);
        setSnack(true)
        resetForm();
      }
    } catch (error) {
     alert(error.message)
    } finally {
      setLoading(false); 
       document.querySelector('body').style.overflow='auto'
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      date: '',
      work: '',
    },

    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      date: yup
        .date()
        .required('Date is required')
        .min(currentDate, 'Cannot fill for past dates')
        .max(currentDate, 'Cannot fill for future dates'),
      work: yup.string().required('Work is required').min(5),
    }),

    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, { resetForm });
    },
  });

  return (
    <div className='attenp'>
      <div className='atten-form'>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='name'
            name='name'
            label='Name'
            type='text'
            value={formik.values.name || ''}
            onChange={(e) => {
              const upperCaseValue = e.target.value.toUpperCase();
              formik.handleChange({ target: { name: 'name', value: upperCaseValue } });
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='standard'
          />

          <TextField
            id='date'
            name='date'
            type='date'
            value={formik.values.date || ''}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
            variant='standard'
          />

          <TextField
            id='work'
            name='work'
            label='Work /Reason*'
            multiline
            rows={4}
            value={formik.values.work || ''}
            onChange={formik.handleChange}
            error={formik.touched.work && Boolean(formik.errors.work)}
            helperText={formik.touched.work && formik.errors.work}
            variant='standard'
          />

          <Button
            variant='contained'
            className='SM'
            type='submit'
            disabled={loading}  
          
          >
            {loading ? 'sending...' : 'Submit'}  
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AttendanceForm;
