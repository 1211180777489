import React, { useContext, useEffect, useState } from 'react'
import "./Batch.scss"
import Rate from "../../assets/batch/rate.jpeg";
import MyContext from '../../Uttis/MyContext';

const Batch = () => {
  const{location} = useContext(MyContext)
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

  
  }, []);

  if (isVisible || location.pathname.match(/^\/(html|sql|css|css-advance|javascript|project|reactjs|vxeditor|express-js|excel|cyber-security|php)\//) || location.pathname === '/vxeditor') {
    return null;
  }

  return (
    <div className='fixed-batch' onClick={() => window.open('https://www.google.com/search?q=v+ex+tech&rlz=1C1CHBF_enIN1034IN1034&oq=v+ex+tech&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDM4NTRqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8#ip=1&lrd=0x395fc9e2c4382d89:0xc25c2a592f9acd4,1,,,,','_blank')}>
      <img src={Rate} alt="" />
      500+ Reviews
    </div>
  )
}

export default Batch;
