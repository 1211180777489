

const CssAJson = [

  {
    "id": 1,


    "html_title": "intro",


    "bootstrap_head_desc": [
      {
"bootstrap_title_heading": "Side Radius",
  
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Side Border Radius Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.container {
    width: 300px;
    height: 200px;
    background-color: #4682b4;
    border-top-left-radius: 100px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}
</style>
</head>
<body>

<div class="container">
    Box with Side Border Radius
</div>

</body>
</html>
`,


  },


    ],


    "coursename": "Side Radius (day-1)",


  },


  {
    "id": 2,


    "html_title": "css-gradient",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "linear-gradient",
       
     
       


        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Linear Gradient Examples</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    flex-direction: column;
    gap: 20px;
}

.box {
    width: 300px;
    height: 200px;
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
}


.gradient-top-bottom {
    background: linear-gradient(to bottom, #ff7e5f, #feb47b);
}


.gradient-left-right {
    background: linear-gradient(to right, #6a11cb, #2575fc);
}


.gradient-diagonal {
    background: linear-gradient(to bottom right, #43cea2, #185a9d);
}


.gradient-multi {
    background: linear-gradient(to right, #ff6a00, #ee0979, #f0c27b, #4b1248);
}
</style>
</head>
<body>

<div class="box gradient-top-bottom">
    Top to Bottom Gradient
</div>

<div class="box gradient-left-right">
    Left to Right Gradient
</div>

<div class="box gradient-diagonal">
    Diagonal Gradient
</div>

<div class="box gradient-multi">
    Multiple Colors Gradient
</div>

</body>
</html>
`,


  },



  {
    "bootstrap_title_heading": "radial-gradient",
   

    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Radial Gradient Examples</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    flex-direction: column;
    gap: 20px;
}

.box {
    width: 300px;
    height: 200px;
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
}


.gradient-default {
    background: radial-gradient(circle, #ff7e5f, #feb47b);
}


.gradient-ellipse {
    background: radial-gradient(ellipse at center, #6a11cb, #2575fc);
}


.gradient-radius {
    background: radial-gradient(circle closest-side, #43cea2, #185a9d);
}

.gradient-multi {
    background: radial-gradient(circle, #ff6a00 0%, #ee0979 50%, #f0c27b 100%);
}
</style>
</head>
<body>

<div class="box gradient-default">
    Default Radial Gradient
</div>

<div class="box gradient-ellipse">
    Elliptical Radial Gradient
</div>

<div class="box gradient-radius">
    Specific Radius Gradient
</div>

<div class="box gradient-multi">
    Multi-color Radial Gradient
</div>

</body>
</html>
`,


},

  
    ],


    "coursename": "Css Gradient",


  },


  {
    "id": 3,


    "html_title": "text-overflow-elipsis",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "ellipsis",
       
     
       


        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<style>
.main{
height: 50px;
width: 100px;
border: 1px solid black;

}

p{
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
</style>
<body>
<div class="main">
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
 Quis commodi, dicta modi vero fugit quos! Molestias beatae,
laboriosam et ullam ratione omnis magni incidunt saepe repellendus,
alias expedita tenetur sed?</p>
</div>
</body>
</html>`,


  },


    ],

    "coursename": "Text Elipsis (day-2)",


  },


  {
    "id": 4,


    "html_title": "transform-property",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Transform Property",

        
        "bootstrap_title_desc": `translate()
rotate()
scale()
skew()`,
copy:true


  },


  {
    "bootstrap_title_heading": "translate() Property",
 
    "bootstrap_title_desc": `<!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  </head>
  
  <style>
  
  h2{
  transition:transform 2s;
  background-color: yellow;
  height: 100px;
  width: 300px;
  }
  
  
  
  h2:hover{
  transform: translate(100px,200px);
  
  }
  </style>
  <body>
  
  
  <h2>s</h2>
  
  
  </body>
  </html>`,
  
  
  },

  {
    "bootstrap_title_heading": "rotate() Property",
   
 
   


    
    
    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>

h2{
transition:transform 2s;
background-color: yellow;
height: 100px;
width: 300px;
}

  

h2:hover{
transform: rotate(380deg);

}
</style>
<body>


<h2>s</h2>
 

</body>
</html>`,


},


{
  "bootstrap_title_heading": "scale() Property",

 

  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
div {
margin: 150px;
width: 200px;
height: 100px;
background-color: yellow;
border: 1px solid black;
transform: scale(2,3);
}
</style>
</head>
<body>

<div>
This div element is two times of its original width, and three times of its original height.
</div>

</body>
</html>
  
  
  `,


},


{
  "bootstrap_title_heading": "skew() Property",
 

 


  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
  <html>
  <head>
  <style>
  div {
    width: 300px;
    height: 100px;
    background-color: yellow;
    border: 1px solid black;
     transform: skew(20deg,10deg);
  }
  
  </style>
  </head>
  <body>
  
  
  <div>
  This div element is skewed 20 degrees along the X-axis, and 10 degrees along the Y-axis.
  </div>
  
  </body>
  </html>`,


},

{
  "bootstrap_title_heading": "all Property",
 

 


  
  
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Transform Examples</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 80%;
}

.box {
    width: 100px;
    height: 100px;
    background-color: #4682b4;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-align: center;
    position: relative;
    border-radius: 10px;
}

.rotate {
    transform: rotate(45deg);
}

.scale {
    transform: scale(1.5);
}

.translate {
    transform: translateX(50px) translateY(50px);
}

.skew {
    transform: skewX(20deg) skewY(10deg);
}

.perspective {
    transform: perspective(500px) rotateY(30deg) rotateX(20deg) translateZ(50px);
}
</style>
</head>
<body>

<div class="container">
    <div class="box rotate">Rotate</div>
    <div class="box scale">Scale</div>
    <div class="box translate">Translate</div>
    <div class="box skew">Skew</div>
    <div class="box perspective">Perspective</div>
</div>

</body>
</html>
`,


},


    
    ],



    "coursename": "Transform Property",


  },

  {
    "id": 5,


    "html_title": "animation-property",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Keyframes",
       
     
       
        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Rotate and Scale Animation</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.box {
    width: 150px;
    height: 150px;
    background-color: #ff6347;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    animation: rotateScale 4s infinite;
}

@keyframes rotateScale {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.5);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
</style>
</head>
<body>

<div class="box">Rotate & Scale</div>

</body>
</html>
`,


  },

    
    ],












    "coursename": "Animation Property",


  },

  {
    "id": 6,


    "html_title": "image-animation",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Image Animation",
       
     
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
.card{
width: 200px;
height: 250px;
background-color: brown;
}

.overlay{
height: 0;
background-color: blue;
width: 100%;
transition: height 1s;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
}

.card:hover .overlay{
height: 100%;
}
</style>
<body>
<div class="card">

<div class="overlay">

hi! buddy

</div>

</div>
</body>
</html>`,



  },

  {
    "bootstrap_title_heading": "Image Animation absolute",
   
     
       
    
    
    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>

<style>
.real-box{
height: 300px;
width: 250px;
background-color: blue;
position: relative;
 
}

.overlay{
position: absolute;
bottom: 0;
top: 0;
right: 0;
background-color: #008CBA;
overflow: hidden;
width:0 ;
height: 100%;
transition: .5s ease;

}

p{
padding: 8px;
}

.real-box:hover .overlay{
width: 100%;
}
</style>
<body>
<div class="real-box">

<div class="overlay">
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur iste consequatur nulla, nisi eos iusto sed id cum labore dolores nihil! Nostrum culpa in dicta earum possimus corrupti, harum itaque?</p>
</div>

</div>
</body>
</html>`,



},

  
    ],



    "coursename": "Image Hover (day-3)",


  },


  {
    "id": 7,


    "html_title": "media-query",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "responsive design",
       
     
    

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Media Query Example</title>
<style>
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.header, .main, .footer {
    width: 100%;
    max-width: 1200px;
    margin: 10px;
    padding: 20px;
    background-color: #f0f0f0;
    text-align: center;
}

.header {
    background-color: #4682b4;
    color: #ffffff;
}

.main {
    background-color: #ffffff;
}

.footer {
    background-color: #4682b4;
    color: #ffffff;
}

/* Media Query for devices with a max width of 768px */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .header, .main, .footer {
        padding: 15px;
    }
}

/* Media Query for devices with a max width of 480px */
@media (max-width: 480px) {
    .header, .main, .footer {
        padding: 10px;
        font-size: 14px;
    }

    .header {
        background-color: #ff6347;
    }

    .footer {
        background-color: #ff6347;
    }
}
</style>
</head>
<body>

<div class="container">
    <div class="header">Header</div>
    <div class="main">Main Content</div>
    <div class="footer">Footer</div>
</div>

</body>
</html>
`,



  },

  
    ],












    "coursename": "Media Query",


  },


  {
    "id": 8,


    "html_title": "display-grid",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Grid Layout",
       
     
       

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<head>
<style>
.grid-container {
display: grid;
height: 400px;
align-content: center;
grid-template-columns: auto auto auto;
gap: 10px;

padding: 10px;
}

.grid-container > div {
background-color: yellow;
text-align: center;
padding: 20px 0;
font-size: 30px;
}
</style>
</head>
<body>

<div class="grid-container">
<div>1</div>
<div>2</div>
<div>3</div>
<div>4</div>
<div>5</div>
<div>6</div>
</div>

</body>
</html>`,


  },

  {
    "bootstrap_title_heading": "Grid Start",
   
     
       

    
    
    "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
</head>
<style>

.box{

height: 200px;
width: 200px;
border: 1px solid black;
}

.main{
display: grid;
grid-template-columns: auto auto auto;
/* row-gap: 30px;
column-gap: 50px; */
/* gap: 50px; */
justify-content: center;
}

.box:nth-child(1){
grid-column-start: 1;
grid-column-end: 4;
width: 600px;
}

.box:nth-child(2){
grid-row-start: 2;
grid-row-end: 4;
height: 400px;
}

.box:nth-child(5){
grid-column-start: 2;
grid-column-end: 4;
width: 400px;
}

</style>

<body>
<div class="main">
<div class="box"></div>
<div class="box"></div>
<div class="box"></div>
<div class="box"></div>
<div class="box"></div>
 

</div>
</body>
</html>`,


},




























    ],


    "coursename": "Display Grid (day-4)",


  },


  {
    "id": 9,


    "html_title": "card-flip",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Flip Card",
    
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <title>Card Flip Example</title>
    <link rel="stylesheet" href="styles.css">
</head>
<style>
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.card {
    width: 200px;
    height: 300px;
    perspective: 1000px;
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.card-front {
    background-color:yellow;
}

.card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
}

</style>
<body>

<div class="card">
    <div class="card-inner">
        <div class="card-front">
            <h3>Front Side</h3>
            <p>This is the front of the card.</p>
        </div>
        <div class="card-back">
            <h3>Back Side</h3>
            <p>This is the back of the card with more details.</p>
        </div>
    </div>
</div>

</body>
</html>
`,


  },




    ],


    "coursename": "Card Flip",


  },

  {
    "id": 10,


    "html_title": "Project",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Project",
       
     
       

        
        
        "bootstrap_title_desc": `Question:

Create a button with a linear gradient background
that transitions from blue to green. Add a hover
effect that changes the gradient to go from red to yellow.

Question:

Create a box with a fixed width of 200px that contains
a long line of text. Use CSS to truncate the text
with an ellipsis if it overflows the box.


Question:

Create a square box and apply the following transformations on hover:
Rotate the box by 45 degrees.
Scale the box to 1.5 times its original size.
Translate the box 50px to the right.


Question:

Create a bouncing ball animation. The ball should
move vertically up and down, simulating a bounce effect.


Question:

Create a responsive layout with three boxes.
Use a media query to stack the boxes vertically
when the screen width is less than 600px.


Question:

Create a responsive grid layout with 3 columns and 2 rows.
Each grid item should have a background color. 
Use media queries to change the layout to a single 
column when the screen width is less than 500px.

Question:

Create a card flip effect on hover. The card
should have a front side that displays a title
and a back side that displays more information.
When the user hovers over the card, it should
flip to reveal the back side.
`,
copy:true


  },




    ],


    "coursename": "Project",


  },
















]
export default CssAJson