import { CssOutlined, HtmlOutlined, JavascriptOutlined } from '@mui/icons-material';
import { IoLogoReact } from 'react-icons/io5';
import { FaNodeJs } from 'react-icons/fa';
import React from 'react';
import "./Video.scss"
import { DiMongodb, DiPython } from 'react-icons/di';
import { SiExpress } from 'react-icons/si';

const Video = () => {
  return (
    <div className='sp-me'>
      
        <h2 >
          Tools For Development
        </h2>
        <div className='sp-main'>
        <div class="bub">
        <div class="bubble"> <HtmlOutlined/></div>
      </div>

      <div class="bub">
      <div class="bubble"> <CssOutlined/></div>
    </div>


  <div class="bub">
  <div class="bubble"> <JavascriptOutlined/></div>
</div>

<div class="bub">
<div class="bubble"> <IoLogoReact/></div>
</div>

<div class="bub">
<div class="bubble"><FaNodeJs/></div>
</div>

<div class="bub">
<div class="bubble"> <DiMongodb/></div>
</div>

<div class="bub">
<div class="bubble"><DiPython/></div>
</div>

<div class="bub">
<div class="bubble"> <SiExpress/></div>
</div>
       
      
        </div>
      
    </div>
  );
};

export default Video;
