const Product = [
  {
    "userid":1,
    "student":require("../../../assets/students/vishal.png"),
    "studentname": "Vishal Salvi",
    "title": "Mern Stack",
   
   
    
    
  
  },
  {
    "userid":2,
    "student":require("../../../assets/students/saurabh.png"),
    "studentname": "Saurabh",
    "title": "Full Stack",
   
   
  },

 
  {
    "userid":5,
    "student":require("../../../assets/students/kunalbhadane.png"),
    "studentname": "Kunal Bhadane",
    "title": "UI/Ux Design",

  },
  {
    "userid":6,
    "student":require("../../../assets/students/renu.png"),
    "studentname": "Renu Chauhan",
    "title": "Frontend",
   
  },
  {
    "userid":7,
    "student":require("../../../assets/students/sumit.png"),
    "studentname": "Sumit Vihol",
    "title": "Frontend"

  },

  {
    "userid":9,
    "student":require("../../../assets/students/nirmal.jpg"),
    "studentname": "Nirmal Selot",
    "title": "Full Stack",
 
   
  },
  {
    "userid":10,
    "student":require("../../../assets/students/sanjay.png"),
    "studentname": "Sanjay Bariya",
    "title": "Django",
  
  },



  {
    "userid":16,
    "student":require("../../../assets/students/om.png"),
    "studentname": "Om Kothari",
    "title": "Python",


    
  },


  {
    "userid":20,
    "student":require("../../../assets/students/meet.png"),
    "studentname": "Meet Bhatiya",
    "title": "Data Analysis",
   
  },
  {
    "userid":21,
    "student":require("../../../assets/students/ashwin.jpg"),
    "studentname": "Ashwin Singh",
    "title": "Full Stack",
  
  },
  {
    "userid":22,
    "student":require("../../../assets/students/dhruv_patel.png"),
    "studentname": "Dhruv Patel",
    "title": "Web Desiging",
  
  },

  {
    "userid":25,
    "student":require("../../../assets/students/dhruv.png"),
    "studentname": "Dhruv Patel",
    "title": "Mern Stack",
 
  },
  {
    "userid":26,
    "student":require("../../../assets/students/chintan.png"),
    "studentname": "Chintan Patel",
    "title": "Fulll Stack",
   
  },
 
  {
    "userid":28,
    "student":require("../../../assets/students/jayshree1.png"),
    "studentname": "Jayshree",
    "title": "Full Stack",


  },
  {
    "userid":29,
    "student":require("../../../assets/students/het.png"),
    "studentname": "Het Chunara",
    "title": "Mern Stack",
    
  },

  {
    "userid":31,
    "student":require("../../../assets/students/paresh.png"),
    "studentname": "Paresh Danger",
    "title": "Web Design",

  },


  {
    "userid":34,
    "student":require("../../../assets/students/rachana.png"),
    "studentname": "Rachana",
    "title": "Html & css",
   
  },
 
  {
    "userid":36,
    "student":require("../../../assets/students/krupa.png"),
    "studentname": "Krupa",
    "title": "React native",
   
  },

  {
    "userid":45,
    "student":require("../../../assets/students/mohit (1).png"),
    "studentname": "Mohit Hundlani",
    "title": "React Js",
 
   
  },
  {
    "userid":46,
    "student":require("../../../assets/students/shreyank.png"),
    "studentname": "Shreyank",
  "title":"c & C++"
   
   
  },
  {
    "userid":47,
    "student":require("../../../assets/students/jayesh.png"),
    "studentname": "Jayesh",
    title:'Ui/UX'
    
   
  },
  {
    "userid":48,
    "student":require("../../../assets/students/bhavisha.png"),
    "studentname": "Bhavisha",
    "title": "React JS",
   
   
  },
  {
    "userid":49,
    "student":require("../../../assets/students/asim.png"),
    "studentname": "Aseem Joshi",
    "title": "React Js",
    
   
  },
  {
    "userid":50,
    "student":require("../../../assets/students/sandhya.png"),
    "studentname": "Sandhya Patel",
    "title": "ReactJs",
   
  },
  {
    "userid":51,
    "student":require("../../../assets/students/DEVPANCHAL.png"),
    "studentname": "Dev Panchal",
    "title": "DataAnalysis",
   
   
  },
  {
    "userid":52,
    "student":require("../../../assets/students/Bakirbhai.png"),
    "studentname": "Bakirbhai",
    "title": "Full Stack",
   
  },
  {
    "userid":53,
    "student":require("../../../assets/students/sunil.png"),
    "studentname": "Sunil Hundlani",
    "title": "Amazon selling",
   
  },
  {
    "userid":54,
    "student":require("../../../assets/students/dhruv-maheta.png"),
    "studentname": "Dhruv Maheta",
    "title": "Full Stack",
    
   
  },
  {
    "userid":55,
    "student":require("../../../assets/students/JAY-HUDLANI.jpg"),
    "studentname": "Jay Hudlani",
    "title": "Amazon Selling",
   
   
  },

  {
    "userid":56,
    "student":require("../../../assets/students/rutika.png"),
    "studentname": "Rutika Solanki",
    "title": "Web Design",
   
  },

  {
    "userid":57,
    "student":require("../../../assets/students/anu.png"),
    "studentname": "anu Varghese",
    "title": "Full Stack",

   
  },



  {
    "userid":59,
    "student":require("../../../assets/students/shahil.png"),
    "studentname": "Shahil Pathan",
    "title": "Cyber-Security",
  
   
  },

  {
    "userid":60,
    "student":require("../../../assets/students/vats-patel.png"),
    "studentname": "Vats Patel",
    "title": "Cyber-Security",
  
   
  },

  {
    "userid":61,
    "student":require("../../../assets/students/divyang.png"),
    "studentname": "Divyang",
    "title": "Full Stack",
   
   
  },

  {
    "userid":62,
    "student":require("../../../assets/students/disha.png"),
    "studentname": "Disha Patel",
    "title": "Web Develop",
    
   
  },

  {
    "userid":63,
    "student":require("../../../assets/students/jayshree-parmar.png"),
    "studentname": "Jayshree",
    "title": "Web Develop",
  
   
  },
  {
    "userid":64,
    "student":require("../../../assets/students/gamit.png"),
    "studentname": "Jayvir Gamit",
    "title": "Web Develop",
  
   
  },

  {
    "userid":65,
    "student":require("../../../assets/students/khusboo.png"),
    "studentname": "Khushi Mishra",
    "title": "Full Stack",
   
   
  },

  {
    "userid":66,
    "student":require("../../../assets/students/pooja.png"),
    "studentname": "Pooja Rajput",
    "title": "Full Stack",
  
   
  },

  {
    "userid":67,
    "student":require("../../../assets/students/aaman.png"),
    "studentname": "Aaman",
    "title": "Full Stack",
   
   
  },

  {
    "userid":68,
    "student":require("../../../assets/students/darshan.png"),
    "studentname": "Darshan Makwana",
    "title": "Full Stack",
   
   
  },

  {
    "userid":69,
    "student":require("../../../assets/students/devanshi.png"),
    "studentname": "Devanshi Tadvi",
    "title": "Full Stack",
   
   
  },

  {
    "userid":70,
    "student":require("../../../assets/students/daksh.png"),
    "studentname": "Daksh Sheth",
    "title": "Full Stack",
   
   
  },

  {
    "userid":71,
    "student":require("../../../assets/students/vansh.png"),
    "studentname": "Vansh Sheth",
    "title": "Full Stack",
   
   
  },

  {
    "userid":72,
    "student":require("../../../assets/students/divyesh.png"),
    "studentname": "Divyesh Patel",
    "title": "Front-End",
  
   
  },

  {
    "userid":73,
    "student":require("../../../assets/students/swapnil.png"),
    "studentname": "Swapnil Patel",
    "title": "Front-End",
   
   
  },

  {
    "userid":74,
    "student":require("../../../assets/students/jalp.png"),
    "studentname": "Jalp Patel",
    "title": "Full Stack",
    
   
  },

  {
    "userid":75,
    "student":require("../../../assets/students/swar.png"),
    "studentname": "Swar Parikh",
    "title": "Data Analysis",
   
   
  },

  {
    "userid":76,
    "student":require("../../../assets/students/nandani.png"),
    "studentname": "Nandani Patel",
    "title": "Full Stack",
   
   
  },

  {
    "userid":76,
    "student":require("../../../assets/students/nidhi.png"),
    "studentname": "Nidhi Parmar",
    "title": "Ui/Ux",
   
   
  },

  {
    "userid":77,
    "student":require("../../../assets/students/kalpesh.png"),
    "studentname": "Kalpesh",
    "title": "React Js",
  
   
  },

  {
    "userid":78,
    "student":require("../../../assets/students/parth.png"),
    "studentname": "Parth Goswami",
    "title": "React JS",
  
   
  },

  {
    "userid":79,
    "student":require("../../../assets/students/jeel.png"),
    "studentname": "Jeel Patel",
    "title": "Data Analysis",
   
   
  },

  {
    "userid":80,
    "student":require("../../../assets/students/zankhna.png"),
    "studentname": "zankhna",
    "title": "React js",
  
   
  },

  {
    "userid":80,
    "student":require("../../../assets/students/mohit-prajapati.png"),
    "studentname": "mohit prajapati",
    "title": "React Native",
   
   
  },


  {
    "userid":81,
    "student":require("../../../assets/students/pavan.png"),
    "studentname": "pavan amin",
    "title": "Web Development",
  
   
  },

  {
    "userid":82,
    "student":require("../../../assets/students/suchir.png"),
    "studentname": "suchir Jolapara",
    "title": "App Development",

   
  },



  {
    "userid":84,
    "student":require("../../../assets/students/mohit-bajaj.png"),
    "studentname": "Mohit Bajaj",
    "title": "App Development",

   
  },

  {
    "userid":85,
    "student":require("../../../assets/students/darshit.png"),
    "studentname": "Darshit",
    "title": "Frontend",
   
   
  },

  {
    "userid":86,
    "student":require("../../../assets/students/rahul-parmar.png"),
    "studentname": "Rahul Parmar",
    "title": "React JS",
    
   
  },
  {
    "userid":87,
    "student":require("../../../assets/students/aastha.png"),
    "studentname": "Aastha Mehta",
    "title": "Full Stack",
  },

  {
    "userid":88,
    "student":require("../../../assets/students/sumona.png"),
    "studentname": "Sumana Mondal",
    "title": "Full Stack",
  },

  {
    "userid":89,
    "student":require("../../../assets/students/nilesh.png"),
    "studentname": "Nilesh",
    "title": "Full Stack",
  },

  {
    "userid":90,
    "student":require("../../../assets/students/prem.png"),
    "studentname": "Prem",
    "title": "Html Css",
  },

  {
    "userid":91,
    "student":require("../../../assets/students/kadari.png"),
    "studentname": "Kadri khushbu",
    "title": "Data Analysis",
  },

  {
    "userid":92,
    "student":require("../../../assets/students/aditya.png"),
    "studentname": "Aditya",
    "title": "web develop",
  },









  
]

export default Product;