import React, { useState } from 'react'
import './Contact.scss'
import { MdLocationOn } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import Mail from './Mail/Mail'

import Location from './Location/Location'
import Mobile from './Mobile/Mobile'
import { Helmet } from 'react-helmet'
import Office from './Office/Office'

const Form = () => {

    const[showMail,setShowMail] = useState(true)
    const[showContact,setShowContact] = useState(false)
    const[showLocation,setShowLocation] = useState(false)
    


    const handleMail= () => {
        setShowMail(true)
        setShowContact(false)
        setShowLocation(false)
        document.getElementById("mail").style.backgroundColor = "rgb(0, 174, 255) "
        document.getElementById("mail").style.borderRadius = "20px"
        document.getElementById("contact").style.backgroundColor = "transparent"
        document.getElementById("contact").style.borderRadius = "0px"
        document.getElementById("location").style.backgroundColor = "transparent"
        document.getElementById("location").style.borderRadius = "0px"
    }
    const handleContact= () => {
        setShowContact(true)
        setShowMail(false)
        setShowLocation(false)
        document.getElementById("contact").style.backgroundColor = "rgb(0, 174, 255) "
        document.getElementById("contact").style.borderRadius = "20px"
        document.getElementById("mail").style.backgroundColor = "transparent"
        document.getElementById("mail").style.borderRadius = "0px"
        document.getElementById("location").style.backgroundColor = "transparent"
        document.getElementById("location").style.borderRadius = "0px"
    }
    const handleLocation= () => {
        setShowLocation(true)
        setShowContact(false)
        setShowMail(false)
        document.getElementById("location").style.backgroundColor = "rgb(0, 174, 255) "
        document.getElementById("location").style.borderRadius = "20px"
        document.getElementById("contact").style.backgroundColor = "transparent"
        document.getElementById("contact").style.borderRadius = "0px"
        document.getElementById("mail").style.backgroundColor = "transparent"
        document.getElementById("mail").style.borderRadius = "0px"
    }


    return (
        <div className='contact-infi'>

<Helmet>
        <title>Best IT Company For Collage Internship In Vadodara</title>
        <meta name="title" content="Best IT Company For Collage Internship In Vadodara." />
        <meta name="description" content="live project on website development,android development,react native.certificate
        providing,nizampura" />
      </Helmet>

      <Office/>
        <div className='main-contact'>
            <div className='contact'>
                <div className='heading'>
                    <h1>Get in touch!</h1>
                    <span>Contact us for more information </span>
                </div>

                <div className='content' >
                    <div className='sub-content' onClick={handleMail}  id='mail'>
                    <center> <IoIosMail /> </center>
                        <div>Message</div>
                    </div>

                    <div className='sub-content' onClick={handleContact} id='contact'>
                       <center> <FaPhoneAlt /> </center>
                        <div>Contact</div>
                    </div>

                    <div className='sub-content' onClick={handleLocation} id='location'>
                    <center> <MdLocationOn /></center>
                        <div>Location</div>
                    </div>
                </div>

                <div className='page'>
                {showMail &&
                    <Mail />
                }

                {showContact &&

                    <Mobile/>
                }

                {showLocation &&

                    <Location/>
                }
                
                </div>
            </div>

            
        </div>

        </div>
    )
}

export default Form