import React, { useEffect, useState } from 'react'
import { BsCart4, BsFillHeartPulseFill } from 'react-icons/bs'
import { TbAffiliate, TbPigMoney } from "react-icons/tb"
import { RiLockPasswordLine } from 'react-icons/ri'
import { MdLocalOffer } from 'react-icons/md'
import './Industry.scss'

const Industry = () => {

    const[a,setA] = useState('#f9f9f9')
  
    const handles = () =>{
      if(window.scrollY>1900){
        setA('rgb(0, 174, 255)')
       
      }else{
        setA('#f9f9f9')
      }
    }
  
    useEffect(()=>{
  window.addEventListener('scroll',handles)
    },[])
    return (
        <div className='Indus-main'>

           
                <div className='Indus-heading'><h1>INDUSTRIES WE SERVE</h1></div>
                <div className='Indus-desc'>We work with some of the best e-commerce brands, helping them work smarter and grow faster.</div>

            

                <div className='Indus-element'>
                <div style={{backgroundColor:a}}><TbAffiliate /> <h3>Affiliates </h3></div>
                <div ><TbPigMoney /> <h3> Finance </h3></div>
                <div style={{backgroundColor:a}}><BsCart4 /> <h3> E-Commerce </h3></div>
                <div><BsFillHeartPulseFill />  <h3> Healthcare </h3></div>
                <div style={{backgroundColor:a}}><RiLockPasswordLine /> <h3> Online Security(SSL) </h3></div>
                <div> <MdLocalOffer /><h3> Wholesale </h3></div>
                </div>


              

          


        </div>
    )
}

export default Industry