

const ProjectJson = [

  



  {
    "id": 1,


    "html_title": "intro",


    "bootstrap_head_desc": [
      {
"bootstrap_title_heading": "header",

        
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<link rel="stylesheet" href="index.css">
<link rel="stylesheet" 
href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" 
integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
 crossorigin="anonymous" 
 referrerpolicy="no-referrer" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<style>

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}

.head-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2342de;
    color: white;
    padding: 10px 20px;
}

.left {
    display: flex;
    align-items: center;
    /* gap: 30px; */
    
}



.left span {
  padding: 0px 10px;
    cursor: pointer;
    border-right: 1px solid white;
}

.center h1 {
    margin: 0;
}

.right {
    display: flex;
    align-items: center;
}

.right-world {
    display: flex;
    align-items: center;
    margin-right: 20px;
    gap: 5px;
    padding: 0 5px;
    border-right: 1px solid white;
}

.right-world span {
    margin-right: 5px;
}

h3 {
    margin: 0;
    cursor: pointer;
}

.fa-solid,
.fa-brands {
    font-size: 1.5rem;
}
</style>
<body>
    <div class="head-main">

<div class="left">
    <span>follows on</span>

    <span><i class="fa-brands fa-facebook-f"></i></span>
    <span><i class="fa-brands fa-twitter"></i></span> 
    <span><i class="fa-brands fa-instagram"></i></span> 
</div>

<div class="center">
    <h1>Food CAfe</h1>
</div>

<div class="right">

    <div class="right-world">
<span><i class="fa-solid fa-earth-americas"></i></span> 
<span>English</span>
    </div>

    <h3>Login</h3>
    
</div>
    </div>
</body>
</html>`,

      },

  


    
    ],












    "coursename": "Web Design",


  },

  {
    "id": 2,


    "html_title": "img-text",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "IMAGE TEXT",
        
        
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>

  <style>

    *{
      margin: 0;
    }

    .main{
      display: flex;
      justify-content: center;
      background-color: aqua;
      padding: 10px;
      flex-flow: wrap;
      align-items: center;
      gap: 30px;
    }

    .image{
      height: 200px;
      width: 300px;
    }
    p{
      width: 80vh;
    }

    img{
      height: 100%;
      width: 100%;
    }
  </style>
  <div class="main">

    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
      Eaque amet iusto dolorem illo perspiciatis unde eum, corporis 
      doloribus repellendusculpa fugit. Odit culpa fugit et qui 
      voluptatum consectetur voluptates tenetur? Lorem ipsum dolor
       sit amet consectetur adipisicing elit. Optio error, totam
laborum eveniet dolore nisi, atque, eos ullam repudiandae
 quisquam earum nesciunt a iusto fugit eius tempore perspiciatis
  vitae neque?Lorem ipsum dolor sit amet consectetur adipisicing elit.
   Placeat hic pariatur quis illo voluptatem explicabo sequi quidem,
    accusantium rerum ratione labore eius quia tempore nam repellat, 
    aspernatur ab suscipit temporibus.</p>

    <div class="image">
      <img src="https://i.ibb.co/X5dJxN6/download.jpg" alt="">
    </div>
    
  </div>
</body>
</html>`,

      },

  


    
    ],


    "coursename": "Img-Text",


  },

  {
    "id": 3,


    "html_title": "button-disabled",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "disabled button",
        
        
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>

    Terms and conditions<input type="checkbox" id="termsCheckbox" onclick="toggleButton()">
    <button id="demo" disabled>Click</button>

    <script>
function toggleButton() {
 
    var button = document.getElementById("demo");

  
    button.disabled = !button.disabled
}
    </script>

</body>
</html>
`,
      },

  


    
    ],












    "coursename": "button disabled",


  },

  
  {
    "id": 4,


    "html_title": "edit-pdf",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "frontend",
        
        
      

        
        
        "bootstrap_title_desc": `import React, { useState } from 'react';
import axios from 'axios';

const FormComponent = () => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Send form data to the server
    try {
      const response = await axios.post('/api/fill-pdf', { name, title });

      // Download the filled PDF
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'filled_form.pdf';
      a.click();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <label>
Name:
<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </label>
      <br />
      <label>
Title:
<input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      </label>
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};

export default FormComponent;
`,
      },

      {
        "bootstrap_title_heading": "backend",
        
        
      

        
        
        "bootstrap_title_desc": `const express = require('express');
const bodyParser = require('body-parser');
const fs = require('fs');
const { PDFDocument } = require('pdf-lib');

const app = express();
const port = 3001;

app.use(bodyParser.json());

app.post('/api/fill-pdf', async (req, res) => {
  try {
    // Get form data from the request
    const { name, title } = req.body;

    // Load the existing PDF
    const pdfBytes = fs.readFileSync('path/to/your/template.pdf');
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const form = pdfDoc.getForm();

    // Fill in the PDF form fields
    form.getTextField('Name').setText(name);
    form.getTextField('Title').setText(title);

    // Save the filled PDF to a buffer
    const filledPdfBytes = await pdfDoc.save();

    // Send the filled PDF as the response
    res.setHeader('Content-Type', 'application/pdf');
    res.setHeader('Content-Disposition', 'attachment; filename=filled_form.pdf');
    res.send(filledPdfBytes);
  } catch (error) {
    console.error('Error filling PDF:', error);
    res.status(500).send('Internal Server Error');
  }
});

app.listen(port, () => {
  console.log('Server is running on http://localhost:$'{port}');
});
`,
      },

  


    
    ],












    "coursename": "Edit Pdf",


  },


  {
    "id": 5,


    "html_title": "text-undeline",


    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "index.html",
        
        
      

        
        
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Document</title>
<style>
   p {
margin-bottom: 20px;
font-size: 18px;
font-weight: 500;
text-transform: uppercase;
position: relative;
display: inline-block;
}

p::after {
content: "";
position: absolute;
top: 30px;
left: 50%;
transform: translateX(-50%);
width: 0;
height: 3px;
background-color: #8e2de2;
transition: width 0.3s ease-in-out;
}

p:hover::after {
width: 100%;
}
</style>
</head>
<body>
<p>vfvfv</p>
</body>
</html>
`,
  },



  



    ],












    "coursename": "Text Underline",


  },





 










]
export default ProjectJson