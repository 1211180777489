const Product = [

    {
        "id":1,
        "image": require('../../../assets/Servicepage-img/chat.png'),
        "title": "Discuss",
        "description": "In our meeting, we'll take a deep drive into your requirements and goals."

    },
    {
        "id":2,
        "image": require('../../../assets/Servicepage-img/design.jpg'),
        "title": "Design",
        "description": "we'll create a design that supports the web app's usability."

    },
    {
        "id":3,
        "image": require('../../../assets/Servicepage-img/developer.png'),
        "title": "Development",
        "description": "Let's code now! In this chapter build a Website."

    },
    {
        "id":4,
        "image": require('../../../assets/Servicepage-img/qa.jpeg'),
        "title": "QA-Testing",
        "description": "To confirm there are no bugs, all code Will Be Test."

    },
    {
        "id":5,
        "image": require('../../../assets/Servicepage-img/pencil.png'),
        "title": "Delivery",
        "description": "Finally, we deliver the product  On time and on budget."

    }
]

export default Product