import React from 'react'
import './Location.scss'

const Location = () => {
  return (
  <iframe className='page2-location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.512180901108!2d73.17795117496891!3d22.334281579662147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc9e2c4382d89%3A0xc25c2a592f9acd4!2sV-Ex%20Tech%20-%20IT%20Training%2FInternship%20%26Placement!5e0!3m2!1sen!2slt!4v1687504758567!5m2!1sen!2slt" 
     allowfullscreen="" style={{border:0}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 

    
  )
}

export default Location