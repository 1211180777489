


const Product = [
  {
    "id": 1,
    "main":"PHP Program",
    "student": require("../../../../assets/Course_Images/php.png"),
    "page_img":require("../../../../assets/Fullstack/PHP.png") ,
    "Coursename": "php-training-vadodara",
    "description": "PHP is a server scripting language, and a powerful tool for making dynamic and interactive Web pages PHP is a widely-used, free, and efficient alternative to competitors such as Microsoft's ASP.PHP is a recursive acronym for PHP: Hypertext Preprocessor. PHP is a server side scripting language that is embedded in HTML. It is used to manage dynamic content, databases, session tracking, even build entire e-commerce sites.",

   
  


  },
  {
    "id": 2,
    "main":"React Js Program",
    "student": require("../../../../assets/Course_Images/react.png"),
    "page_img":require("../../../../assets/Fullstack/reactjs.png") ,
    "Coursename": "reactjs-training-vadodara",
    "description": `The Best Guide to 
    Know What Is React Lesson 1 of 12By Chinmayee
     Deshpande Last updated on Feb 7, 202311416774 The 
     Best Guide to Know What Is ReactPreviousNext Table of Contents 
     What Is React?ReactJS HistoryWhy React?ReactJS KeysReactJS AdvantagesView 
     More The world can't live without mobile and web applications in this day and age.
      Everything is digitized, from booking cabs to ordering food to make bank transactions.
       Thanks to the efficient frameworks that provide a seamless user experience. One such robust
        frontend library is React. This tutorial on ‘what is React’ will help you understand the
         library's fundamentals and work with a simple demo. What Is React? React-Frontend_Language 
         React is a JavaScript-based UI development library. Facebook and an open-source developer 
         community run it. Although React is a library rather than a language, it is widely used in
          web development. The library first appeared in May 2013 and is now one of the most commonly
           used frontend libraries for web development. React offers various extensions for entire application
            architectural support, such as Flux and React Native, beyond mere UI.`,
            
           

   

  },
  {
    "id": 3,
    "main":"Python Program",
    "student": require("../../../../assets/Course_Images/python.png"),
    "page_img":require("../../../../assets/Fullstack/python.png") ,
    "Coursename": "python-training-vadodara",
    "description": "100% Job Oriented & Practical Python Training Course in Vadodara with Placement Assistance. 100% Job Oriented & Practical Python Training Course in Vadodara with Placement Assistance. Nowadays, python is one of the most used scripting language in the world. It is designed to be highly extensible and can be embedded in existing applications that require a programmable interface. So  , if you are planning to give your career graph a boost with Python training in Vadodara, Weltec can help you in  your success journey by its Python Course.",
 


  },
  {
    "id": 4,
    "main":"Node Js Program",
    "student": require("../../../../assets/Course_Images/nodejs.png"),
    "page_img":require("../../../../assets/Fullstack/nodejs.png") ,

    "Coursename": "nodejs-training-vadodara",
    "description": `Node.js is an open-source, cross-platform JavaScript runtime environment that allows developers to build server- side web apps using JavaScript.It has become increasingly popularin recent years because of its ability to handle large - scale web applications and its lightweight nature.In this course,you will learn the fundamentals of Node.js, including modules,event - driven programming, asynchronous programming, and the Node.js
    runtime environment.You will also learn how to use Node.js to build 
    web applications, create RESTful APIs, and work with databases like MongoDB.
     Throughout the course, you will have the opportunity to work on hands - on projects 
     that will help you gain practical experience with Node.js.You will also have access
      to a community of fellow learners and instructors who can provide support and guidance
       as you progress through the course.Whether you are from a Non - IT background, beginner
        or an experienced developer, this course will provide you with the skills and knowledge
         you need to build robust and scalable websites using Node.js.So, join us today and start 
         your journey to becoming a Node.js developer!`,



  },
  {
    "id": 5,
    "main":"Mongo Db Program",
    "student": require("../../../../assets/Course_Images/mongodb.png"),
    "page_img":require("../../../../assets/Fullstack/mongodb.jpg") ,
    "Coursename": "mongodb-training-vadodara",
    "description": `MongoDB is a flexible and scalable document-oriented NoSQL database that allows for efficient storage and retrieval of data. It is designed to handle large-scale web applications and provides a lightweight and efficient solution for managing data. In this course, you will learn the fundamentals of MongoDB and how to use it in conjunction with Node.js to build powerful web applications.<br/>
            Throughout the course, you will cover various topics including data modeling, querying and indexing, data aggregation, and working with the MongoDB database. You will gain hands-on experience by working on practical projects that will help you understand how to effectively use MongoDB in real-world scenarios.`,
            

  },
  {
    "id": 8,
    "main":"Machine Learning Program",
    "student": require("../../../../assets/Course_Images/machine-learning.png"),
    "page_img":require("../../../../assets/Fullstack/machine.jpg") ,
    "Coursename": "machine-learning-training-vadodara",
    "description": `Machine learning allows developers to build applications that can effectively solve complex problems by learning patterns and insights from data. It enables computers to make accurate predictions, recognize patterns, classify data, and provide intelligent recommendations.

    We at V-ex tech are delighted to offer one of the best machine learning training in Vadodara with in-depth course material and real-time scenarios for each topic along with its solutions. All our training classes are conducted by the highly qualified trainers and real-time experts.<br/>
    
    Our machine learninf training in Vadodara covers all the basic. `,

  },

  {
    "id": 10,
    "main":"Full Stack Development Program",
    "student": require("../../../../assets/Course_Images/fullstack.png"),
    "page_img":require("../../../../assets/Fullstack/fullstack-development.png") ,
    "Coursename": "full-stack-development-training-vadodara",
    "description": `Full-stack development is a comprehensive approach to building web applications that encompasses both the front-end (client-side) and back-end (server-side) components. A full-stack developer is skilled in working with various technologies and frameworks across the entire application stack.<br/>

    We at V-ex tech are delighted to offer one of the best Fullstack development training in Vadodara with in-depth course material and real-time scenarios for each topic along with its solutions. All our training classes are conducted by the highly qualified trainers and real-time experts.<br/>
    
    Our Fullstack development training in Vadodara covers all the basic and advanced concepts of Fullstack development including three-tier architecture, database connectivity, standard web controls, advanced controls, MVC architecture and Silverlight.`,

   
  },

  {
    "id": 12,
    "main":"SQL Program",
    "student": require("../../../../assets/Course_Images/MYSQL.png"),
    "page_img":require("../../../../assets/Fullstack/mysql.png"),
    "Coursename": "sql-database-training-vadodara",
    "description": `My sql allows developers to build robust applications that are inter operable among other platforms and within a shorter interval. It also provides excellent platform for both-administrators and developers by providing tool support, reliability, administration, scalability and security.

    We at v-ex tech are delighted to offer one of the best My sql training in Vadodara with in-depth course material and real-time scenarios for each topic along with its solutions. All our training classes are conducted by the highly qualified trainers and real-time experts.
    
    Our My sql training in Vadodara covers all the basic and advanced concepts of My sql including three-tier architecture, database connectivity, standard web controls, advanced controls, MVC architecture and Silverlight.`,

  },





  {
    "id": 17,
    "main":"React Native Program",
    "student": require("../../../../assets/Course_Images/Mobile-App.png"),
    "page_img":require("../../../../assets/Fullstack/mobile.jpg") ,
    "Coursename": "mobileapppDevelopment-training-vadodara",
    "description": `Mobile development refers to the process of creating software applications specifically designed to run on mobile devices, such as smartphones and tablets. It encompasses a wide range of platforms, including Android and iOS, and involves both native and cross-platform development approaches.

    Native Mobile Development focuses on building apps specifically for a single platform using the native programming languages and tools. For Android, Java and Kotlin are the primary languages, and Android Studio is the official IDE. iOS development, on the other hand, uses Swift and Objective-C, and Xcode is the official IDE. Native development allows developers to access the full capabilities of the device and platform-specific features, resulting in high-performance and optimized apps.`,
  },

  {
    "id": 19,
    "main":"Data Analysis Program",
    "student": require("../../../../assets/Course_Images/data-analytics.png"),
    "page_img":require("../../../../assets/Fullstack/data-analysis.png") ,
    "Coursename": "data-analyst-training-vadodara",
    "component": "DATA ANALYTICS",
    "description": `Data analytics is the process of examining and interpreting data to uncover meaningful patterns, insights, and trends. It involves using various statistical and computational techniques to analyze large datasets and extract valuable information that can guide decision-making and drive business strategies. Data analytics plays a crucial role across various domains, including business, finance, healthcare, marketing, and more.

    The first step in data analytics is data collection and preparation. This involves gathering data from different sources, such as databases, spreadsheets, APIs, or even social media platforms. The collected data may be in various formats and might require cleaning and preprocessing to remove errors, duplicates, or missing values, ensuring it is in a usable form for analysis.`,
  },



  {
    "id": 23,
    "main":"SEO Program",
    "student": require("../../../../assets/Course_Images/SEO.png"),
    "page_img":require("../../../../assets/Fullstack/seo.png") ,
    "Coursename": "seo-training-vadodara",

    "description": `Search Engine Optimization (SEO) is a set of strategies and techniques aimed at improving a website's visibility and ranking in search engine results. The primary goal of SEO is to increase organic (non-paid) traffic to a website by making it more relevant and appealing to search engines like Google, Bing, and Yahoo.

    One of the foundational aspects of SEO is keyword research. This involves identifying the relevant keywords and phrases that potential visitors might use when searching for content related to the website's topic or business. By targeting the right keywords, websites can optimize their content to match search queries and attract the right audience.`,
  },
  {
    "id": 24,
    "main":"Excel Program",
    "student": require("../../../../assets/Course_Images/Advance-Excel.png"),
    "page_img":require("../../../../assets/Fullstack/exel.png") ,
    "Coursename": "excel-training-vadodara",
    "description": `
    Microsoft Excel is a widely-used spreadsheet application that forms part of the Microsoft Office suite. It is designed to facilitate data organization, analysis, and visualization, making it a versatile tool for businesses, educators, researchers, and individuals alike.
    
    One of Excel's core features is its grid-like interface, consisting of rows and columns, where users can input and manipulate data. Excel allows users to perform basic arithmetic calculations as well as more complex mathematical functions, making it an excellent tool for numerical analysis and data processing.`,
  },
  {
    "id": 25,
    "main":"Ai Program",
    "student": require("../../../../assets/Course_Images/artificial-intellidence.png"),
    "page_img":require("../../../../assets/Fullstack/AI.avif") ,

    "Coursename": "ai",
    "description": `Artificial Intelligence (AI) is a branch of computer science that focuses on creating intelligent machines capable of performing tasks that typically require human intelligence. AI aims to develop systems that can learn, reason, problem-solve, and perceive their environment, much like humans do. It is a rapidly advancing field that has the potential to revolutionize various industries and aspects of our daily lives.

    One of the key concepts in AI is machine learning, which involves training algorithms to recognize patterns and make predictions based on data. Through iterative learning processes, AI systems can improve their performance and accuracy over time, making them more efficient and effective in their tasks.`,
  },

  {
    "id": 27,
    "main":"Wordpress Program",
    "student": require("../../../../assets/Course_Images/wordpress.png"),
    "page_img":require("../../../../assets/Fullstack/wordpress.webp") ,
    "Coursename": "wordpress-training-vadodara",
    "description": `WordPress is a popular and widely-used open-source content management system (CMS) that allows users to create and manage websites and blogs. It was first released in 2003 and has since evolved into a versatile platform used by millions of websites around the world. WordPress's user-friendly interface and extensive plugin ecosystem have made it a top choice for individuals, businesses, and organizations looking to establish an online presence quickly and easily.

    One of WordPress's key strengths is its simplicity and ease of use. Even users with limited technical knowledge can set up a website or blog without the need for coding skills. The platform offers a user-friendly dashboard where users can manage their content, themes, and plugins effortlessly. Additionally, its intuitive editor allows users to create and publish content in a straightforward manner.`,

  },
 
  {
    "id": 29,
    "main":"UI & UX Program",
    "student": require("../../../../assets/Course_Images/ui-design.png"),
    "page_img":require("../../../../assets/Fullstack/uiux.jpg") ,
    "Coursename": "ui-ux-training-vadodara",
    "description": `UI (User Interface) and UX (User Experience) are two essential aspects of the design process that aim to create user-friendly and visually appealing digital products, such as websites and mobile applications. While UI and UX are closely related, they serve distinct purposes and contribute to the overall success of a product in different ways.

    User Interface (UI) design focuses on the visual elements of a digital product, including layouts, color schemes, typography, icons, and other visual elements. The goal of UI design is to create an attractive and intuitive interface that enhances the user's interaction with the product. A well-designed UI ensures that users can easily navigate through the application, understand its functionalities, and perform tasks effortlessly.`,
  },
  {
    "id": 30,
    "main":"Web Design Program",
    "student": require("../../../../assets/Course_Images/web-design.png"),
    "page_img":require("../../../../assets/Fullstack/webD.jpg") ,
    "Coursename": "webdesign",
    "description": `
    Web design is the process of creating and designing websites that are visually appealing, user-friendly, and functional. It encompasses various disciplines, including graphic design, user interface (UI) design, user experience (UX) design, and web development. The goal of web design is to create a website that effectively communicates the brand's message, engages the target audience, and facilitates a seamless user experience.
    
    One of the key aspects of web design is visual aesthetics. The graphic design elements, such as layout, color schemes, typography, and images, play a crucial role in creating a visually attractive website. A well-designed website grabs the user's attention and communicates the brand's identity and values effectively.`,
  },



  {
    "id": 34,
    "main":"Mean Stack Program",
    "student": require("../../../../assets/Course_Images/mean-stack.png"),
    "page_img":require("../../../../assets/Fullstack/mernstack.png") ,
    "Coursename": "meanstack-training-vadodara",
    "description": `The MEAN stack is a popular and powerful web development framework used to build dynamic and scalable web applications. The MEAN stack is an acronym that stands for MongoDB, Express.js, AngularJS (now replaced with Angular), and Node.js. Each component of the stack serves a specific purpose, and together they form a comprehensive and efficient development environment.

    MongoDB is a NoSQL database that stores data in a flexible and scalable JSON-like format. It is particularly well-suited for handling large amounts of unstructured or semi-structured data and provides high-performance querying and indexing capabilities. MongoDB's schema-less nature allows developers to make changes to the data structure easily, making it highly adaptable to evolving application requirements.
    
    `,
  },

]

export default Product;