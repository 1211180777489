import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'
import { useLocation, useNavigate } from 'react-router-dom';


const MyContextProvider = ({children}) => {

  const location=useLocation()

  let navigate = useNavigate();

  const[snack,setSnack] = useState(false)

  const[loading,setLoading] = useState(false)

  const [showSearch, setShowSearch] = useState(false)

  const openSearch = () => {
    setShowSearch(true);
    document.querySelector('body').style.overflow='hidden'
  };

  const closeSearch = () => {
    setShowSearch(false);
     document.querySelector('body').style.overflow='auto'
  };


 const [showLeft, setShowLeft] = useState(false)
 const openLeft = () => {
  setShowLeft(true);
  document.querySelector('body').style.overflow='hidden'
};

const closeLeft = () => {
  setShowLeft(false);
   document.querySelector('body').style.overflow='auto'
};

const [showRight, setShowRight] = useState(false)

const openMenu = () => {
  setShowRight(true);
  document.querySelector('body').style.overflow='hidden'
};

const closeMenu = () => {
  setShowRight(false);
   document.querySelector('body').style.overflow='auto'
};
   
  const [code, setCode] = useState(localStorage.getItem('editedCode') || '<h1>Hello, world!</h1>');
  const handleSendMessage = (title) => {
    const phoneNumber = '9664768292'; // Replace with your WhatsApp number
    const message = `hi, I want to join ${title},let me how can I join...`;

    // Construct the WhatsApp API link
    const apiLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    // Open the link in a new tab
    window.open(apiLink, '_blank');
  };
  // Update local storage whenever the code is changed
  useEffect(() => {
    localStorage.setItem('editedCode', code);
  }, [code]);





 


   const[message,setMessage] = useState('')
   const [join, setJoin] = useState(false);

   const [store, setStore] = useState(() => {

    const sessionData = sessionStorage.getItem('formData');
    return sessionData ? JSON.parse(sessionData) : { name: '', email: '', mobile: '' };
  });

 


const url = process.env.REACT_APP_API_URL || 'http://localhost:9000'



  return (
    <MyContext.Provider value={{store,setStore,url,join,setJoin,openMenu,closeMenu,showLeft,openLeft,closeLeft, setShowLeft, navigate,openSearch,closeSearch,showSearch,setShowSearch,location,snack,setSnack,message,setMessage,loading,setLoading,setShowRight,showRight,code,setCode,handleSendMessage}} >
      {children}
    </MyContext.Provider>
  )
}

export default MyContextProvider