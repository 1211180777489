

const ReactNativeJson = [

{
"id": 1,


"html_title": "intro",


"bootstrap_head_desc": [
 

{
"bootstrap_title_heading": "Installation",

"bootstrap_title_desc": `Install Node.js and npm

Ensure that you have Node.js and npm (Node Package Manager) 
installed on your machine. You can download them from here-https://nodejs.org/en/download`

},

{
  "bootstrap_title_heading": "create Package",
"bootstrap_title_desc": `Install React Native App

Open your terminal or command prompt and run the following
command to install Create React App globally:

--npx create-expo-app AwesomeProject

Navigate to Your Project

--cd AwesomeProject

-- to start: npx expo start

-- download expo app on mobile device 
and scan the qr code on your device,
qr code available on terminal`
}

],



"coursename": "Intro",


},

{
"id": 2,


"html_title": "react-native-view",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "What Is View?",





"bootstrap_title_desc": `View is a container that supports 
layout with flexbox, style, some touch handling, 
and accessibility controls. View maps directly to 
the native view equivalent on whatever platform React
 Native is running on, whether that is a UIView,
<div>, android.view, etc.`,

},


{
"bootstrap_title_heading": "App.js",

"bootstrap_title_desc": `import React from 'react';
import {View} from 'react-native';

const App = () => {
return (
<View
style={{
flexDirection: 'row',
height: 100,
padding: 20,
}}>
<View style={{backgroundColor: 'blue', flex: 0.3}} />
<View style={{backgroundColor: 'red', flex: 0.5}} />

</View>
);
};

export default App;`,

},

 





],












"coursename": "RN-View",


},

{
"id": 3,


"html_title": "react-native-text",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js",





"bootstrap_title_desc": `import React, {useState} from 'react';
import {Text, StyleSheet} from 'react-native';

const App = () => {
const [titleText, setTitleText] = useState("Bird's Nest");
const bodyText = 'This is not really a bird nest.';

const onPressTitle = () => {
setTitleText("Bird's Nest [pressed]");
};

return (
<View style={styles.baseText}>
<Text style={styles.titleText} onPress={onPressTitle}>
{titleText}
 
<br/><br/>
</Text>
<Text numberOfLines={5}>{bodyText}</Text>
</View>
);
};

const styles = StyleSheet.create({
baseText: {
fontFamily: 'Cochin',
},
titleText: {
fontSize: 20,
fontWeight: 'bold',
},
});

export default App;`,

},


 

 





],












"coursename": "RN-Text",


},

{
"id": 12,


"html_title": "react-native-Stack",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Install Npm",





 
"bootstrap_title_desc": `npm install @react-navigation/native react-native-reanimated react-native-gesture-handler react-native-screens react-native-safe-area-context @react-native-community/viewpager

npm install @react-navigation/stack


`,

},

{
"bootstrap_title_heading": "SplashScreen.js",





 
"bootstrap_title_desc": `// SplashScreen.js

import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

const SplashScreen = ({ navigation }) => {
useEffect(() => {
// Simulate a delay of 3 seconds
const delay = setTimeout(() => {
// Navigate to the home screen
navigation.navigate('Home');
}, 3000);

// Clear the timeout on component unmount
return () => clearTimeout(delay);
}, [navigation]);

return (
<View style={styles.container}>
<Text style={styles.text}>Your App Name</Text>
{/* You can add your splash screen content here */}
</View>
);
};

const styles = StyleSheet.create({
container: {
flex: 1,
justifyContent: 'center',
alignItems: 'center',
},
text: {
fontSize: 24,
fontWeight: 'bold',
},
});

export default SplashScreen;
`,

},

{
"bootstrap_title_heading": "HomeScreen.js",





 
"bootstrap_title_desc": `// HomeScreen.js

import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const HomeScreen = () => {
return (
<View style={styles.container}>
<Text style={styles.text}>Home Screen</Text>
{/* Your home screen content goes here */}
</View>
);
};

const styles = StyleSheet.create({
container: {
flex: 1,
justifyContent: 'center',
alignItems: 'center',
},
text: {
fontSize: 24,
fontWeight: 'bold',
},
});

export default HomeScreen;
`,

},

{
"bootstrap_title_heading": "App.tsx",





 
"bootstrap_title_desc": `import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Splash from '@/components/Splash';
import TabNavigator from './TabNavigator';
import Product from './Product';
import Register from './Register';

const Stack = createStackNavigator();

const App = () => {



 
return (
 
<Stack.Navigator initialRouteName="tab" >
<Stack.Screen name="splash" component={Splash} options={{ headerShown: false }} />
<Stack.Screen name="product" component={Product} options={{headerShown: false }}/>
<Stack.Screen name="tab" component={TabNavigator} options={{headerShown: false }}/>
<Stack.Screen name="register" component={Register}options={{headerStatusBarHeight:0,headerBackTitle:'login' }}/>
</Stack.Navigator>

 
);
};



export default App;
`,

},















],












"coursename": "RN-Stack",


},

{
"id": 16,


"html_title": "react-native-bottom-navigator",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "npm install",


 
"bootstrap_title_desc": `-- npm install 
@react-navigation/native 
@react-navigation/bottom-tabs
react-native-reanimated 
react-native-gesture-handler 
react-native-screens 
react-native-safe-area-context 
@react-native-community/masked-view
`,

},

{
"bootstrap_title_heading": "TabNavigator.tsx",



"bootstrap_title_desc": `import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Entypo } from '@expo/vector-icons';

import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import React from 'react';

import Home from './Home';
import Programs from './Programs';
import Lecture from './Lecture';
import Account from './Account';
import { Text,View } from 'react-native';
import Header from './Header';



const TabNavigator = () => {



const Tab = createBottomTabNavigator();

const CustomHeaderTitle = () => (
<Text style={{ marginRight: 15, fontSize: 18, fontWeight: 'bold' }}>Header Title</Text>
);


return (

<Tab.Navigator
initialRouteName="home"
screenOptions={{
headerStatusBarHeight:0,
headerTitleAlign: 'left',


tabBarInactiveTintColor:"black",
tabBarActiveTintColor:"black",
tabBarItemStyle: { marginVertical: -20 },
tabBarStyle: { backgroundColor: 'white', height:50, paddingVertical: 10 },
tabBarIconStyle: { marginBottom: -20 },
tabBarLabelStyle: { fontSize: 12,marginBottom:-10},
}}
>
<Tab.Screen
name="home"
component={Home}
options={{
header: () => <Header/>,
tabBarLabel: 'Home',
tabBarIcon: ({ focused }) => (
focused?<Entypo name="home" size={24} color="black" />:
<AntDesign name="home" size={24} color="black" />
),
}}
/>
<Tab.Screen
name="programs"
component={Programs}
options={{
tabBarLabel: 'Programs',
headerRight: () => <CustomHeaderTitle />,
headerTitle:'Learn Programs',
tabBarIcon: ({focused}) => (
focused?
<FontAwesome5 name="code" size={24} color="black" />:
<FontAwesome name="code" size={24} color="black" />

),



}}
/>

<Tab.Screen
name="internship"
component={Lecture}
options={{
tabBarLabel: 'Internship',
tabBarIcon: ({ focused }) => (

focused?
<FontAwesome5 name="unlock" size={24} color="black" />:
<AntDesign name="unlock" size={24} color="black" />
),

tabBarBadgeStyle:{color:"black"},

}}
/>

<Tab.Screen
name="account"
component={Account}
options={{
tabBarLabel: 'account',
tabBarIcon: ({focused}) => (
focused?
<FontAwesome name="user" size={24} color="black" />:
<Feather name="user" size={24} color="black" />
),
 
tabBarBadgeStyle:{color:"black"},

}}
/>
 
</Tab.Navigator>

)
}

export default TabNavigator
`,

},

{
"bootstrap_title_heading": "MainNavigator.tsx",



"bootstrap_title_desc": `import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Splash from '@/components/Splash';
import TabNavigator from './TabNavigator';
import Product from './Product';
import Register from './Register';

const Stack = createStackNavigator();

const App = () => {



 
return (
 
<Stack.Navigator initialRouteName="tab" >
<Stack.Screen name="splash" component={Splash} options={{ headerShown: false }} />
<Stack.Screen name="product" component={Product} options={{headerShown: false }}/>
<Stack.Screen name="tab" component={TabNavigator} options={{headerShown: false }}/>
<Stack.Screen name="register" component={Register}options={{headerStatusBarHeight:0,headerBackTitle:'login' }}/>
</Stack.Navigator>

 
);
};



export default App;
`,

},

{
"bootstrap_title_heading": "index.tsx",



"bootstrap_title_desc": `
import React from 'react'
import App from '@/components/App'

const index = () => {
return (


<App/>


)
}

export default index`,

},

















],












"coursename": "RN-Bottom-bar",


},

{
"id": 4,


"html_title": "react-native-image",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.tss",





"bootstrap_title_desc": `import React from 'react';
import {View, Image, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
container: {
paddingTop: 50,
},
tinyLogo: {
width: 50,
height: 50,
},

});

const App = () => {
return (
<View style={styles.container}>
<Image
style={styles.tinyLogo}
source={require('@expo/snack-static/react-native-logo.png')}
/>
<Image
style={styles.tinyLogo}
source={{
uri: 'https://reactnative.dev/img/tiny_logo.png',
}}
/>
 
</View>
);
};

export default App;`,

},


 

 





],












"coursename": "RN-Image",


},

{
"id": 5,


"html_title": "react-native-input-text",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js",





"bootstrap_title_desc": `<!--SafeAreaView: A component provided by React
 Native to ensure that its children do not overlap
with the device's status bar, and also provides padding.-->

import React,{useState} from 'react';
import {SafeAreaView, StyleSheet, TextInput} from 'react-native';

const App = () => {
const [text, onChangeText] = useState('Useless Text');
const [number, onChangeNumber] = useState('');

return (
<SafeAreaView>
<TextInput
style={styles.input}
onChangeText={onChangeText}
value={text}
editable
multiline
numberOfLines={4}
maxLength={40}
/>
<TextInput
style={styles.input}
onChangeText={onChangeNumber}
value={number}
placeholder="useless placeholder"
keyboardType="numeric"
/>
</SafeAreaView>
);
};

const styles = StyleSheet.create({
input: {
height: 40,
margin: 12,
borderWidth: 1,
padding: 10,
},
});

export default App;`,

},


 

 





],












"coursename": "RN-Input",


},

{
"id": 6,


"html_title": "react-native-scrollview",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js",





"bootstrap_title_desc": `import React from 'react';
import {
StyleSheet,
Text,
SafeAreaView,
ScrollView,
StatusBar,
} from 'react-native';

const App = () => {
return (
<SafeAreaView style={styles.container}>
<ScrollView style={styles.scrollView}>
<Text style={styles.text}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat. Duis aute irure dolor in
reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
culpa qui officia deserunt mollit anim id est laborum.
</Text>
</ScrollView>
</SafeAreaView>
);
};

const styles = StyleSheet.create({
container: {
flex: 1,
paddingTop: StatusBar.currentHeight,
},
scrollView: {
backgroundColor: 'pink',
marginHorizontal: 20,
},
text: {
fontSize: 42,
},
});

export default App;`,

},


 

 





],












"coursename": "RN-scrollview",


},

{
"id": 7,


"html_title": "react-native-style",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "inline css",





"bootstrap_title_desc": `import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const App = () => (
<View style={{ backgroundColor: 'blue', padding: 10 }}>
<Text style={{ color: 'white', fontSize: 20 }}>React Native</Text>
</View>
);

export default App;`,

},

{
"bootstrap_title_heading": "internal css",





"bootstrap_title_desc": `import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const App = () => (
<View style={styles.container}>
<Text style={styles.title}>React Native</Text>
</View>
);

const styles = StyleSheet.create({
container: {
flex: 1,
padding: 24,
backgroundColor: '#eaeaea',
},
title: {
marginTop: 16,
paddingVertical: 8,
borderWidth: 4,
borderColor: '#20232a',
borderRadius: 6,
backgroundColor: '#61dafb',
color: '#20232a',
textAlign: 'center',
fontSize: 30,
fontWeight: 'bold',
},
});

export default App;`,

},

{
"bootstrap_title_heading": "External css",





"bootstrap_title_desc": `// styles.js
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
container: {
backgroundColor: 'blue',
padding: 10,
},
title: {
color: 'white',
fontSize: 20,
},
});

export default styles;

// App.js
import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

const App = () => {
return (
<View style={styles.container}>
<Text style={styles.title}>React Native</Text>
</View>
);
};

export default App;
`,

},


 

 





],












"coursename": "RN-Style",


},


{
"id": 8,


"html_title": "react-native-buttons",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js",





"bootstrap_title_desc": `import React from 'react';
import {
StyleSheet,
Button,
View,
SafeAreaView,
Text,
Alert,
} from 'react-native';

const Separator = () => <View style={styles.separator} />;

const App = () => (
<SafeAreaView style={styles.container}>
<View>
<Text style={styles.title}>
The title and onPress handler are required. It is recommended to set
accessibilityLabel to help make your app usable by everyone.
</Text>
<Button
title="Press me"
onPress={() => Alert.alert('Simple Button pressed')}
/>
</View>
<Separator />
<View>
<Text style={styles.title}>
Adjust the color in a way that looks standard on each platform. On iOS,
the color prop controls the color of the text. On Android, the color
adjusts the background color of the button.
</Text>
<Button
title="Press me"
color="#f194ff"
onPress={() => Alert.alert('Button with adjusted color pressed')}
/>
</View>
<Separator />
<View>
<Text style={styles.title}>
All interaction for the component are disabled.
</Text>
<Button
title="Press me"
disabled
onPress={() => Alert.alert('Cannot press this one')}
/>
</View>
<Separator />
<View>
<Text style={styles.title}>
This layout strategy lets the title define the width of the button.
</Text>
<View style={styles.fixToText}>
<Button
title="Left button"
onPress={() => Alert.alert('Left button pressed')}
/>
<Button
title="Right button"
onPress={() => Alert.alert('Right button pressed')}
/>
</View>
</View>
</SafeAreaView>
);

const styles = StyleSheet.create({
container: {
flex: 1,
justifyContent: 'center',
marginHorizontal: 16,
},
title: {
textAlign: 'center',
marginVertical: 8,
},
fixToText: {
flexDirection: 'row',
justifyContent: 'space-between',
},
separator: {
marginVertical: 8,
borderBottomColor: '#737373',
borderBottomWidth: StyleSheet.hairlineWidth,
},
});

export default App;`,

},




 

 





],












"coursename": "RN-Button",


},

{
"id": 9,


"html_title": "react-native-switch",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js",





"bootstrap_title_desc": `import React, {useState} from 'react';
import {View, Switch, StyleSheet} from 'react-native';

const App = () => {
const [isEnabled, setIsEnabled] = useState(false);
const toggleSwitch = () => setIsEnabled(!isEnabled);

return (
<View style={styles.container}>
<Switch
trackColor={{false: '#767577', true: '#81b0ff'}}
thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
ios_backgroundColor="#3e3e3e"
onValueChange={toggleSwitch}
value={isEnabled}
/>
</View>
);
};

const styles = StyleSheet.create({
container: {
flex: 1,
alignItems: 'center',
justifyContent: 'center',
},
});

export default App;`,

},




 

 





],












"coursename": "RN-Switch",


},

{
"id": 10,


"html_title": "react-native-flatlist",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "App.js(flatlist)",





"bootstrap_title_desc": `import React from 'react';
import { SafeAreaView, FlatList, StyleSheet, Text, StatusBar } from 'react-native';

const DATA = [
{ id: '1', title: 'First Item' },
{ id: '2', title: 'Second Item' },
{ id: '3', title: 'Third Item' },
 { id: '4', title: 'First Item' },
{ id: '5', title: 'Second Item' },
{ id: '6', title: 'Third Item' },
 { id: '7', title: 'First Item' },
{ id: '8', title: 'Second Item' },
{ id: '9', title: 'Third Item' },
];

const Item = ({ title }) => (
<Text style={styles.item}>{title}</Text>
);

const App = () => {
return (
<SafeAreaView style={styles.container}>
<FlatList
data={DATA}
renderItem={({ item }) => <Item title={item.title} />}
keyExtractor={item => item.id}
/>
</SafeAreaView>
);
};

const styles = StyleSheet.create({
container: {
flex: 1,
marginTop: StatusBar.currentHeight || 0,
},
item: {
backgroundColor: '#f9c2ff',
padding: 20,
marginVertical: 8,
marginHorizontal: 16,
},
});

export default App;
`,

},

{
"bootstrap_title_heading": "App.js(sectionflatlist)",





"bootstrap_title_desc": `import React from 'react';
import { StyleSheet, Text, View, SafeAreaView, SectionList, StatusBar } from 'react-native';

const DATA = [
{
title: 'Main dishes',
data: ['Pizza', 'Burger', 'Risotto'],
},
{
title: 'Sides',
data: ['French Fries', 'Onion Rings', 'Fried Shrimps'],
},
{
title: 'Drinks',
data: ['Water', 'Coke', 'Beer'],
},
{
title: 'Desserts',
data: ['Cheese Cake', 'Ice Cream'],
},
];

const App = () => (
<SafeAreaView style={styles.container}>
<SectionList
sections={DATA}
keyExtractor={(item, index) => item + index}
renderItem={({ item }) => (
<View style={styles.item}>
<Text style={styles.title}>{item}</Text>
</View>
)}
renderSectionHeader={({ section: { title } }) => (
<View style={styles.header}>
<Text style={styles.headerText}>{title}</Text>
</View>
)}
/>
</SafeAreaView>
);

const styles = StyleSheet.create({
container: {
flex: 1,
paddingTop: StatusBar.currentHeight,
marginHorizontal: 16,
},
item: {
backgroundColor: '#f9c2ff',
padding: 20,
marginVertical: 8,
},
header: {
backgroundColor: '#ccc',
padding: 10,
},
headerText: {
fontSize: 24,
fontWeight: 'bold',
},
title: {
fontSize: 20,
},
});

export default App;
`,

},




 

 





],












"coursename": "RN-FlatList",


},

{
"id": 11,


"html_title": "react-native-Header",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Header.js",





"bootstrap_title_desc": `import React from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

const Header = () => {
return (
<View style={styles.headerContainer}>
<Image
source={require('../assets/logo.png') }
style={styles.logo}
/>
 
 <View style={styles.iconi}>
<TouchableOpacity style={styles.icon}>
<FontAwesome name="book" size={24} color="black" />
</TouchableOpacity>
<TouchableOpacity style={styles.icon}>
<FontAwesome name="ellipsis-v" size={24} color="black" />
</TouchableOpacity>
</View>
</View>
)
}

export default Header

const styles = StyleSheet.create({
headerContainer: {
flexDirection: 'row',
alignItems: 'center',
justifyContent:'space-between',
padding: 3,
paddingLeft:8,
paddingRight:20,
backgroundColor: 'white',
elevation: 4,
},


logo: {
width: 130,
height: 40,

},

iconi:{
flexDirection:'row',
gap:15
},

icon: {
 
},
});`,

},














],












"coursename": "RN-Header",


},





{
"id": 14,


"html_title": "react-native-banner",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Banner.tsx",


 
"bootstrap_title_desc": `import React from 'react';
import { View, Text, ImageBackground, StyleSheet } from 'react-native';

const Banner = () => {
return (
<ImageBackground
source={require('../../assets/icon.png')}
style={styles.background}>
<View style={styles.overlay}>
<Text style={styles.title}>Your Hero Title</Text>
<Text style={styles.subtitle}>Your Hero Subtitle</Text>
</View>
</ImageBackground>
);
};

const styles = StyleSheet.create({
background: {
width: '100%',
height: 200,
resizeMode: 'cover',
justifyContent: 'center',
alignItems: 'center',
},
overlay: {
backgroundColor: 'rgba(0, 0, 0, 0.5)',
padding: 20,
borderRadius: 5,
},
title: {
color: 'white',
fontSize: 24,
fontWeight: 'bold',
marginBottom: 10,
},
subtitle: {
color: 'white',
fontSize: 18,
},
});

export default Banner;
`,

},

{
"bootstrap_title_heading": "Home.tsx",



"bootstrap_title_desc": `import React from 'react';
import { View, Text } from 'react-native';
import Header from '../component/Header';
import Slider from './Home/Slider';



const HomeScreen = ({ navigation }) => {
React.useLayoutEffect(() => {
navigation.setOptions({
headerLeft: () => null,
headerTitle: () => null,// Hides the default back button
header: () => <Header/>, 
});
}, [navigation]);

return (
<View>
<Slider/>

</View>
);
};

export default HomeScreen;
`,

},


















],












"coursename": "RN-Banner",


},


{
"id": 15,


"html_title": "react-native-Category",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "CategoryCard.tsx",


 
"bootstrap_title_desc": `import { View, Text, StyleSheet } from 'react-native';
import React from 'react';

const Category = () => {
return (
<View style={styles.container}>
<View style={styles.row}>
<View style={styles.box}>
<Text style={styles.boxText}>Category 1</Text>
</View>
<View style={styles.box}>
<Text style={styles.boxText}>Category 2</Text>
</View>
</View>
<View style={styles.row}>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 3</Text>
  </View>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 4</Text>
  </View>
</View>
  
<View style={styles.row}>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 1</Text>
  </View>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 2</Text>
  </View>
</View>
<View style={styles.row}>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 3</Text>
  </View>
  <View style={styles.box}>
<Text style={styles.boxText}>Category 4</Text>
  </View>
</View>
  </View>
);
  }
  
  
  
  export default Category;
  const styles = StyleSheet.create({
container: {
  
  padding: 20,
  marginTop: 20,
  marginBottom: 20,
 
},
row: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 20,
},
box: {
  backgroundColor: 'white',
  width: '48%',
  height: 200,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 20,
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.3,
  shadowRadius: 4,
  elevation: 5,
},
boxText: {
  fontSize: 16,
  fontWeight: 'bold',
},
  });`,

},



{
  "bootstrap_title_heading": "Home.tsx",
  
  

  "bootstrap_title_desc": `// screens/HomeScreen.js

  import React from 'react';
  import {ScrollView, StyleSheet, View } from 'react-native';
  import Search from './Search';
  import Category from './Category';
  import HorizontalCard from './HorizontalCard';
  
  function Home() {
  
  
return (
  <View>
  <Search/>
  <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
  <Category />
  <HorizontalCard/>
</ScrollView>
  </View>
);
  }
  
  export default Home;
  
  const styles = StyleSheet.create({
container: {
   marginTop:20,
   marginBottom:20
}
  
  });
  `,

},


















],












"coursename": "RN-Category",


},



{
  "id": 17,


  "html_title": "react-native-image-slider",


  "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "Slider.tsx",
  
  
   
"bootstrap_title_desc": `import { View, Text } from 'react-native'
import React from 'react'
import { ImageSlider } from "react-native-image-slider-banner";

const Slider = () => {
return (
<View>
 <ImageSlider 
data={[
{img: require('../../assets/logo.jpg')},
{img: require('../../assets/logo.jpg')},
{img: require('../../assets/logo.jpg')},
{img: require('../../assets/logo.jpg')},
{img: 'https://v-extechsolution.in/static/media/python.0cc149e38bc8d29ebe80.png'},
]}
autoPlay={true}
timer={2000}
 
localImg={true}

onItemChanged={(item) => console.log("item", item)}
closeIconColor="#fff"
/>
</View>
)
}

export default Slider`,

},








],












"coursename": "RN-Slider",


},

{
  "id": 18,


  "html_title": "react-native-change-theme",


  "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "MainNavigator.tsx",
  
  
   
"bootstrap_title_desc": `import React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import SplaceScreen from '../screens/SplaceScreen';
import HomeScreen from '../screens/HomeScreen';
import TabNavigator from './TabNavigator';



const Stack = createStackNavigator();


const MainNavigator = () => {

const theme = {
...DefaultTheme,
colors: {
...DefaultTheme.colors,
background: 'white', // Change the background color here
},
}
return (


<NavigationContainer theme={theme}>
 
<Stack.Navigator
initialRouteName="Splash"
 
>
<Stack.Screen name="Splash" component={SplaceScreen} />
<Stack.Screen name="home" component={HomeScreen}/>
<Stack.Screen name="tab" component={TabNavigator}/>
</Stack.Navigator>


  </NavigationContainer>
  );
};

export default MainNavigator;
`,

},


],


"coursename": "RN-Theme",


},


{
  "id": 19,


  "html_title": "react-native-search",


  "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "Search.tsx",
  
  
   
"bootstrap_title_desc": `import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import Typewriter from 'react-native-typewriter';

const Search = () => {
 
return (
<View style={styles.container}>
<Fontisto name="search" size={15} color="rgba(0,0,0,0.4)" style={styles.icon}/>
<TextInput
placeholder='search here...'
style={styles.input}
placeholderTextColor="rgba(0,0,0,0.4)"
/>
</View>
);
};

const styles = StyleSheet.create({
container: {
flexDirection: 'row',
alignItems: 'center',
backgroundColor: '#fff',
borderRadius: 25,
paddingVertical: 5,
paddingHorizontal: 20,
marginHorizontal: 20,
marginVertical: 10,
shadowColor: '#000',
shadowOffset: {
width: 0,
height: 5,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
elevation: 5,
borderWidth:0.17,
borderBottomColor: 'rgba(0,0,0,0.4)',
borderTopColor: 'rgba(0,0,0,0.6)', 
},
icon: {
marginRight: 10,

},
input: {
flex: 1,
height: 30,
color: 'black',
},
});

export default Search;
`,

},


],


"coursename": "RN-Search",


},

{
  "id": 20,


  "html_title": "react-native-contact",


  "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "ContactScreen.tsx",
  
  
   
"bootstrap_title_desc": `import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, ImageBackground, Dimensions, Linking } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

const ContactScreen = () => {

  
const openInstagram = () => {
Linking.openURL('https://www.instagram.com/v_extech/?igshid=Zjc2ZTc4Nzk%3D/');
};
return (
<ImageBackground
source={require('../assets/slider1.png')}
style={styles.backgroundImage}
resizeMode="cover"
>
<View style={styles.container}>
<View style={styles.content}>
<Text style={styles.header}>Contact Us</Text>
<View style={styles.contactInfoContainer}>
<Text style={styles.contactInfoTitle}>Our Address</Text>
<Text style={styles.contactInfoText}>301,dhun complex,nizampura,vadodara, india</Text>
<Text style={styles.contactInfoTitle}>Phone Number</Text>
<Text style={styles.contactInfoText}>+91 9316051170</Text>
<Text style={styles.contactInfoTitle}>Email</Text>
<Text style={styles.contactInfoText}>veer@vhxview.com</Text>
</View>
<View style={styles.socialMediaContainer}>
<TouchableOpacity style={styles.socialMediaButton}>
<FontAwesome name="facebook" size={24} color="black" />
</TouchableOpacity>
<TouchableOpacity style={styles.socialMediaButton}>
<FontAwesome name="twitter" size={24} color="black" />
</TouchableOpacity>
<TouchableOpacity style={styles.socialMediaButton} onPress={openInstagram}>
<FontAwesome name="instagram" size={24} color="black" />
</TouchableOpacity>
</View>
</View>
</View>
</ImageBackground>
);
};



const styles = StyleSheet.create({
backgroundImage: {
flex: 1,
resizeMode: 'cover',
justifyContent: 'center',
},
container: {
flex: 1,
alignItems: 'center',
justifyContent: 'center',
backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
},
content: {
width: '100%',
padding: 20,
alignItems: 'center',
},
header: {
fontSize: 24,
fontWeight: 'bold',
marginBottom: 20,
color: 'white',
},
contactInfoContainer: {
marginBottom: 20,
},
contactInfoTitle: {
fontSize: 18,
fontWeight: 'bold',
marginBottom: 5,
color: 'white',
},
contactInfoText: {
fontSize: 16,
marginBottom: 10,
color: 'white',
},
socialMediaContainer: {
flexDirection: 'row',
justifyContent: 'center',
marginTop: 20,
},
socialMediaButton: {
backgroundColor: 'white',
borderRadius: 50,
width: 50,
height: 50,
marginHorizontal: 10,
justifyContent: 'center',
alignItems: 'center',
},
});

export default ContactScreen;
`,

},


],


"coursename": "RN-contactscreen",


},

{
  "id": 20,


  "html_title": "Api-horizontal-card",


  "bootstrap_head_desc": [
{
  "bootstrap_title_heading": "Horizontal.tsx",
  
  
   
"bootstrap_title_desc": `import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';

const HorizontalCard = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
fetch('https://vhx.vercel.app/api')
  .then(response => response.json())
  .then(data => setCategories(data.data))
  .catch(error => console.error(error));
  }, []);

  return (
<View style={styles.container}>
  <ScrollView horizontal showsHorizontalScrollIndicator={false}>
<View style={styles.row}>
  {categories.map(category =>{
const{id,product_heading} = category;
return(
<View style={styles.box} key={id}>
  <Text style={styles.boxText}>{product_heading}</Text>
</View>
)


  } 



  )}
</View>
  </ScrollView>
</View>
  );
}

export default HorizontalCard;

const styles = StyleSheet.create({
  container: {
padding: 20,
marginTop: 20,
marginBottom: 20,
  },
  row: {
flexDirection: 'row',
gap: 20
  },
  box: {
backgroundColor: 'pink',
width: 200,
height: 200,
justifyContent: 'center',
alignItems: 'center',
borderRadius: 20,
shadowColor: '#000',
shadowOffset: { width: 0, height: 2 },
shadowOpacity: 0.1,
shadowRadius: 4,
elevation: 5,
  },
  boxText: {
fontSize: 16,
fontWeight: 'bold',
  },
});
`,

},


],


"coursename": "RN-Api Call",


},

{
"id": 20,


"html_title": "react-native-register",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Register.js",


 
"bootstrap_title_desc": `import React, { useContext } from 'react';
import { Alert, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { MyContext } from './MyContextProvider';



const RegisterPage = () => {

const{loader,setLoader} = useContext(MyContext)


const navigation = useNavigation()


 

return (
<View style={styles.container}>
<Text style={styles.title}>Register</Text>
<Formik
initialValues={{ name: '', email: '', mobile: '', password: '' }}
validationSchema={Yup.object({
name: Yup.string()
.required('name is required')
.matches(/^([^0-9]*)$/, "Don't allow Numeric Value"),
email: Yup.string().required('Email is required')
.email('Enter a valid email'),
mobile: Yup.string().required('Mobile number is required')
.matches(/^[0-9]{10}$/, 'Mobile number is not valid'),
password: Yup.string().required('Password is required')
.min(8, 'Password must be at least 8 characters')
.max(12, 'Password must be at most 12 characters'),
})}
onSubmit={async (values, { resetForm }) => {
setLoader(true);
try {
const response = await fetch('https://vhx.vercel.app/register', {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(values),
});

const text = await response.text();
let data;
try {
data = JSON.parse(text);
} catch (error) {
throw new Error("Server response is not valid JSON");
}

if (data.success) {
Alert.alert('Success', data.message);
resetForm();
navigation.navigate('login');
} else {
Alert.alert('Error', data.error);
}
} catch (error) {
Alert.alert('Error', error.message);
} finally {
setLoader(false);
}
}}
>
{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
<>
<TextInput
style={styles.input}
placeholder="name"
onChangeText={handleChange('name')}
onBlur={handleBlur('name')}
value={values.name}
/>
{errors.name && <Text style={styles.error}>{errors.name}</Text>}
<TextInput
style={styles.input}
placeholder="email"
onChangeText={handleChange('email')}
onBlur={handleBlur('email')}
value={values.email}
/>
{errors.email && <Text style={styles.error}>{errors.email}</Text>}
<TextInput
style={styles.input}
placeholder="mobile"
onChangeText={handleChange('mobile')}
onBlur={handleBlur('mobile')}
value={values.mobile}
/>
{errors.mobile && <Text style={styles.error}>{errors.mobile}</Text>}
<TextInput
style={styles.input}
placeholder="Password"
secureTextEntry
onChangeText={handleChange('password')}
onBlur={handleBlur('password')}
value={values.password}
/>
{errors.password && <Text style={styles.error}>{errors.password}</Text>}
<TouchableOpacity style={styles.button} onPress={handleSubmit}>
<Text style={styles.buttonText}>{loader?'wait..':'Register'}</Text>
</TouchableOpacity>
</>
)}
</Formik>
</View>
);
};


const styles = StyleSheet.create({
container: {
flex: 1,
justifyContent: 'center',
alignItems: 'center',
backgroundColor: 'white',
},
title: {
fontSize: 24,
fontWeight: 'bold',
marginBottom: 20,
},
input: {
width: '80%',
height: 40,
borderWidth: 1,
borderColor: '#ccc',
borderRadius: 5,
paddingHorizontal: 10,
marginBottom: 10,
},
button: {
width: '80%',
height: 40,
backgroundColor: '#007AFF',
justifyContent: 'center',
alignItems: 'center',
borderRadius: 5,
marginTop: 20,
},
buttonText: {
color: '#fff',
fontSize: 16,
fontWeight: 'bold',
},
error: {
color: 'red',
marginBottom: 5,
},
});

export default RegisterPage;
`,

},

{
  "bootstrap_title_heading": "MyContextProvider.js",
  
  
   
  "bootstrap_title_desc": `import React, { createContext, useState } from 'react';

  // Create a Context for the auth state
  export const MyContext = createContext();
  
  // Create a provider component
  export const MycontextProvider = ({ children }) => {
  const[loader,setLoader] = useState(false)
  
      const [token,setToken] = useState('')
  
      
    
  
  
    return (
      <MyContext.Provider value={{loader,token,setLoader,setToken}}>
        {children}
      </MyContext.Provider>
    );
  };
  `,


  },

  {
    "bootstrap_title_heading": "App.js",
    
    
     
    "bootstrap_title_desc": `import React from 'react';
    import { createStackNavigator } from '@react-navigation/stack';
    import Splash from '@/components/Splash';
    import TabNavigator from './TabNavigator';
    import Product from './Product';
    import Register from './Register';
    import LoginScreen from './Login';
    
    import Loader from './Loader'
    import { MycontextProvider } from './MyContextProvider';
    
    
    
    const Stack = createStackNavigator();
    
    const App = () => {
    
    
    
       
      return (
        <MycontextProvider>
          <Loader/>
          <Stack.Navigator initialRouteName="tab" >
            <Stack.Screen name="splash" component={Splash} options={{ headerShown: false }} />
            <Stack.Screen name="product" component={Product} options={{headerShown: false }}/>
            <Stack.Screen name="tab" component={TabNavigator} options={{headerShown: false }} />
            <Stack.Screen name="login" component={LoginScreen} options={{headerStatusBarHeight:0,headerBackTitle:'register' }} />
            <Stack.Screen name="register" component={Register}  options={{headerStatusBarHeight:0,headerBackTitle:'login' }}/>
            </Stack.Navigator>
        </MycontextProvider>
       
      );
    };
    
    
    
    export default App;
    `,
  

    },

    {
      "bootstrap_title_heading": "Loader.js",
      
      
       
      "bootstrap_title_desc": `import {ActivityIndicator, Modal, StyleSheet, View} from 'react-native';
      import React, { useContext } from 'react'
      import { MyContext } from './MyContextProvider';
      
      
      
      const Loader = () => {
      
          const{loader} = useContext(MyContext)
        return (
      
          <>
          {
              loader &&
              <Modal
              transparent={true}
              animationType="none"
              visible={loader}
              onRequestClose={() => {}}
            >
              <View style={styles.modalBackground}>
                <View style={styles.activityIndicatorWrapper}>
                  <ActivityIndicator size="large" color="#007AFF" />
                </View>
              </View>
            </Modal>
          }
          </>
        )
      }
      
      export default Loader
      
      
      const styles = StyleSheet.create({
          modalBackground: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          activityIndicatorWrapper: {
            backgroundColor: '#FFFFFF',
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        });
        `,
    

      },


],


"coursename": "RN-register",


},

{
"id": 20,


"html_title": "react-native-login",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Login.js",


 
"bootstrap_title_desc": `import React, { useContext } from 'react';
import { Alert, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { MyContext } from './MyContextProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';


const LoginScreen = () => {
const navigation = useNavigation()
const{loader,setLoader,setToken} = useContext(MyContext)

  return (
<View style={styles.container}>
<Text style={styles.title}>Login</Text>
<Formik
initialValues={{ email: '', password: '' }}
validationSchema={Yup.object({
email: Yup.string().required('Username is required'),
password: Yup.string().required('Password is required'),
})}
onSubmit={async (values, { resetForm }) => {
 
setLoader(true)
const response = await fetch('https://vhx.vercel.app/login', {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(values),
});

const text = await response.text();
let data;
try {
data = JSON.parse(text);
} catch (error) {
throw new Error('Server response is not valid JSON');
}

if (data.success) {
 
resetForm();
navigation.navigate('tab')
await AsyncStorage.setItem('token', data.data);
setToken(data.data)
 
} else {
 
Alert.alert(data.error);
}
setLoader(false)
 
}}
>
{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
<>
<View style={styles.inputContainer}>
<TextInput
style={styles.input}
placeholder="email"
onChangeText={handleChange('email')}
onBlur={handleBlur('email')}
value={values.email}
/>
</View>
{errors.email && <Text style={styles.error}>{errors.email}</Text>}
<View style={styles.inputContainer}>
<TextInput
style={styles.input}
placeholder="Password"
secureTextEntry
onChangeText={handleChange('password')}
onBlur={handleBlur('password')}
value={values.password}
/>
</View>
{errors.password && <Text style={styles.error}>{errors.password}</Text>}
<TouchableOpacity style={styles.button} onPress={handleSubmit}>
<Text style={styles.buttonText}>{loader ?'wait...':'Login'}</Text>
</TouchableOpacity>

<TouchableOpacity onPress={() => navigation.navigate('register')}>
<Text style={styles.link}>Not signed up? Register here</Text>
</TouchableOpacity>
</>
)}
</Formik>
</View>
);
};



export default LoginScreen;

const styles = StyleSheet.create({
container: {
height:750,
justifyContent: 'center',
alignItems: 'center',
backgroundColor: 'white',
},
title: {
fontSize: 24,
fontWeight: 'bold',
marginBottom: 20,
},
inputContainer: {
width: '80%',
borderWidth: 1,
borderColor: '#ccc',
borderRadius: 5,
marginBottom: 10,
},
input: {
paddingHorizontal: 10,
height: 40,
},
button: {
width: '80%',
height: 40,
backgroundColor: '#007AFF',
justifyContent: 'center',
alignItems: 'center',
borderRadius: 5,
marginTop: 20,
},
buttonText: {
color: '#fff',
fontSize: 16,
fontWeight: 'bold',
},
error: {
color: 'red',
marginBottom: 5,
},
link: {
marginTop: 30,
color: '#007AFF',
textDecorationLine: 'underline',
},
});
`,

},

{
"bootstrap_title_heading": "Account.js",


 
"bootstrap_title_desc": `import { View, Text } from 'react-native'
import React, { useContext } from 'react'
import Login from './Login'
import AccountDetails from './AccountDetails'
import { MyContext } from './MyContextProvider'

const Account = () => {
 const {token} = useContext(MyContext)
return (
<View>

{token ?<AccountDetails/>:
<Login/>
}
</View>
)
}

export default Account`,

},


],


"coursename": "RN-Login",


},

{
  "id": 20,
  
  
  "html_title": "react-native-dynamic-product",
  
  
  "bootstrap_head_desc": [
  {
  "bootstrap_title_heading": "product.js",
  
  
   
  "bootstrap_title_desc": `import React, { useContext } from 'react';
  import { View, Text, StyleSheet, ScrollView } from 'react-native';
  import { MyContext } from './MyContextProvider';
  
  const Product = ({ route, navigation }) => {
    const { categories } = useContext(MyContext);
    const { category } = route.params;
  
    React.useLayoutEffect(() => {
      navigation.setOptions({ title: category });
    }, [navigation, category]);
  
    const renderProductRows = (products) => {
      const rows = [];
      for (let i = 0; i < products.length; i += 2) {
        rows.push(
          <View style={styles.row} key={i}>
            <View style={styles.box}>
              <Text style={styles.boxText}>{products[i].product_name}</Text>
            </View>
            {products[i + 1] && (
              <View style={styles.box}>
                <Text style={styles.boxText}>{products[i + 1].product_name}</Text>
              </View>
            )}
          </View>
        );
      }
      return rows;
    };
  
    return (
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        {categories
          .filter((o) => o.product_heading === category)
          .map((o) => {
            return (
              <React.Fragment key={o.id}>
                {renderProductRows(o.product_container)}
              </React.Fragment>
            );
          })}
      </ScrollView>
    );
  };
  
  export default Product;
  
  const styles = StyleSheet.create({
    container: {
      padding: 20,
      marginTop: 20,
      marginBottom: 20,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    box: {
      backgroundColor: 'white',
      width: '48%',
      height: 200,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 4,
      elevation: 5,
    },
    boxText: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  });
  `,


  },

  {
    "bootstrap_title_heading": "category.js",
    
    
     
    "bootstrap_title_desc": `import React, { useContext, useEffect, useState } from 'react';
    import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
    import { useNavigation } from '@react-navigation/native';
    import { MyContext } from './MyContextProvider';
    
    const HorizontalCard = () => {
    
      const navigation = useNavigation();
    
    const{categories} = useContext(MyContext)
    
      const handleCategoryPress = (category) => {
        navigation.navigate('product', { category });
      };
    
      return (
        <View style={styles.container}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.row}>
              {categories.map(category => {
                const { id, product_heading } = category;
                return (
                  <TouchableOpacity key={id} onPress={() => handleCategoryPress(product_heading)}>
                    <View style={styles.box}>
                      <Text style={styles.boxText}>{product_heading}</Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        </View>
      );
    };
    
    export default HorizontalCard;
    
    const styles = StyleSheet.create({
      container: {
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
      },
      row: {
        flexDirection: 'row',
        gap: 20
      },
      box: {
        backgroundColor: 'pink',
        width: 200,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
      },
      boxText: {
        fontSize: 16,
        fontWeight: 'bold',
      },
    });
    `,
  

    },

    {
      "bootstrap_title_heading": "MyContextProvider.js",
      
      
       
      "bootstrap_title_desc": `import React, { createContext, useEffect, useState } from 'react';

// Create a Context for the auth state
export const MyContext = createContext();

// Create a provider component
export const MycontextProvider = ({ children }) => {
const[loader,setLoader] = useState(false)

  const [token,setToken] = useState('')
  
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
fetch('https://vhx.vercel.app/api')
  .then(response => response.json())
  .then(data => setCategories(data.data))
  .catch(error => console.error(error));
  }, []);

  
  
return (
  <MyContext.Provider value={{categories,loader,token,setLoader,setToken}}>
{children}
  </MyContext.Provider>
);
  };
  `,


  },
  
 
  
  
  ],

 
  
  
  "coursename": "RN-dynamic-product",
  
  
  },





]
export default ReactNativeJson